
<template>
  <el-card class="box-main_main" shadow="never" v-loading="loading">
    <div class="relation-list-obj">
      <!-- 通用相关列表 -->
      <div class="tit">
        <div class="tit_s">
          <svg class="icon imgClass" aria-hidden="true">
            <use href="#icon-icon"></use>
          </svg>
          <!-- 员工邀约 -->
          <p class="hover_p">
            {{$t('c62')}}（{{
              tableDataList.length > 10 ? "10+" : tableDataList.length
            }}）
          </p>
        </div>
        <el-button-group style="position: relative" class="right_btn">
          <el-button
            class="button"
            size="small"
            v-for="item in objectInfo.showButton"
            :key="item.id"
            @click="buttonFun(item)"
            style="position: relative"
          >
            <span>{{ item.label }}</span>
          </el-button>
        </el-button-group>
      </div>
      <!--    相关列表-->
      <div>
        <tablePanel
          :tableAttr="tableAttr"
          :tableDataList="tableDataList"
          @edit="edit"
          @delete="del"
          @share="share"
          :operationButtonList="operationButtonList"
        ></tablePanel>
      </div>
      <!-- 在右侧只能是卡片 -->
      <!-- 查看更多 -->
      <div class="see_more">
        <span @click="seeAllBtn">{{
          $t("component.todaytask.label.all")
        }}</span>
      </div>
    </div>
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.emailtocloudcc.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确认删除该条数据吗 -->
      <span style="font-size: 14px; color: #080707">{{
        $t("label.weixin.confirm.delete")
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button @click="confirmDel" class="el-button--primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- soical详情 -->
    <detailSoical ref="detailSoical"></detailSoical>
    <!-- 新建弹窗 -->
    <dialogInvitation
      ref="dialogInvitation"
      @saveinvit="saveinvit"
    ></dialogInvitation>
    <shareFrom
      ref="shareFrom"
      :title="$t('front-marketing-module-v1-share-invitation')"
    ></shareFrom>
  </el-card>
</template>

<script>
import detailSoical from '@/views/social/components/detailSoical.vue'
import * as CommonObjApi from "../api";
import dialogInvitation from '@/views/marketList/marketingInvitation/component/dialogInvitation.vue'
import tablePanel from '@/views/marketList/marketingChannel/component/table-panel/index.vue'
import * as SetViewApi from '@/views/marketList/api.js'
import shareFrom from '@/views/marketList/marketingInvitation/component/shareFrom.vue'

export default {
  name: "marketingInviation",
  components: {
    detailSoical,
    tablePanel,
    dialogInvitation,
    shareFrom
  },
  data() {
    return {
      //
      loading: false,
      formLabelWidth: '118px',
      dataId: 1,
      //初始数据
      initData: {
        fieldid: '', // select 绑定的值
        dvalue: '', // input绑定的值
      },
      stepTwoFormData: {
        leadDefaultValues: [
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
        ],
      },
      selectFieldList: [], // 下拉框的数据 从后端获取
      projectTasksObjectApi: '',
      dialogBodyHeight: `${document.body.offsetHeight - 295}px`,
      dialogTitle: ' 市场活动：编辑社交帖子成本',
      rules: {
        subject: [
          { required: true, message: this.$i18n.t('label.file.enter.title'), trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      posterFromData: {
        subject: '',
        actual: '',
        from: '',
        channel: '',
        radio: '1'
      },
      operationButtonList: [
        { action: 'delete', label: '删除' },
        { action: 'edit', label: '编辑' },
        { action: 'share', label: '分享' }
      ],
      information: {},
      dialogVisible: false,
      campaignname: '',
      url: '',
      // 相关列表右侧按钮
      objId: '',
      objectInfo: {
        showButton: [
          { label: this.$i18n.t("label.custom.new"), id: 'cost' },//'新建'
          { label: this.$i18n.t('vue_label_commonobjects_detail_related_listview'), id: 'show' },
        ]
      },
      //表头
      tableAttr: [
      ],
      tableDataList: [],
      flag: false, // 父组件给子组件传动态参数的时候，拿不到的问题，加flag判断
      fields: "",
      relatedlistId: "",
      recordId: "",
      layoutId: "",
      list: []
    }
  },
  created() {
    this.getRelationList()
  },
  methods: {
    getRelationList() {
      this.loading = true
      SetViewApi.GetRelationList({ recordId: this.$route.params.id }).then((res) => {
        if (res.result) {
          this.list = res.data
          this.getHeadArr()
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          })
        }
      })
    },
    // 获取相关列表数据
    getHeadArr() {
      this.list.forEach(element => {
        if (element.relatedlistType === "invited") {
          this.relatedlistId = element.relatedlistId
          this.layoutId = element.layoutId
        }
      });
       if(!this.layoutId){
          this.layoutId = this.$route.params.layoutId || this.this.$route.query.layoutId
      }
      let data = {
        recordId: this.$route.params.id,
        layoutId: this.layoutId,
        relatedlistId: this.relatedlistId,
        perPage: 10,
        attachementType: '',
      }
      SetViewApi.GetDetailRelatedItems(data).then((res) => {
        if (res.result && res.data.fieldList) {
          this.tableDataList = res.data.data
          this.tableAttr = []
          res.data.fieldList.forEach(list => {

            let obj = {
              apiname: list.name,
              colwidth: list.length,
              labelname: list.label,
              type: list.type,
            }
            this.tableAttr.push(obj)
          })
          this.tableAttr = this.getName(this.tableAttr)
          this.$store.commit('setInvitationLength', this.tableDataList.length)
          this.loading = false
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          })
        }
      })
    },
    getName(item) {
      item.forEach(val => {
        if (val.type == "S" && (val.apiname == 'formid' || val.apiname == 'createbyid' || val.apiname == 'lastmodifybyid' || val.apiname == 'ownerid' || val.apiname == 'inviteduser' || val.apiname == 'campaignid')) {
          val.apiname += "ccname";
        }

      })
      return item
    },
    //新增
    adds() {
      if (this.dataId < 10) {
        this.dataId += 1
        this.stepTwoFormData.leadDefaultValues.push({
          ...this.initData,
          id: this.dataId,
        })
      } else {
        return
      }
    },
    //根据筛选字段判断值
    selectChange() { },
    //分享
    share(id) {
      this.$refs.shareFrom.init(id)
    },
    // 编辑
    edit(id) {
      this.$refs.dialogInvitation.init(id)
    },
    closeDialog() {
      // 刷新列表
      this.init()
      this.qrcodeFlg = false
    },
    // 查看更多跳转到营销渠道列表展示页面
    seeAllBtn() {
      this.$router.push({
        path: `/marketingInvitationShow`,
        query: {
          id: this.$route.params.id,
          objId: this.$route.params.id
        },
      });
    },
    del(id) {
      this.dialogVisible = true
      this.id = id
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = true;
      CommonObjApi.deleteEmployeeInvited({ id: this.id }).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"))
          this.getRelationList()
          this.dialogVisible = false;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          })
        }
      })
    },
    //新建成功
    saveinvit() {
      this.getRelationList()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 列表展示
    buttonFun(item) {
      if (item.id == 'show') {
        this.$router.push({
          path: `/marketingInvitationShow`,
          query: {
            id: this.$route.params.id,
            objId: this.$route.params.id
          },
        });
      } else if (item.id == 'cost') {
        this.$nextTick(() => {
          this.$refs.dialogInvitation.init()
        })



      }
    }
  }
}
</script>

<style scoped lang="scss">
.imgClass {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
.screen_s > div {
  margin-bottom: 0 !important;
}

::v-deep .screen_s .editableCell {
  .el-select__tags {
    flex-wrap: unset;
    overflow-x: overlay;
    top: 53%;
    height: 38px;
    overflow-y: hidden;
  }

  .el-input .el-input__inner {
    height: 32px !important;
  }

  .el-tag {
    margin: 0 5px !important;
  }
}

.screen_s ::v-deep .el-form-item__content {
  display: flex;
  margin-left: 133px !important;
  align-items: center;
}

.el-form-item {
  margin-bottom: 0;
}

.select_one {
  /*width: 280px;*/
  width: 35%;
  margin: 6px;
  height: 32px;
}

.select_two {
  width: 35%;
  margin: 6px 12px 6px 6px;
  height: 32px;
}

.btn_s {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}
.fieldTextStyle {
  text-align: center;
  font-size: 14px;
  color: #080707;
}
.screen_s > div {
  margin-bottom: 0 !important;
}
.dialog-text {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 20px;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
::v-deep .el-dialog__body {
  overflow: auto;
  padding: 16px 36px;
  font-size: 12px !important;
}
.content {
  font-size: 14px;
  padding-left: 20px;
}
.download {
  margin-left: 40px !important;
}
.wrapper {
  padding-left: 10px;
  padding-top: 10px;
  word-break: break-all;
  font-family: "Microsoft Yahei";
  float: left;
  width: 400px;
}
.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.totip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  .upload-demo {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .el-upload .el-upload--text {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .tit_s {
    display: flex;
    img {
      margin-right: 7px;
      //   float: left;
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0;
      //   float: right;
      height: 20px;
      font-size: 12px;
      color: #080707;
      line-height: 20px;
    }
    .hover_p:hover {
      border-bottom: 1px solid #006dcc;
      cursor: pointer;
      color: #006dcc;
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}

.see_more {
  border-top: 1px solid #ccc;
  height: 44px;
  line-height: 44px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    cursor: pointer;
  }
}
::v-deep .dropbox {
  width: 38px;
}
</style>
<style scoped>
.box-main_main {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid #dddbda;
  margin-top: 10px;
}
.my-tooltip .dis_b ::v-deep a {
  color: #fff;
  cursor: default;
}
.xiala_s {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}
.list_xiala_s {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.xiala_s img {
  cursor: pointer;
  width: 12px;
  margin-top: -2px;
}

.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}

.upload-demo {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.upload-demo ::v-deep .el-upload {
  width: 100% !important;
  text-align: left !important;
}
.upload-demo ::v-deep .el-upload-dragger {
  width: 100% !important;
}
</style>
