<template>
  <!-- 开发人员：张亚欣
  多语言替换：王雪茹
  -->
  <div class="relation-list-obj">
    <!-- 通用相关列表 -->
    <div class="tit" :class="viewDetail == 'pdf' ? 'pdf-btn' : ''">
      <div class="tit_s" v-if="viewDetail != 'pdf'">
        <svg class="icon" aria-hidden="true">
          <use
            :href="
              objectInfo.tabStyle
                ? '#icon-' + objectInfo.tabStyle
                : '#icon-hometab'
            "
          />
        </svg>
        <!-- 批准历史、影响业务机会(特殊相关列表，不跳转)-->
        <p
          v-if="
            objectInfo.relatedlistType === 'approvalHistory' ||
            objectInfo.relatedlistType === 'caseteam' ||
            objectInfo.objName === 'influenceBusinessOpportunities'
          "
        >
          <span v-html="obj.relationName"></span>
        </p>
        <!-- 价格手册 (特殊相关列表，不跳转) -->
        <p
          v-else-if="
            objectInfo.objLabel === $t('lable.opppdt.selPbk.pricebook') ||
            obj.relatedlistId === 'attendanceStaff'
          "
        >
          <span v-html="obj.relationName"></span>
          ({{ relationSize > 99 ? "99+" : relationSize }})
        </p>
        <!-- 客户和联系人下的服务评价 -->
        <p
          v-else-if="
            objectInfo.objid === '20210207EvaluationSE' &&
            (objectInfo.recordObjId === 'account' ||
              objectInfo.recordObjId === 'contact')
          "
        >
          <span v-html="obj.relationName"></span>
          ({{ serviceEvaluationNumber > 10 ? "10+" : serviceEvaluationNumber }})
        </p>
        <!-- 联系人角色 -->
        <p
          class="hover_p"
          @click="tableView(obj)"
          v-else-if="
            objectInfo.recordObjId === 'opportunity' &&
            objectInfo.objName === 'tp_sys_contactrole' &&
            orgchartData.children
          "
        >
          <span v-html="obj.relationName"></span>
          ({{
            orgchartData.children.length > 99
              ? "99+"
              : orgchartData.children.length
          }})
        </p>

        <p
          v-else
          :class="!isNotPersonaccount && obj.prefix === '003' ? '' : 'hover_p'"
          @click="tableView(obj)"
        >
          <span v-html="obj.relationName"></span>
          ({{ relationSize > 99 ? "99+" : relationSize }})
        </p>
      </div>

      <el-button-group
        v-if="objectInfo.showButton"
        style="position: relative"
        class="right_btn"
      >
        <el-button
          class="button"
          size="small"
          v-for="(item, i) in objectInfo.showButton"
          :key="i"
          :devid="item.id"
          :disabled="
            objectInfo.objName === 'Attachement'
              ? item.isFlag || item.isAddFlag || !isispermissionUpload
              : (item.isFlag || item.isAddFlag) && isNotPersonaccount
              ? item.isFlag || item.isAddFlag
              : !isNotPersonaccount && objectInfo.objName === 'Contact'
              ? true
              : item.isFlag || item.isAddFlag
          "
          :style="{
            backgroundColor:
              item.isFlag ||
              item.isAddFlag ||
              (noFile &&
                objectInfo.objid === 'attachement' &&
                item.id !== 'd01')
                ? '#fff'
                : '',
            color:
              item.isFlag ||
              item.isAddFlag ||
              (noFile &&
                objectInfo.objid === 'attachement' &&
                item.id !== 'd01')
                ? '#C0C4CC'
                : '',
          }"
          :class="item.id === 'dropbox' ? 'dropbox' : ''"
          @click="buttonFun(item)"
          style="position: relative"
        >
          <div class="file-dropbox" v-if="item.id === 'dropbox' ? true : false">
            <svg
              v-if="!noFile"
              class="icon file-dropbox-svg"
              aria-hidden="true"
              @click="dropboxPicker"
            >
              <use href="#icon-dropbox" />
            </svg>
            <svg v-if="noFile" class="icon file-dropbox-svg" aria-hidden="true">
              <use href="#icon-dropboxDisable" />
            </svg>

            <VueDropboxPicker
              v-if="!noFile"
              :api-key="dropboxApiKey"
              link-type="preview"
              :multiselect="true"
              :folderselect="true"
              :extensions="[]"
              @cancel="onCancel"
              @picked="onPicked"
              style="position: absolute; z-index: 100"
            >
              <svg class="icon file-dropbox-svg" aria-hidden="true">
                <use href="#icon-dropbox" />
              </svg>
            </VueDropboxPicker>
          </div>
          <span v-else>{{ item.label }}</span>
        </el-button>
        <el-popover
          placement="bottom-end"
          popper-class="pop_box"
          :visible-arrow="false"
          trigger="hover"
        >
          <el-button
            class="list_xiala_s"
            size="small"
            slot="reference"
            v-show="objectInfo.showButton.length > showBtnLength"
          >
            <svg class="icon" aria-hidden="true">
              <use href="#icon-blue_sj" />
            </svg>
          </el-button>
          <ul class="moreButton">
            <li
              v-for="(item, index) in objectInfo.showButton"
              :key="index"
              :devid="item.id"
              @click="buttonFun(item)"
              style="cursor: pointer"
            >
              {{ item.label }}
            </li>
          </ul>
        </el-popover>

        <!-- 市场活动下增加【表单营销推广】 需要前端写死发布 -->
        <el-button
          class="button"
          size="small"
          v-if="
            objectInfo.prefix === 'caf' && objectInfo.objid == 'campaignform'
          "
          @click="(isShowformPromotion = true), formPromotionBtnClick"
        >
          表单营销渠道ROI
        </el-button>
        <!-- 表单营销推广弹窗 -->
        <el-dialog
          title="表单营销渠道ROI"
          width="60%"
          top="2vh"
          :close-on-click-modal="false"
          v-if="isShowformPromotion"
          :visible="isShowformPromotion"
          :before-close="
            () => {
              isShowformPromotion = false;
            }
          "
        >
          <FormPromotionRoi :campaignid="campaignid" />
        </el-dialog>
      </el-button-group>
    </div>

    <!-- 附件上传 -->
    <upload
      v-if="objectInfo.objName === 'Attachement'"
      style="width: 100%; border-bottom: 1px solid #ccc"
      ref="upload"
      :fileType="fileType"
      :mainId="id"
      :size="size"
      :limit="10"
      :disabled="noFile"
      :action="action"
      :multiple="multiple"
      :autoUpload="true"
      :identification="identification"
      :drag="true"
      @UploadSuccess="UploadSuccess"
    ></upload>
    <!-- 批准历史 -->
    <div v-if="objectInfo.relatedlistType == 'approvalHistory'">
      <ApprovalHistory
        ref="myChlidHistory"
        @showWhichBtn="showHistoryBtn"
        :historyID="id"
        :itemTitle="bigTitle"
      ></ApprovalHistory>
    </div>
    <!--    工作订单行项目-->
    <!-- <div
      v-if="
        objectInfo.objid === 'WorkOrderLineItemObj' &&
        objId === 'WorkOrderstdobj02021'
      "
    >
      <OrderLineProcess :orderID="id"></OrderLineProcess>
    </div> -->
    <!-- 客户和联系人下 服务评价  wwwjjj-->
    <div
      v-else-if="
        objectInfo.objid === '20210207EvaluationSE' &&
        (objectInfo.recordObjId === 'account' ||
          objectInfo.recordObjId === 'contact')
      "
    >
      <serviceEvaluation
        :evaluationID="id"
        :evaluationType="objectInfo.recordObjId"
        @accountNumber="accountNumber"
      ></serviceEvaluation>
    </div>
    <!-- 个案团队 -->
    <div v-else-if="obj.relatedlistType == 'caseteam'">
      <caseteam :recordId="id"></caseteam>
    </div>
    <!-- 考勤组-考勤人员 -->
    <div v-else-if="obj.relatedlistId === 'attendanceStaff'">
      <div
        class="attendance-staff"
        v-if="attendanceStaffData.length > 0"
        v-loading="KaoqinLoading"
      >
        <el-table :data="attendanceStaffData" style="width: 100%">
          <el-table-column prop="name" :label="$t('label.weixin.username')">
          </el-table-column>
          <el-table-column prop="option" :label="$t('label.ems.action')">
            <template slot-scope="scope">
              <span @click="handleRemoveKaoqin(scope.row)" class="remove-btn">{{
                $t("label.Remove")
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 表单营销推广 -->
    <div v-else-if="objectInfo.objid === 'campaignform'">
      <table-panel
        v-if="
          objectInfo.data &&
          objectInfo.data.length > 0 &&
          !objectInfo.data[0].norecord &&
          !knowlegeshow
        "
        :islineEdit="false"
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-index="false"
        :showLock="false"
        :checked="false"
        :customHeader="false"
        :prefix="objectInfo.prefix"
        :obj-id="objectInfo.objid"
        :objectApi="objectInfo.objName"
        :pageNum="pageNum"
        :table-attr="objectInfo.fieldList"
        :table-button="tableBtnList"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :view-modify="ismodifyFalg"
        :itemObj="objectInfo"
        :mainId="id"
        @edit="everyEdit"
        @del="everyDelet"
        @onchange="onchange"
        @invitationCode="invitationCodeFun"
        @qrCode="qrCodeFun"
        @activeMember="activeMemberFun"
      ></table-panel>
    </div>
    <div v-else-if="isRlation === 'List' && detailLayoutModeData === 'mode1'">
      <!-- 业务机会下，联系人角色不展示列表，
      要展示关系网图-->
      <table-panel
        v-if="
          objectInfo.relatedlistType !== 'approvalHistory' &&
          objectInfo.relatedlistType !== 'caseteam' &&
          obj.relationName !== $t('lable.opppdt.selPbk.pricebook') &&
          objectInfo.data &&
          objectInfo.data.length > 0 &&
          !objectInfo.data[0].norecord &&
          !knowlegeshow &&
          objectInfo.objName !== 'tp_sys_contactrole'
        "
        :islineEdit="false"
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-index="false"
        :showLock="false"
        :checked="objectInfo.objid === 'attachement' ? true : false"
        :customHeader="false"
        :prefix="objectInfo.prefix"
        :obj-id="objectInfo.objid"
        :objectApi="objectInfo.objName"
        :pageNum="pageNum"
        :table-attr="objectInfo.fieldList"
        :table-button="tableBtnList"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :view-modify="ismodifyFalg"
        :itemObj="objectInfo"
        :mainId="id"
        :isNotPersonaccount="isNotPersonaccount"
        @edit="everyEdit"
        @del="everyDelet"
        @onchange="onchange"
        @changedefaultaddress="changedefaultaddress"
      ></table-panel>
      <!-- 列表 -->
      <knowledgeindextable-panel
        class="text-centerKnow"
        ref="knowledgeindextablePanel"
        v-if="knowlegeshow"
        :border="true"
        :show-index="false"
        :customHeader="false"
        :showLock="false"
        :prefix="objectInfo.prefix"
        :obj-id="objectInfo.objid"
        :objectApi="objectInfo.objName"
        :pageNum="pageNum"
        :table-attr="objectInfo.fieldList"
        :page-obj="pageObj"
        :table-button="tableBtnList"
        :noDataTip="noDataTip"
        :pict-loading="pictLoading"
        :view-modify="ismodifyFalg"
        :itemObj="objectInfo"
        @edit="everyEdit"
        @Additionalclose="Additionalclose"
        :caseKnowList="true"
        @dialogAssembly="dialogAssembly"
      ></knowledgeindextable-panel>
      <!-- 价格手册 -->
      <div
        v-if="
          pricebookItems &&
          pricebookItems.length > 0 &&
          objectInfo.objLabel === $t('lable.opppdt.selPbk.pricebook')
        "
      >
        <table style="width: 100%" bordercolor="#DDDBDA" class="pricebook_box">
          <tr>
            <!-- 价格手册名称 -->
            <th>{{ $t("label.product.pricebooks.name") }}</th>
            <!-- 价目表价格 -->
            <th>{{ $t("lable.product.plist") }}</th>
            <!-- 使用标准价格 -->
            <th>{{ $t("lable.product.pricebooks.ustand") }}</th>
            <!-- 已启用 -->
            <th>{{ $t("label.inusing") }}</th>
            <th></th>
          </tr>
          <tr v-for="(item, index1) in pricebookItems" :key="index1">
            <td @click="jumpPriceDetail(item)" class="jumpPriceClass">
              {{ item.pricebookname }}
            </td>
            <td style="text-align: right">
              {{ item.currency }}
              {{
                item.listPrice &&
                Number(item.listPrice)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
              }}
            </td>
            <!-- 使用标准价格 -->
            <td>
              <el-checkbox
                :value="item.useStdPrice === 'true' ? true : false"
                disabled
              ></el-checkbox>
            </td>
            <td>
              <el-checkbox
                :value="item.isenable === 'true' ? true : false"
                disabled
              ></el-checkbox>
            </td>
            <td class="price_td">
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="hover"
                :visible-arrow="false"
              >
                <div slot="reference" class="xiala_s">
                  <svg
                    class="icon inline_block"
                    aria-hidden="true"
                    style="margin-top: -2px"
                  >
                    <use href="#icon-sj" />
                  </svg>
                </div>
                <ul class="moreButton">
                  <li
                    style="cursor: pointer"
                    v-show="isShowLi('modify')"
                    @click="editPriceItem(item.id, item)"
                  >
                    {{ $t("pagecreator_page_button_edit") }}
                  </li>
                  <li
                    style="cursor: pointer"
                    v-show="isShowLi('delete')"
                    @click="
                      deletPriceItem(item.id, index1, item, obj.relationName)
                    "
                  >
                    {{ $t("component_setup_tabs_label_delete") }}
                  </li>
                </ul>
              </el-popover>
            </td>
          </tr>
        </table>
      </div>

      <!-- 直接把联系人五星图展示在相关列表上，同客户股权架构图，可以放到 王钰祥需求 -->
      <div
        v-if="
          objectInfo.recordObjId === 'opportunity' &&
          objectInfo.objName === 'tp_sys_contactrole' &&
          Object.keys(orgchartData).length > 0
        "
        class="orgchart_box"
      >
        <!-- 顶部title 和 放大图标 -->
        <div class="top_box">
          <div class="top_box_left">
            <div class="top_box_left_title">
              <span>{{ $t("vue_label_commonobjects_detail_network") }}</span>
              <svg
                aria-hidden="true"
                class="example_style"
                @mouseenter="focusExample"
                @mouseleave="mouseleaveExample"
              >
                <use href="#icon-helpp-blue" />
              </svg>
            </div>

            <div class="top_box_left_description">
              {{ $t("label_radar_map_function") }}
            </div>
          </div>
          <!-- 点击全屏显示按钮 -->
          <svg
            aria-hidden="true"
            class="top_box_right"
            @click="isShowFullScreenOrgChart = true"
          >
            <use href="#icon-fullScreenWhite" />
          </svg>
        </div>

        <!-- 关系网图形渲染 -->
        <div
          class="org_chart"
          ref="org_chart"
          :style="{ 'min-height': isShowExampleArea ? '350px' : '' }"
        >
          <org-chart :datasource="orgchartData" v-if="orgchartData">
            <template slot-scope="{ nodeData }">
              <!-- 最顶部的人的名字 -->
              <b class="org_chart_link_style">
                <a :href="`#/commonObjects/detail/${nodeData.pathid}/DETAIL`">
                  {{ nodeData.name }}
                </a>

                <!--最顶部的人的名字旁边的管理按钮 -->
                <svg
                  v-if="nodeData.name && orgchartData.children.length == 0"
                  aria-hidden="true"
                  class="org_chart_manage_btn"
                  @click="handleManageBusSend"
                >
                  <use href="#icon-a-plus2x" />
                </svg>
              </b>
            </template>
          </org-chart>

          <!-- example area -->
          <div class="example_area" v-if="isShowExampleArea">
            <img :src="backgroundComputed" alt="example" />
          </div>
        </div>

        <!-- dialog area -->
        <el-dialog
          :title="$t('vue_label_commonobjects_detail_network')"
          v-if="isShowFullScreenOrgChart"
          :visible="isShowFullScreenOrgChart"
          width="90%"
          top="2vh"
          :before-close="fullScreenOrgChartClose"
          :close-on-click-modal="false"
        >
          <org-chart :datasource="orgchartData" v-if="orgchartData">
            <template slot-scope="{ nodeData }">
              <!-- 最顶部的人的名字 -->
              <b>
                <a :href="`#/commonObjects/detail/${nodeData.pathid}/DETAIL`">
                  {{ nodeData.name }}
                </a>
              </b>
            </template>
          </org-chart>
        </el-dialog>
      </div>
    </div>
    <!-- 在右侧只能是卡片 -->
    <template v-else>
      <!--相关列表下条目信息-->
      <div
        v-if="
          objectInfo.data &&
          objectInfo.data.length !== 0 &&
          objectInfo.data[0] &&
          !objectInfo.data[0].norecord
        "
        class="cont_table"
      >
        <div
          class="cont"
          v-for="(item1, index1) in objectInfo.data.slice(0, 10)"
          :key="index1"
        >
          <div class="cont_div">
            <!-- 文件的相关列表 -->
            <ul
              class="list_left"
              style="position: relative"
              v-if="objectInfo.objName === 'Attachement'"
            >
              <li>
                <el-checkbox
                  v-model="item1.attachChecked"
                  class="work_check"
                ></el-checkbox>
                <!-- @change="attachBtn(item1)" -->
                <dl style="display: flex; width: 90%">
                  <dt>
                    <svg
                      v-if="item1.attachtype === 'dropbox'"
                      class="icon"
                      aria-hidden="true"
                      style="width: 28px; height: 35px; margin: 0 10px 0 10px"
                    >
                      <use href="#icon-dropbox" />
                    </svg>
                    <svg
                      v-else
                      class="icon"
                      aria-hidden="true"
                      style="width: 28px; height: 35px; margin: 0 10px 0 10px"
                    >
                      <use
                        :href="
                          item1.suffix && allIcon[item1.suffix.split('.')[1]]
                            ? allIcon[item1.suffix.split('.')[1]]
                            : defaultIcon
                        "
                      />
                    </svg>
                  </dt>
                  <dd
                    :style="{
                      marginLeft: 0,
                      width: detailLayoutModeData === 'mode1' ? '80%' : '60%',
                    }"
                  >
                    <a :href="rightUrl" @click="detailBtn(item1)">
                      <p
                        style="color: #006dcc; cursor: pointer; width: 100%"
                        @contextmenu.prevent.stop="
                          item1.attachtype === 'dropbox' ? detailBtn(item1) : ''
                        "
                        @mouseenter="handleCellMouseEnter($event)"
                        @mouseleave="handleCellMouseLeave($event)"
                      >
                        <span class="totip topicStyle"
                          >{{ item1.name }}{{ item1.suffix }}</span
                        >
                      </p>
                    </a>
                    <p>{{ item1.createdate | datetimeFormat(countryCode) }}</p>
                  </dd>
                </dl>
                <el-popover
                  placement="bottom-end"
                  :visible-arrow="false"
                  popper-class="pop_small"
                  trigger="hover"
                >
                  <div
                    v-show="!noFile"
                    slot="reference"
                    class="xiala_s"
                    style="position: absolute; top: 0; right: 0"
                  >
                    <svg class="icon inline_block" aria-hidden="true">
                      <use href="#icon-sj" />
                    </svg>
                  </div>
                  <ul class="moreButton">
                    <!-- 编辑 -->
                    <!-- <li
                      style="cursor: pointer"
                      v-if="
                        objectInfo.objid !== 'attachement' &&
                          objectInfo.objName !== 'tp_sys_contactrole' &&
                          objectInfo.services.modify
                      "
                      @click="everyEdit(item1)"
                    >
                      {{ $t('pagecreator_page_button_edit') }}
                    </li>-->
                    <!-- 删除 -->
                    <li
                      style="cursor: pointer"
                      v-show="isShowLi('delete')"
                      @click="everyDelet(objectInfo.objName, item1.id, item1)"
                    >
                      {{ $t("component_setup_tabs_label_delete") }}
                    </li>
                  </ul>
                </el-popover>
              </li>
            </ul>
            <!-- 通用相关列表 -->
            <ul class="list_left" v-else>
              <template v-for="(item, i) in objectInfo.fieldList">
                <li
                  :key="i"
                  v-if="
                    item.name === 'name' ||
                    (objectInfo.objName == 'purchaseorderitem' &&
                      item.name == 'product')
                  "
                >
                  <div class="title">
                    <div class="knowOwnpop">
                      <Popover-cpn
                        v-if="
                          objectInfo.objid === 'cloudcc_k_article' &&
                          item.name === 'name'
                        "
                        :recordObj="item1"
                        :content="item1.name"
                        :knowOwnImg="true"
                        @jumpDetail="jumpDetail(item1)"
                      />
                      <a
                        :href="rightUrl"
                        v-else
                        @click="detailBtn(item1)"
                        @click.right="detailBtnRight(item1)"
                        ><p>{{ item1.name }}</p></a
                      >
                    </div>
                    <el-popover
                      placement="bottom-end"
                      :visible-arrow="false"
                      popper-class="pop_small"
                      trigger="hover"
                      v-if="
                        (objectInfo.services.delete ||
                          objectInfo.services.modify) &&
                        typedisabled !== '草稿'
                      "
                    >
                      <div
                        slot="reference"
                        class="xiala_s"
                        v-if="
                          (objectInfo.services.delete ||
                            objectInfo.services.modify) &&
                          typedisabled !== '草稿'
                        "
                      >
                        <svg class="icon inline_block" aria-hidden="true">
                          <use href="#icon-sj" />
                        </svg>
                      </div>
                      <!-- v-show="objectInfo.objid!=='attachement'" -->
                      <!-- 文件和联系人角色不允许编辑 -->
                      <ul class="moreButton" v-if="!knowlegeshow">
                        <li
                          style="cursor: pointer"
                          v-if="
                            objectInfo.objid !== 'attachement' &&
                            objectInfo.objName !== 'tp_sys_contactrole' &&
                            objectInfo.services.modify
                          "
                          @click="everyEdit(item1)"
                        >
                          {{ $t("pagecreator_page_button_edit") }}
                        </li>
                        <li
                          style="cursor: pointer"
                          v-if="objectInfo.services.delete"
                          @click="
                            everyDelet(objectInfo.objName, item1.id, item1)
                          "
                        >
                          {{ $t("component_setup_tabs_label_delete") }}
                        </li>
                        <li
                          style="cursor: pointer"
                          v-if="objectInfo.objName == 'cloudccustomeraddress'"
                          @click="
                            changedefaultaddress(item1, 'card', 'billing')
                          "
                        >
                          {{ $t("c605") }}
                        </li>
                        <li
                          style="cursor: pointer"
                          v-if="objectInfo.objName == 'cloudccustomeraddress'"
                          @click="
                            changedefaultaddress(item1, 'card', 'shipping')
                          "
                        >
                          {{ $t("c606") }}
                        </li>
                      </ul>
                      <!-- 个案下文章列表 -->
                      <ul class="moreButton" v-if="knowlegeshow">
                        <span v-if="item1.publish_status === '草稿'">
                          <li
                            style="cursor: pointer"
                            @click="Additionalclose(item1)"
                          >
                            <!-- 取消附加文章 -->
                            {{
                              $t("label.knowledgebase.cancel.attached.articles")
                            }}
                          </li>
                          <li style="cursor: pointer" @click="everyEdit(item1)">
                            <!-- 编辑 -->
                            {{ $t("pagecreator.page.button.edit") }}
                          </li>
                        </span>
                        <span
                          v-if="
                            item1.publish_status === '已发布' &&
                            !item1.conditional
                          "
                        >
                          <li
                            style="cursor: pointer"
                            @click="Additionalclose(item1)"
                          >
                            <!-- 取消附加文章 -->
                            {{
                              $t("label.knowledgebase.cancel.attached.articles")
                            }}
                          </li>
                          <li
                            style="cursor: pointer"
                            @click="dialogAssembly(item1, 'draftedit')"
                          >
                            <!-- 作为草稿编辑 -->
                            {{ $t("label.knowledgebase.edit.as.draft") }}
                          </li>
                        </span>
                        <span
                          v-if="
                            item1.publish_status === '已发布' &&
                            item1.conditional
                          "
                        >
                          <li
                            style="cursor: pointer"
                            @click="Additionalclose(item1)"
                          >
                            <!-- 取消附加文章 -->
                            {{
                              $t("label.knowledgebase.cancel.attached.articles")
                            }}
                          </li>
                        </span>
                        <span v-if="item1.publish_status === '已归档'">
                          <li
                            style="cursor: pointer"
                            @click="Additionalclose(item1)"
                          >
                            <!-- 取消附加文章 -->
                            {{
                              $t("label.knowledgebase.cancel.attached.articles")
                            }}
                          </li>
                        </span>
                      </ul>
                    </el-popover>
                  </div>
                </li>
              </template>

              <template v-for="(item, indexs) in objectInfo.fieldList">
                <li :key="indexs" v-if="item.name !== 'name'">
                  <p
                    style="width: 30%"
                    v-if="item.name !== 'name'"
                    @mouseenter="handleCellMouseEnter($event)"
                    @mouseleave="handleCellMouseLeave($event)"
                  >
                    <span class="totip topicStyle">{{ item.label }}:</span>
                  </p>

                  <p
                    style="width: 70%"
                    v-if="
                      item.type === 'Y' ||
                      item.type === 'Q' ||
                      item.type === 'MR' ||
                      item.type === 'M' ||
                      (item.type === 'R' &&
                        objectInfo.objName !== 'influenceBusinessOpportunities')
                    "
                    @click="
                      item.type === 'Y' && item.name === 'contact'
                        ? detailBtn(item1)
                        : ''
                    "
                    :style="{
                      color:
                        item.type === 'Y' && item.name === 'contact'
                          ? '#006dcc'
                          : '',
                      fontWeight:
                        item.type === 'Y' && item.name === 'contact'
                          ? '700'
                          : '',
                      cursor:
                        item.type === 'Y' && item.name === 'contact'
                          ? 'pointer'
                          : '',
                    }"
                    @mouseenter="handleCellMouseEnter($event)"
                    @mouseleave="handleCellMouseLeave($event)"
                  >
                    <span class="totip topicStyle">
                      {{
                        item1[item.name + "ccname"]
                          ? item1[item.name + "ccname"]
                          : item1[item.name]
                      }}
                    </span>
                  </p>
                  <p v-else-if="item.type === 'B'">
                    <el-checkbox
                      :value="
                        item1[item.name] === 'true' ||
                        item1[item.name] === 1 ||
                        item1[item.name] === '1'
                      "
                      disabled
                    ></el-checkbox>
                  </p>
                  <p v-else-if="item.type === 'D'">
                    <!-- 详情-实际工作清单-日期添加点击事件 -->
                    <span
                      v-if="objectInfo.objid === 'projectworklist'"
                      @click="detailBtn(item1)"
                      style="color: #006dcc; fontweight: 700; cursor: pointer"
                      >{{ item1[item.name] | dateFormat(countryCode) }}</span
                    >
                    <span v-else>
                      {{ item1[item.name] | dateFormat(countryCode) }}
                    </span>
                  </p>
                  <p v-else-if="item.type === 'F'">
                    {{ item1[item.name] | datetimeFormat(countryCode) }}
                  </p>
                  <p
                    v-else-if="
                      objectInfo.objName === 'influenceBusinessOpportunities'
                    "
                  >
                    {{ item1[item.name] }}
                  </p>
                  <p
                    style="width: 70%"
                    v-else-if="item.name === 'operate' || item.name === 'clxx'"
                    @mouseover="disBtn(index1)"
                    @mouseenter="handleCellMouseEnter($event)"
                    @mouseleave="handleCellMouseLeave($event)"
                  >
                    <span
                      class="totip dis_a topicStyle"
                      v-html="item1[item.name]"
                    ></span>
                  </p>
                  <!-- 图片-->
                  <p v-else-if="item.type === 'IMG'" class="cannotEdit">
                    <img
                      v-if="
                        item1[item.name] !== undefined &&
                        item1[item.name] !== '' &&
                        item1[item.name] !== null
                      "
                      :src="imgSrc(item1[item.name])"
                    />
                  </p>
                  <!-- 图片-->
                  <p v-else-if="item.type === 'FL'" class="cannotEdit">
                    <span class="totip topicStyle" style="text-align: left">
                      <em
                        style="
                          font-style: normal;
                          color: #006dcc;
                          cursor: pointer;
                        "
                        v-for="(workVal, idx) in item1[
                          item.name + 'ccfilelist'
                        ]"
                        :key="idx"
                        @click="downloadBtn(workVal)"
                        >{{ workVal.name }}</em
                      >
                    </span>
                  </p>
                  <p v-else-if="item.type === 'SCORE'" class="cannotEdit stars">
                    <span
                      v-for="(star, idx) in Number(item.length) + 18"
                      v-show="idx !== 0"
                      :class="{
                        show2: idx <= Number(item1[item.name]),
                      }"
                      :key="idx"
                    ></span>
                  </p>
                  <!-- 富文本需要v-html渲染 -->
                  <p
                    v-else-if="item.type === 'A'"
                    style="width: 70%"
                    @mouseenter="handleCellMouseEnter($event)"
                    @mouseleave="handleCellMouseLeave($event)"
                  >
                    <span class="totip topicStyle" v-html="item1[item.name]">
                    </span>
                  </p>
                  <p
                    v-else
                    style="width: 70%"
                    @mouseenter="handleCellMouseEnter($event)"
                    @mouseleave="handleCellMouseLeave($event)"
                  >
                    <span class="totip topicStyle">
                      {{
                        item.name === "Phone"
                          ? item1["phone"]
                          : item1[item.name]
                      }}
                    </span>
                  </p>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <el-tooltip
        ref="tooltip"
        effect="dark"
        :content="tooltipContent"
      ></el-tooltip>
      <!--价格手册相关列表-->
      <div
        v-if="
          pricebookItems &&
          pricebookItems.length !== 0 &&
          obj.relationName == $t('lable.opppdt.selPbk.pricebook')
        "
        class="cont_table"
      >
        <div
          class="cont"
          v-for="(item1, index1) in pricebookItems"
          :key="index1"
        >
          <div class="title">
            <div>
              <a
                :href="rightUrl"
                @click="detailBtn(item1)"
                @click.right="detailBtnRight(item1)"
                ><p>{{ item1.pricebookname }}</p></a
              >
              <!-- 价目表价格 -->
              <div class="filedname">
                <span class="filednametitle"
                  >{{ $t("lable.product.plist") }}:</span
                >
                <span style="font-size: 14px; color: #080707">
                  {{ item1.currency }}
                </span>
                <span
                  style="
                    display: inline-block;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #080707;
                  "
                  class="font_li"
                  >{{ item1.listPrice }}</span
                >
              </div>
              <!-- 使用标准价格 -->
              <div class="filedname">
                <span class="filednametitle"
                  >{{ $t("lable.product.pricebooks.ustand") }}:</span
                >
                <span>
                  <el-checkbox
                    :value="item1.useStdPrice === 'true' ? true : false"
                    disabled
                  ></el-checkbox>
                </span>
              </div>
              <!-- 已启用 -->
              <div class="filedname">
                <span class="filednametitle">{{ $t("label.inusing") }}:</span>
                <span>
                  <el-checkbox
                    :value="item1.isenable === 'true' ? true : false"
                    @change="changeedit(item1.isenable)"
                    disabled
                  ></el-checkbox>
                </span>
              </div>
            </div>

            <el-popover
              placement="bottom-end"
              popper-class="pop_small"
              trigger="hover"
              :visible-arrow="false"
            >
              <div slot="reference" class="xiala_s">
                <svg
                  class="icon inline_block"
                  style="margin-top: -7px"
                  aria-hidden="true"
                >
                  <use href="#icon-sj" />
                </svg>
              </div>
              <ul class="moreButton">
                <li
                  style="cursor: pointer"
                  v-show="isShowLi('modify')"
                  @click="editPriceItem(item1.id, item1)"
                >
                  {{ $t("pagecreator_page_button_edit") }}
                </li>
                <li
                  style="cursor: pointer"
                  v-show="isShowLi('delete')"
                  @click="
                    deletPriceItem(item1.id, index1, item1, obj.relationName)
                  "
                >
                  {{ $t("component_setup_tabs_label_delete") }}
                </li>
              </ul>
            </el-popover>
          </div>
        </div>
      </div>
    </template>
    <!-- 删除文章、删除草稿、归档、还原、作为草稿编辑 -->
    <Dialog
      :id="id"
      :objId="objId"
      :dialogSign="dialogSign"
      :content="content"
      :btnName="btnName"
      :titleType="titleType"
      :btnSign="btnSign"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <template v-slot:Dialog>
        <div>
          <div v-if="btnSign == 'draftdel'">
            <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
            <div class="dialog-type">
              {{ $t("label.knowledgebase.unable.restore.draft") }}
            </div>
            <div>
              {{
                $t(
                  "label.knowledgebase.delete.translation.associated.with.draft"
                )
              }}
            </div>
          </div>
          <div v-else-if="btnSign == 'articledel'" class="dialog-type">
            <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
            <div>
              {{ $t("label.knowledgebase.permanently.remove.articles") }}
            </div>
          </div>
          <div v-else-if="btnSign == 'file'" class="dialog-type">
            <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
            <div>{{ $t("label.knowledgebase.published.translations") }}</div>
          </div>
          <div v-else-if="btnSign == 'recovery'" class="dialog-type">
            <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
            <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
          </div>
          <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
            <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
            <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 查看更多 -->
    <!-- 批准历史、选择价格手册(不跳转查看更多列表)-->
    <div
      class="see_more"
      v-if="
        objectInfo.data &&
        objectInfo.data[0] &&
        !objectInfo.data[0].norecord &&
        objectInfo.data.length &&
        objectInfo.relatedlistType !== 'approvalHistory' &&
        objectInfo.relatedlistType !== 'caseteam' &&
        objectInfo.recordObjId !== 'opportunity' &&
        objectInfo.objName !== 'tp_sys_contactrole' &&
        objectInfo.objLabel !== $t('lable.opppdt.selPbk.title') &&
        objectInfo.objName !== 'ServiceEvaluation' &&
        (isNotPersonaccount ||
          (!isNotPersonaccount && objectInfo.objName !== 'Contact')) &&
        viewDetail != 'pdf'
      "
    >
      <span @click="seeMove(objectInfo)">{{ seeMoveArr }}</span>
    </div>
    <!-- 附件上传 -->
    <!-- <upload
      v-if="
        objectInfo.objName === 'Attachement'
      "
      style="width: 100%;border-top: 1px solid #ccc"
      ref="upload"
      :fileType="fileType"
      :mainId="id"
      :size="size"
      :limit="10"
      :disabled="noFile"
      :action="action"
      :multiple="multiple"
      :autoUpload="true"
      :identification="identification"
      :drag="true"
      @fileChange="fileChange"
      @success="success"
      @error="error"
      @UploadSuccess="UploadSuccess"
    ></upload> -->
    <!-- 服务报告预览 -->
    <file-preview
      :show="showPreview"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    ></file-preview>
    <!-- （表单营销推广）邀约码 -->
    <invitationCode
      :id="rowId"
      :invitationCode="invitationCode"
      @closeInvitationCodeDialog="closeInvitationCodeDialog"
      @closeSendMail="closeSendMail"
    ></invitationCode>
    <!-- （表单营销推广）二维码 -->
    <qrcode
      ref="qrcode"
      :bigTitle="bigTitle"
      :qrCodeId="rowId"
      :qrCodeName="qrCodeName"
    ></qrcode>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import * as CommonObjApi from "./api";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";
// import OrderLineProcess from "@/views/commonObjects/detailChild/OrderLineProcess/OrderLineProcess.vue";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import { Upload, Dialog } from "@/components/index";
//三个点显示气泡
import { getStyle } from "@/utils/threePoints.js";
import debounce from "throttle-debounce/debounce";
import detailSendCard from "@/utils/robotMessage.js";
import VueDropboxPicker from "@/components/vue-dropbox-chooser/DropboxPicker.vue";
import { getSaveDropbox, cancelAttachCase } from "./api.js";
import { TablePanel, knowledgeindextablePanel } from "@/components/index.js";
import createButton from "@/utils/Button";
import * as Time from "@/utils/time";
import PopoverCpn from "@/views/homePage/components/PopoverCpn";
import filePreview from "@/components/FileView/filePreview";
import serviceEvaluation from "@/views/commonObjects/components/service-evaluation/serviceEvaluation.vue";
import DialogMixin from "@/mixin/Dialog.js";
import invitationCode from "@/views/commonObjects/formMarketingPromotion/invitationCode.vue";
import qrcode from "@/views/commonObjects/formMarketingPromotion/qrcode.vue";
import caseteam from "./caseTeam/caseteam.vue";
import caseHeardList from "@/views/commonObjects/fieldHeard.js";

export default {
  mixins: [DialogMixin],
  name: "relation-list-obj",
  components: {
    ApprovalHistory,
    // OrderLineProcess,
    Upload,
    VueDropboxPicker,
    TablePanel,
    knowledgeindextablePanel,
    Dialog,
    PopoverCpn,
    filePreview,
    // 架构图
    OrgChart: () => import("@/components/OrgChart"),
    serviceEvaluation,
    invitationCode,
    qrcode,
    FormPromotionRoi: () => import("./FormPromotionRoi.vue"),
    caseteam,
  },
  props: {
    // 区分PDF视图详情页
    // PDF视图详情页不显示相关列表名称和图标
    viewDetail: {
      type: String,
      default: "",
    },
    // 是否有权限从cloudcc中上传文件
    isispermissionUpload: {
      type: Boolean,
      default: false,
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    // 记录是否锁定
    isLocked: {
      type: Boolean,
      default: false,
    },
    detailLayoutMode: {
      type: String,
      default: "mode1",
    },
    // 相关列表的对象信息
    obj: {
      type: Object,
      default: () => ({}),
    },
    // 主记录id
    id: {
      type: String,
      default: "",
    },
    // 主记录名称
    bigTitle: {
      type: String,
      default: "",
    },
    // 主记录对象id
    objId: {
      type: String,
      default: "",
    },
    isRlation: {
      type: String,
      default: "Card",
    },
    typedisabled: {
      type: String,
      default: "",
    },
    // 结构图数据
    orgchartData: {
      type: Object,
      default: () => {},
    },
    // 不是个人客户
    isNotPersonaccount: {
      type: Boolean,
      default: false,
    },
    // 考勤组 考勤人员
    attendanceStaffData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 当前从记录数据条数
      relationSize: 0,
      // 右键跳详情的a标签的url
      rightUrl: "javascript:void(0);",
      isShowFullScreenOrgChart: false, //是否全屏显示orgchart
      isShowExampleArea: false, //是否显示example area
      serviceEvaluationNumber: 0, //客户下 联系人的评价数量
      noFile: false,
      pricebookItems: {},
      perPage: 10,
      objectInfo: {},
      influenceData: [],
      seeMoveArr: this.$i18n.t("label.viewall"), //"查看全部"
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      action: "/test",
      fileType: [],
      size: 1024 * 500,
      multiple: true,
      identification: "",
      tooltipContent: "",
      dropboxApiKey: "aj2eb0wetk4aocr",
      isVueDropboxPicker: this.$store.state.VueDropboxPicker, //判断VueDropboxPicker组件是否加载完成
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      popNoDataTip: false, // 是否提示无更多数据
      pictLoading: false,
      pageNum: 2, //页码默认为1
      noDataTip: false,
      ismodifyFalg: "false",
      pageObj: {
        dataList: [],
      },
      drafteditdialogVisible: false,
      tableBtnList: {
        // 操作
        title: this.$i18n.t("label_partner_all_operate"),
        buttonList: [
          createButton({
            label: this.$i18n.t("pagecreator_page_button_edit"), //"编辑",
            action: "edit",
          }),
          createButton({
            label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
            action: "del",
          }),
        ],
        buttonListKnowone: [],
        buttonListKnowtwo: [],
        buttonListKnowthree: [],
        buttonListKnowfour: [],
      },
      showBtnLength: 5, // 相关列表按钮展示个数
      dropbox: false,
      knowlegeshow: false, //知识文章列显示
      // 文件预览参数
      showPreview: false,
      showPreviewData: {},
      previewUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      isSubmit: false, // 相关列表【批准历史】中是否包含提交待审批按钮
      invitationCode: false, //邀约码开关
      rowId: "", //邀约码每行id
      qrCodeName: "", //二维码每行名称
      // 是否显示表单营销推广弹窗
      isShowformPromotion: false,
      /**
       * 表单推广ROI请求参数
       * campaignid 市场活动表id
       */
      campaignid: this.id,
      detailLayoutModeData: this.detailLayoutMode,
      // 客户小组
      accountTeamList: {},
      // 考勤人员loading
      KaoqinLoading: false,
    };
  },
  beforeDestroy() {
    this.$Bus.$off("KaoqinLoading", this.setKaoqinLoading);
  },
  mounted() {
    this.showBtnLength = this.detailLayoutModeData === "mode1" ? 5 : 0;
    this.$Bus.$on("KaoqinLoading", this.setKaoqinLoading);
  },
  created() {
    //三个点显示气泡
    this.activateTooltip = debounce(50, (tooltip) =>
      tooltip.handleShowPopper()
    );
    this.detailLayoutModeData = this.$cookies.get("detailLayoutMode");
  },
  methods: {
    setKaoqinLoading(val) {
      this.KaoqinLoading = val;
    },
    /**
     * 判断从记录内容操作按钮是否有权限（控制显示隐藏）
     * @param {string} btn:按钮对象信息
     */
    isShowLi(btn) {
      if (this.objectInfo?.services) {
        // 控制显示隐藏
        return this.objectInfo.services[btn] !== false;
      } else {
        return true;
      }
    },
    // 点击按钮，向父页面发射点击管理按钮事件
    handleManageBusSend() {
      this.$bus.$emit("handleManageBusSend");
    },
    fullScreenOrgChartClose() {
      this.isShowFullScreenOrgChart = false;
    },
    focusExample() {
      this.isShowExampleArea = true;
    },
    mouseleaveExample() {
      this.isShowExampleArea = false;
    },
    // 用户是否有文件操作权限
    getPermission() {
      // 某条记录锁定，且当前用户对这条记录没有编辑权限
      CommonObjApi.getPermissionById({
        id: this.id,
      }).then((res) => {
        if (res.data.isLocked === true && res.data.isEdit === false) {
          this.noFile = true;
        }
      });
    },
    init() {
      if (this.obj.attachementType && this.obj.attachementType === "file") {
        this.getPermission();
      }
      this.objectInfo = {};
      // 价格手册
      if (this.obj.relatedlistId === "pricebook") {
        this.objectInfo = {
          objLabel: this.$i18n.t("lable.opppdt.selPbk.pricebook"),
          showButton: [
            //   "添加标准价格"
            { id: "", label: this.$i18n.t("vue_label_pricebook_price") },
            { id: "", label: this.$i18n.t("lable.product.pricebooks.add") }, //"添加到价格手册"
          ],
        };

        this.$forceUpdate();
        this.querypricebooklist();
      } else if (this.obj.relatedlistId === "attendanceStaff") {
        // 考勤人员
        this.objectInfo = {
          objLabel: this.$i18n.t("c1789"),
          showButton: [
            //关联用户
            { id: "relateToUser", label: this.$i18n.t("c1790") },
          ],
        };
      } else if (this.obj.relatedlistId === "influenceBusinessOpportunities") {
        // 影响业务机会
        this.GetEffectOpp();
      } else {
        this.getDetailRelatedItems(this.obj);
      }
    },
    //获取影响业务机会
    GetEffectOpp() {
      CommonObjApi.GetEffectOpp({ id: this.id }).then((res) => {
        this.influenceData = res.data;
        this.objectInfo = {
          objLabel: this.$i18n.t("vue_label_campaign_influencedopportunity"), //'影响业务机会'
          objName: "influenceBusinessOpportunities",
          data: this.influenceData,
          fieldList: [
            { name: "name", label: this.$i18n.t("label_tabpage_busoppnamez") }, //业务机会名
            { name: "contact", label: this.$i18n.t("label.quote.contactname") }, //联系人姓名
            {
              name: "Influence",
              label: this.$i18n.t("vue_label_commonobjects_detail_Influence"),
            }, //影响力
            {
              name: "revenueShare",
              label: this.$i18n.t("vue_label_commonobjects_detail_share"),
            }, //"收入份额"
            {
              name: "amount",
              label: this.$i18n.t("label.stdashbord.xsld.business.jine"),
            }, //金额
          ],
          totalCount: this.influenceData.length,
        };
      });
    },
    // 价格手册
    querypricebooklist() {
      // eslint-disable-next-line vue/no-mutating-props
      this.obj.loading = true;
      CommonObjApi.queryProductPricebookItems({ productId: this.id }).then(
        (res) => {
          // 如果价格手册相关列表无数据，添加到价格手册按钮禁止
          if (
            this.objectInfo.objLabel ===
              this.$i18n.t("lable.opppdt.selPbk.pricebook") &&
            res.data.pricebookItems.length > 0 &&
            this.objectInfo.showButton[1].label ===
              this.$i18n.t("lable.product.pricebooks.add")
          ) {
            this.objectInfo.showButton[1].isFlag = false;
          } else {
            this.objectInfo.showButton[1].isFlag = true;
          }
          this.pricebookItems = res.data.pricebookItems;
          this.objectInfo.totalCount = this.pricebookItems.length;
          this.$emit("getPricebook", res.data.pricebookItems);
          // eslint-disable-next-line vue/no-mutating-props
          this.obj.loading = false;
        }
      );
      CommonObjApi.hideAddPricebookButton({ productId: this.id }).then(
        (res) => {
          if (res.data === "true") {
            this.objectInfo.showButton[0].isAddFlag = true;
          } else {
            this.objectInfo.showButton[0].isAddFlag = false;
          }
        }
      );
    },
    getDetailRelatedItems(everyItem) {
      if (this.obj.relatedlistType === "accountteam") {
        CommonObjApi.getRelateObjOption({}).then((result) => {
          this.accountTeamList = {};
          result.data?.objOptions?.forEach(item => {
            this.accountTeamList[item.codevalue] = item.codetext;
          })
        });
      }
      let params = {
        recordId: this.id,
        layoutId: everyItem.layoutId ? everyItem.layoutId : "",
        relatedlistId: everyItem.relatedlistId ? everyItem.relatedlistId : "",
        perPage: this.viewDetail ? 0 : this.perPage, //PDF视图详情页显示全部数据，不显示查看更多按钮
        attachementType: everyItem.attachementType
          ? everyItem.attachementType
          : "",
      };
      everyItem.loading = true;
      CommonObjApi.GetDetailRelatedItems(params).then((res) => {
        // "个案历史跟踪":表头数据在前端获取 relatedlistId
        if (res?.data?.relatedlistType === "casehis") {
          caseHeardList.forEach((item) => {
            item.label = this.$i18n.t(item.label);
            if (Array.isArray(item.options)) {
              item.options.forEach((opItem) => {
                opItem.codevalue = this.$i18n.t(opItem.codevalue);
              });
            }
          });
          res.data.fieldList = caseHeardList;
        }
        // 判断知识文章下搜索性能词列表操作列
        // if (
        //   res.data.objid == "promoted_search_term" &&
        //   (this.typedisabled == "草稿" || this.typedisabled == "已归档")
        // ) {
        //   this.tableBtnList.buttonList = [];
        // }
        // 判断知识文章列表是否显示
        // if (res.data.objid == "cloudcc_k_article" && res.data.data.length > 0) {
        //   this.knowlegeshow = true;
        // }
        // 判断相关列表【批准历史】的按钮集合中是否包含提交待审批按钮
        // if (res.data.type == "approvalHistory") {
        //   const { buttons } = res.data;
        //   if (buttons && buttons.length > 0) {
        //     this.isSubmit = buttons.some((item) => item.name === "Submit");
        //   } else {
        //     this.isSubmit = false;
        //   }
        // }
        if (res.result && res.returnCode === "1") {
          // 给从记录设置relationSize
          let dataList = res.data?.data;
          if (dataList) {
            if (dataList[0] && dataList[0].norecord) {
              this.relationSize = 0;
            } else {
              this.relationSize = dataList.length || 0;
              if (dataList.length > 9) {
                this.relationSize = "10+";
              }
            }
          }else{
            this.relationSize = 0;
          }

          // 手动添加totalCount属性
          res.data.totalCount = this.relationSize;
          this.$emit(
            "setRelationSize",
            this.obj.relatedlistId,
            this.relationSize || 0
          );
          // // 表单营销推广(操作按钮 编辑、删除、邀约码、二维码、市场活动)
          // if (
          //   res.data &&
          //   res.data.objLabel &&
          //   res.data.objLabel === this.$i18n.t("c11")
          // ) {
          //   this.tableBtnList = {
          //     // 操作
          //     title: this.$i18n.t("label_partner_all_operate"),
          //     buttonList: [
          //       {
          //         action: "edit",
          //         label: this.$i18n.t("label.emailtocloudcc.edit"),
          //       },
          //       {
          //         action: "del",
          //         label: this.$i18n.t("pagecreator.page.button.delete"),
          //       },
          //       {
          //         action: "invitationCode",
          //         label: this.$i18n.t("label.yard"),
          //       },
          //       { action: "qrCode", label: this.$i18n.t("label.qrcode") },
          //       {
          //         action: "activeMember",
          //         label: this.$i18n.t("label.members.activity"),
          //       },
          //     ],
          //   };
          // }
          // // 个案下文章列表
          // if (res.data.objid === "cloudcc_k_article") {
          //   this.tableBtnList.buttonListKnowone = [
          //     createButton({
          //       // 取消附加文章
          //       label: this.$i18n.t(
          //         "label.knowledgebase.cancel.attached.articles"
          //       ),
          //       action: "Additionalclose",
          //     }),
          //     createButton({
          //       // 编辑
          //       label: this.$i18n.t("label.modify"),
          //       action: "edit",
          //     }),
          //   ];
          //   this.tableBtnList.buttonListKnowtwo = [
          //     createButton({
          //       // 取消附加文章
          //       label: this.$i18n.t(
          //         "label.knowledgebase.cancel.attached.articles"
          //       ),
          //       action: "Additionalclose",
          //     }),
          //     createButton({
          //       // 作为草稿编辑
          //       label: this.$i18n.t("label.knowledgebase.edit.as.draft"),
          //       action: "draftedit",
          //     }),
          //   ];
          //   this.tableBtnList.buttonListKnowthree = [
          //     createButton({
          //       // 取消附加文章
          //       label: this.$i18n.t(
          //         "label.knowledgebase.cancel.attached.articles"
          //       ),
          //       action: "Additionalclose",
          //     }),
          //   ];
          //   this.tableBtnList.buttonListKnowfour = [
          //     createButton({
          //       // 取消附加文章
          //       label: this.$i18n.t(
          //         "label.knowledgebase.cancel.attached.articles"
          //       ),
          //       action: "Additionalclose",
          //     }),
          //   ];
          // }
          // if (res.data.objid === "attachement") {
          //   this.$emit("dropboxState", res.data.dropboxState);
          // }
          // if (
          //   res.data &&
          //   res.data.objLabel &&
          //   res.data.objLabel === this.$i18n.t("label.quote.detail")
          // ) {
          //   //"报价单明细"
          //   this.$emit("offeres", res.data);
          // }
          if (res.data !== null && res.data !== undefined) {
            this.getRelationListButton(res.data);
            this.$set(
              res.data,
              "titImg",
              res.data.tabStyle ? res.data.tabStyle : ""
            );
            this.objectInfo = res.data;
            // 如果是客户小组的话，特殊处理相关对象权限
            if (res.data.objName === "AccountTeam") {
              this.objectInfo?.data.forEach((item) => {
                item.xglbqx = this.accountTeamList[item.xglbqx];
              });
            }
            // 个人客户下的联系人相关列表(卡片式)，name字段处理
            if (
              this.isRlation === "Card" &&
              this.objectInfo.objName === "Contact" &&
              this.isNotPersonaccount === false
            ) {
              this.objectInfo.data.map((item) => {
                item.name = item.name.split("ispersonaccount").join("");
                item.objApi = "Contact";
              });
            }
            //表单营销推广只展示列表式
            if (
              this.isRlation === "List" ||
              res.data.objid === "campaignform"
            ) {
              this.objectInfo.fieldList.forEach((item, idx) => {
                // 名称、查找、主详、查找多选均可跳转
                if (
                  item.name === "name" ||
                  item.type === "Y" ||
                  item.type === "M" ||
                  item.type === "MR" ||
                  item.name === "subject" ||
                  item.lookupObj === "user" ||
                  item.name === "whoid" ||
                  item.name === "relateid"
                ) {
                  this.$set(this.objectInfo.fieldList[idx], "click", "detail");
                }
                // 项目管理系统-详情-实际工作清单点击日期跳转详情
                if (
                  sessionStorage.getItem("activeTabObjId") === "projectworklist"
                ) {
                  if (item.type === "D") {
                    this.$set(
                      this.objectInfo.fieldList[idx],
                      "click",
                      "detail"
                    );
                  }
                }
                if (
                  this.objectInfo.type === "attachement" &&
                  this.noFile === false
                ) {
                  this.tableBtnList = {
                    // 操作
                    title: this.$i18n.t("label_partner_all_operate"),
                    buttonList: [
                      createButton({
                        label: this.$i18n.t(
                          "component_setup_tabs_label_delete"
                        ), //"删除",
                        action: "del",
                      }),
                    ],
                  };
                }
                if (this.objectInfo.type === "attachement" && this.noFile) {
                  this.tableBtnList = {
                    // 操作
                    title: this.$i18n.t("label_partner_all_operate"),
                    buttonList: [],
                  };
                }
                if (
                  this.objectInfo.objName === "tp_sys_contactrole" ||
                  this.objectInfo.objName === "opportunitypdt" ||
                  this.objectInfo.objName === "opportunityteam"
                ) {
                  this.tableBtnList = {};
                }
                //文件字段转换字段名称
                if (item.type === "FL") {
                  this.objectInfo.data.forEach((childVal) => {
                    this.$set(
                      childVal,
                      item.name + "List",
                      childVal[item.name + "ccfilelist"]
                    );
                  });
                }
                this.$set(this.objectInfo.fieldList[idx], "sortByThis", false);
                this.$set(this.objectInfo.fieldList[idx], "sortDir", "asc");
                this.$set(this.objectInfo.fieldList[idx], "fixed", false);
                this.$set(this.objectInfo.fieldList[idx], "locked", false);
                item.schemefieldType = item.type;
                item.schemefieldName = item.name;
                item.schemefieldLength = item.length;
                item.apiname = item.name;
                item.nameLabel = item.label;
              });
              // 个人客户下的联系人相关列表，name字段处理
              if (
                this.objectInfo.objName === "Contact" &&
                this.isNotPersonaccount === false
              ) {
                this.objectInfo.data.map((item) => {
                  item.name = item.name.split("ispersonaccount").join("");
                  item.objApi = "Contact";
                });
              }
              // PDF视图详情页展示全部数据
              if (this.viewDetail) {
                this.pageObj.dataList = this.objectInfo.data;
              } else {
                this.pageObj.dataList =
                  this.objectInfo.data && this.objectInfo.data.slice(0, 10);
              }
              this.$nextTick(() => {
                this.$refs.tablePanel &&
                  this.$refs.tablePanel.changeTableAttr(
                    this.objectInfo.fieldList
                  );
              });
            }
            setTimeout(() => {
              everyItem.loading = false;
            }, 1000);
          }
          //客户地址相关列表更改内联编辑状态，改为不可编辑
          if (res.data.objName == "cloudccustomeraddress") {
            res.data.fieldList.forEach((ele) => {
              ele.modify = "false";
            });
            this.tableBtnList.buttonList = [
              createButton({
                label: this.$i18n.t("pagecreator_page_button_edit"), //"编辑",
                action: "edit",
              }),
              createButton({
                label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
                action: "del",
              }),
              createButton({
                label: this.$t("c605"),
                action: "billing",
              }),
              createButton({
                label: this.$t("c606"),
                action: "shipping",
              }),
            ];
          }
          // 个案团队按钮处理
          if (res.data.type == "caseteam") {
            this.objectInfo.showButton = [
              {
                id: "updateCaseteam",
                label: this.$i18n.t("c1078"),
              },
            ];
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取相关列表按钮
    getRelationListButton(param) {
      this.$emit("getRelationListButton", param);
    },
    /**
     * 考勤人员移除方法
     */
    handleRemoveKaoqin(row) {
      let arr = [];
      arr = this.attendanceStaffData.filter((item) => {
        return item.id !== row.id;
      });
      this.$emit("handleChangeKaoqin", arr);
    },
    // 相关列表按钮点击方法
    buttonFun(item) {
      // 自定义按钮
      if (
        item.category === "CustomButton" &&
        item.id !== "adc201300153342D9xq4"
      ) {
        // lightning版本自定义按钮 调用特定方法执行服务
        if (item.event === "lightning") {
          // 替换id
          if (item.function_code.indexOf("{!id}") !== -1) {
            item.function_code = item.function_code.replace("{!id}", this.id);
          }
          let param = JSON.parse(item.function_code);
          param.id = this.id;
          let loadingInstance = Loading.service(this.loadingOption);
          CommonObjApi.openCall(param).then((res) => {
            loadingInstance.close();
            if (res.data && res.data.action) {
              // 刷新页面
              if (res.data.action === "refresh") {
                this.$Bus.$emit("custombtnrefresh");
              } else if (res.data.action === "redirect") {
                // 重定向 需判断当前窗口打开还是新窗口打开
                if (res.data.mode === "new") {
                  window.open(res.data.url);
                } else if (res.data.mode === "now") {
                  window.location.href = res.data.url;
                }
              } else if (res.data.action === "alert") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
              }
              //先弹窗，在当前页面跳转
              else if (res.data.action === "alert_refresh") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  this.$Bus.$emit("custombtnrefresh");
                  // this.refreshAll();
                }, 1000);
              }
              //先弹窗,在新标签页跳转
              else if (res.data.action === "alert_redirect") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  // 重定向 需判断当前窗口打开还是新窗口打开
                  if (res.data.mode === "new") {
                    window.open(res.data.url);
                  } else if (res.data.mode === "now") {
                    if (`/${window.location.hash}` === res.data.url) {
                      this.$Bus.$emit("custombtnrefresh");
                      // this.refreshAll();
                    } else {
                      window.location.href = res.data.url;
                    }
                  }
                }, 1000);
              }
            }
          });
        }
        // 自定义按钮lightning-script
        if (item.event === "lightning-script") {
          let funText = item.function_code
            ? item.function_code
            : item.functionCode;
          let newFun = Function(funText);
          newFun();
        }
        if (item.event === "lightning-url") {
          // 替换id
          if (item.function_code.indexOf("{!id}") !== -1) {
            item.function_code = item.function_code.replace("{!id}", this.id);
          }
          // 当前窗口打开
          if (item.behavior === "self") {
            // window.location.href = item.function_code
            if (item.function_code.substring(0, 7) == "cloudcc") {
              let searchUrl = item.function_code.split("?")[1];
              let id = item.function_code
                .split("?")[1]
                .split("&")[0]
                .split("=")[1];
              let page = item.function_code
                .split("?")[1]
                .split("&")[1]
                .split("=")[1];
              // 判断是否显示菜单栏
              let routeData = "";
              if (item.menubar == "show") {
                // 展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent",
                  query: { id: id, page: page, button: "Home" },
                });
              } else {
                // 不展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent1",
                  query: { id: id, page: page },
                });
              }
              if (item.function_code.indexOf("target") !== -1) {
                //在本页面打开一个新的窗口
                let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
                if (paramsObj.target.indexOf("dialog") !== -1) {
                  let obj = {
                    title: paramsObj.dialogTitle || "详情",
                    width: paramsObj.dialogWidth || "50%",
                    height: paramsObj.dialogHeight || "",
                    pageApi: page,
                  };
                  this.$bus.$emit("openDialog", obj);
                } else if (paramsObj.target.indexOf("window") !== -1) {
                  window.open(
                    routeData.href,
                    "newWindow",
                    "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                  );
                }
              } else {
                // 覆盖
                window.open(routeData.href, "_self");
              }
            } else {
              window.location.href = item.function_code;
            }
          } else {
            // 新窗口打开
            // window.open(item.function_code)
            // 新窗口打开
            if (item.function_code.substring(0, 7) == "cloudcc") {
              let searchUrl = item.function_code.split("?")[1];
              let paramsObj = this.$qs.parse(searchUrl);
              let id = item.function_code
                .split("?")[1]
                .split("&")[0]
                .split("=")[1];
              let page = item.function_code
                .split("?")[1]
                .split("&")[1]
                .split("=")[1];
              // 判断是否显示菜单栏
              let routeData = "";
              if (item.menubar == "show") {
                // 展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent",
                  query: {
                    id: id,
                    page: page,
                    button: "Home",
                    dialogTitle: paramsObj.dialogTitle,
                  },
                });
              } else {
                // 不展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent1",
                  query: {
                    id: id,
                    page: page,
                    dialogTitle: paramsObj.dialogTitle,
                  },
                });
              }
              window.open(routeData.href, "_blank");
            } else {
              window.open(item.function_code);
            }
          }
        } else if (
          item.event === "JavaScript" ||
          (item.event === "URL" && item.id !== "adc201300153342D9xq4")
        ) {
          this.$message.warning(this.$i18n.t("lable.Please.switch"));
          //
        }
      } else {
        // 添加该相关列表relationFieldId
        if (this.obj.relationFieldId) {
          this.objectInfo.relationFieldId = this.obj.relationFieldId;
        }
        if (
          this.obj.relationName ===
          this.$i18n.t("lable.opppdt.selPbk.pricebook")
        ) {
          // 产品 - 价格手册，需要回传价格手册条目
          this.$emit("buttonFun", item, this.objectInfo, this.pricebookItems);
        } else if (this.obj.relatedlistId === "attendanceStaff") {
          // 产品 - 价格手册，需要回传价格手册条目
          this.$emit("buttonFun", item, this.objectInfo, this.pricebookItems);
        } else {
          // 如果是添加文件 并且禁用了
          if (
            item.label === this.$i18n.t("label.file.library.selectFile2Lib") &&
            this.noFile
          ) {
            this.$message.warning(
              this.$i18n.t("front-lighting-module-v1-detail-file-lockInfo")
            );
          } else {
            this.$emit("buttonFun", item, this.objectInfo);
          }
        }
      }
    },
    // 查看更多跳转对应的相关列表
    seeMove(val) {
      this.$emit("seeMove", val);
    },
    /**
     * 卡片式相关列表右键打开，跳对应详情
     */
    detailBtnRight(item) {
      // 个人客户下的联系人相关列表(卡片式)，name字段跳转
      if (item.objApi && item.objApi === "Contact") {
        this.rightUrl = `#/commonObjects/detail/${this.objectInfo.recordId}/DETAIL`;
      } else if (this.objectInfo.objName === "Attachement") {
        let baseURL = this.$baseConfig.baseURL;
        let token = window.this.$CCDK.CCToken.getToken();
        this.rightUrl = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${item.id}`;
      } else if (this.objectInfo.objName === "tp_sys_contactrole") {
        this.rightUrl = `#/commonObjects/detail/${item.contact}/DETAIL?viewId=${this.viewId}`;
      } else {
        this.rightUrl = `#/commonObjects/detail/${item.id}/DETAIL?viewId=${this.viewId}`;
      }
    },
    // 跳转对应的详情页
    detailBtn(param) {
      /**
       * 如果有文件id，则是文件预览
       * 没有文件id，则是跳转到原本功能的查看详情页功能
       */
      if (param.fileInfoId) {
        this.openPreview(param);
      } else {
        this.$emit("detailBtn", this.objectInfo, param);
      }
    },
    // 打开预览
    openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = {
        name: "pdf",
        type: "pdf",
        id: item.fileInfoId,
      };
      // let { fileButtons } = item;
      // let btns = fileButtons.filter((item) => {
      //   return item === "download" || item === "viewinfo" || item === "share";
      // });
      // this.$refs.previewFile.handleBtn(btns);
      this.$refs.previewFile.handleBtn(["download"]);
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
    },
    // 编辑
    everyEdit(param) {
      this.$emit("everyEdit", this.objectInfo, param);
    },
    // 删除
    everyDelet(objName, id, item1) {
      this.$emit("everyDelet", this.objectInfo, objName, id, item1);
    },
    // 编辑价格手册条目
    editPriceItem(id, item1) {
      this.$emit("editPriceItem", id, item1, this.pricebookItems);
    },
    // 删除价格手册条目
    deletPriceItem(id, index1, item1, name) {
      this.$emit(
        "deletPriceItem",
        id,
        index1,
        item1,
        this.pricebookItems,
        name
      );
    },
    onchange(arr) {
      this.$emit("getWorkList", arr);
    },
    //客户相关列表地址字段按钮处理
    changedefaultaddress(data, type, value) {
      data.action = value != undefined ? value : data.action;
      this.$emit("changedefaultaddress", data, type);
    },
    // 相关列表【批准历史】对应的按钮集合
    showHistoryBtn(data) {
      this.objectInfo.showButton = [];
      /*
       * 关于【提交待审批】按钮，有两个地方显示
       * 1.详情页顶部，提交待审批按钮，走/objectdetail/getBrief接口
       * 2.相关列表，批准历史中的提交待审批按钮，权限需要两个地方查询
       * - 相关列表中的按钮集合中是否包含，提交待审批按钮
       * - 获取审批历史的接口中，返回的isCanSubmit，表示当前记录是否符合提交审批
       */
      if (this.isSubmit && data.showSubmit) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        }); //"提交待审批"
      }
      if (data.showApproval) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.approveorrefuse"),
          hisId: "2",
        }); //进行审批
      }
      if (data.showReassign) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.redistribution"),
          hisId: "3",
        }); //重新分配
      }
      if (data.showRecall) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.manageAll.sta4"),
          hisId: "1",
        }); //调回批准请求
      }
      this.$emit("historyBtn", data);
    },
    handleHistory(i, detailGroupId, recordId) {
      this.$refs.myChlidHistory.parentClickHistory(i, detailGroupId, recordId);
    },
    // 相关列表菜单跳转列表视图
    tableView(obj) {
      // 个人客户下的联系人相关列表不跳转
      if (!this.isNotPersonaccount && obj.prefix === "003") {
        return false;
      } else {
        this.$router.push({
          path: `/commonObjects/suspension-list/${this.id}/${obj.attachementType}`,
          query: {
            relatedlistId: obj.relatedlistId,
            layoutId: obj.layoutId,
            objId: this.objId,
            relatedlistType: obj.relatedlistType,
          },
        });
      }
    },
    //获取上传成功信息
    UploadSuccess(item) {
      let data = [];
      let dataItem = {};
      dataItem.recordid = this.id;
      dataItem.name = item.name;
      dataItem.type = item.type;
      dataItem.fileContentId = item.fileContentId;
      dataItem.fileinfoid = item.fileinfoid;
      dataItem.filesize = item.filesize;
      data.push(dataItem);
      CommonObjApi.Bind(JSON.stringify(data)).then((res) => {
        let self = this;
        if (res.result === true) {
          //群策卡片
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].cardValue) {
              (function (i) {
                setTimeout(async () => {
                  let fieldsList = [];
                  res.data[i].cardValue.fieldsList.forEach((item) => {
                    if (
                      fieldsList.length < 5 &&
                      item.fieldValue != null &&
                      item.fieldValue != "true" &&
                      item.fieldValue != "false" &&
                      item.fieldValue != "0" &&
                      item.fieldValue != "1"
                    ) {
                      fieldsList.push(item);
                    }
                  });
                  await detailSendCard(res.data[i].cardValue.groupId, {
                    title: self.$i18n.t("vue_label_newfile"),
                    recordId: res.data[i].cardValue.id,
                    type: "NORMAL_CARD",
                    body: fieldsList,
                    changer: localStorage.getItem("username"),
                  });
                }, 500 * i);
              })(i);
            }
          }
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("vue_label_notice_add_fail"),
          });
        }
      });
      // this.$parent.$parent.uploadRefresh();
      this.getDetailRelatedItems(this.obj);
    },
    //a链接禁止跳转
    disBtn(index) {
      let cityNode = document.getElementsByClassName("dis_a");
      let cityLiNodes = cityNode[index].getElementsByTagName("a")[0];
      if (cityLiNodes) {
        cityLiNodes.setAttribute("href", "javascript:;");
        cityLiNodes.style.disabled = "true";
        //悬浮a禁止
        let cityNodeTooltip = document
          .getElementsByClassName("dis_b")[0]
          .getElementsByTagName("a")[0];
        cityNodeTooltip && cityNodeTooltip.setAttribute("href", "javascript:;");
        cityNodeTooltip.style.disabled = "true";
      }
    },
    // 价格手册相关列表 -- 名称支持跳转
    jumpPriceDetail(item) {
      this.$router.push({
        path: `/priceManual/price-detail/${item.pricebook_id}`,
      });
    },
    // 鼠标移入
    handleCellMouseEnter(event) {
      const target = event.target;
      // 判断是否text-overflow, 如果是就显示tooltip
      let child = target.querySelector(".topicStyle");
      // 如果区域宽度被限定，则通过高度判断
      let heightFlag = child.scrollHeight > child.offsetHeight;
      const range = document.createRange();
      range.setStart(child, 0);
      range.setEnd(child, child.childNodes.length);
      const rangeWidth = range.getBoundingClientRect().width; // 文本区域宽度
      const padding =
        (parseInt(getStyle(target, "paddingLeft"), 10) || 0) +
        (parseInt(getStyle(target, "paddingRight"), 10) || 0);
      if (
        rangeWidth + padding > target.offsetWidth ||
        child.scrollWidth > child.offsetWidth ||
        (heightFlag && this.$refs.tooltip)
      ) {
        const tooltip = this.$refs.tooltip;
        // TODO 会引起整个 Table 的重新渲染，需要优化
        this.tooltipContent = target.innerText || target.textContent;
        tooltip.referenceElm = target;
        tooltip.$refs.popper && (tooltip.$refs.popper.style.display = "none");
        tooltip.doDestroy();
        tooltip.setExpectedState(true);
        this.activateTooltip(tooltip);
      }
    },

    // 鼠标移出
    handleCellMouseLeave() {
      const tooltip = this.$refs.tooltip;
      if (tooltip) {
        tooltip.setExpectedState(false);
        tooltip.handleClosePopper();
      }
    },
    onCancel() {},
    onPicked(files) {
      if (files.length > 1) {
        this.$message.info("最多只能选择一个");
      } else {
        let obj = {};
        files.forEach((file) => {
          let str1 = file.title.substring(0, file.title.indexOf("."));
          obj = {
            name: str1,
            recordId: this.id,
            url: file.link,
          };
          this.getSaveDropboxs(obj);
        });
      }
    },
    dropboxPicker() {
      // this.$message.info('请检查网络连接')
      this.dropbox = true;
    },
    async getSaveDropboxs(params) {
      let res = await getSaveDropbox(params);
      if (res.result) {
        this.$emit("SaveDropboxs");
      }
    },
    drafteditdata() {
      // 如果个案下文章列表是卡片
      const draftedite = this.draftedite.id || this.draftedite.data.id;
      this.$emit("editobjClick", draftedite);
    },
    // 取消附加文章
    Additionalclose(item) {
      // 如果个案下文章列表是卡片
      let params = {
        articleId: item.id || item.data.id,
        caseId: this.id,
      };
      const name = item.name || item.data.name;
      cancelAttachCase(params).then(() => {
        this.$message.success(`文章 ${name}已取消附加到个案！`);
        this.$emit("blockRefresh");
        this.$emit("searchRefresh");
      });
    },
    //跳转到记录详情
    jumpDetail(item) {
      this.itemHref = item;
      if (item.objid === "lightningreport") {
        // 属于报表
        this.$router.push({
          path: `/reportObject/editReport/run/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "dashboardlightning") {
        // 属于仪表板
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "file") {
        //属于文件
        this.$router.push({
          path: "/ccFileCenter/fileDetail",
          query: {
            id: item.recordid,
          },
        });
      } else {
        // 属于通用对象
        this.$router.push({
          path: `/commonObjects/detail/${item.id}/DETAIL`,
        });
      }
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    //相关列表文件下载
    downloadBtn(val) {
      let baseURL = this.$baseConfig.baseURL;
      var link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${this.token}&id=${val.id}`;
      link.click();
    },
    // 客户和联系人下的评价数量
    accountNumber(num) {
      this.serviceEvaluationNumber = num;
      this.$emit("accountNumberToList", num);
    },
    //邀约码
    invitationCodeFun(param) {
      this.rowId = param.data.id;
      this.invitationCode = true;
    },
    //关闭邀约码弹窗
    closeInvitationCodeDialog() {
      this.invitationCode = false;
    },
    //刷新详情页
    closeSendMail() {
      this.$parent.$parent.blockRefresh();
    },
    //二维码
    qrCodeFun(param) {
      this.rowId = param.data.id;
      this.qrCodeName = param.data.campaignidccname;
      this.$nextTick(() => {
        this.$refs.qrcode.qrcodeFlg = true;
        this.$refs.qrcode.init();
      });
    },
    //市场活动
    activeMemberFun(param) {
      this.$router.push({
        path: `/commonObjects/activeMember/${this.id}`,
        query: {
          id: this.id,
          channel: param.data.campaignidccname,
          tagId: param.data.id,
          layoutId: this.obj.layoutId,
          relatedlistId: this.objectInfo.id,
        },
      });
    },
    // 表单营销推广按钮点击
    formPromotionBtnClick() {},
  },
  computed: {
    backgroundComputed() {
      let { language } = this.$store.state.userInfoObj;

      if (language === "zh") {
        // 中文

        return require("@/assets/svgImgFiles/structureChart/structureChartexample.svg");
      } else {
        return require("@/assets/svgImgFiles/structureChart/en_structureChartexample.svg");
      }
    },
  },
  watch: {
    detailLayoutModeData(nval) {
      this.showBtnLength = nval === "mode1" ? 5 : 0;
    },
    isRlation(nval) {
      if (nval !== "Card") {
        this.$nextTick(() => {
          this.$refs.tablePanel &&
            this.$refs.tablePanel.changeTableAttr(this.objectInfo.fieldList);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.totip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  .upload-demo {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .el-upload .el-upload--text {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .tit_s {
    display: flex;
    svg {
      margin-right: 7px;
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0;
      //   float: right;
      height: 20px;
      font-size: 12px;
      color: #080707;
      line-height: 20px;
    }
    .hover_p:hover {
      border-bottom: 1px solid #006dcc;
      cursor: pointer;
      color: #006dcc;
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}
.pdf-btn {
  justify-content: end;
  background: #fff !important;
}

.cont_table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  .cont {
    display: flex;
    flex-wrap: wrap;
    width: 46.7%;
    justify-content: space-between;
    padding: 6px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #dddbda;
    .title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // min-height: 30px;
      word-break: break-all;
      p {
        min-height: 20px;
        margin: 0;
        font-size: 12px;
        color: #006dcc;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .cont_div {
      width: 50%;
      display: flex;
      width: 100%;
      .list_left {
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        width: 100%;
        li {
          font-size: 12px;
          min-height: 16px;
          display: flex;
          align-items: center;
          .dis_a {
            ::v-deep a {
              color: #080707 !important;
              cursor: default;
            }
          }
          .font_li {
            padding-left: 20px;
            font-size: 14px;
            color: #080707;
          }
          .over_p .font_li {
            padding-left: 0;
          }
          span:first-child {
            margin-right: 30px;
          }
          .fieldName {
            margin-right: 10px;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            font-size: 12px;
            color: #3e3e3c;
            line-height: 18px;
          }
          ::v-deep .work_check {
            .el-checkbox__input {
              top: 0;
            }
          }
          .cannotEdit {
            width: 70%;
            text-align: center;
            img {
              width: 100%;
            }
            // 评分字段显示
            .stars {
              list-style: none;
              margin: 0;
              color: #ccc;
              word-break: break-all;
              width: 70%;
              text-align: center;
              img {
                width: 100%;
              }
              // 评分字段显示
              .stars {
                list-style: none;
                margin: 0;
                color: #ccc;
                word-break: break-all;
                width: 70%;
              }

              .stars span {
                font-size: 20px;
                margin-left: 3px;
              }

              .stars span:after {
                content: "☆";
              }

              .stars .show:after,
              .stars .show2:after {
                content: "★";
                color: rgb(247, 186, 42);
              }
            }
          }
        }
      }
    }
  }
}
//列表样式
.text-center {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }

  ::v-deep .el-popover__reference {
    width: 20px !important;
    border-radius: 3px !important;
  }
  ::v-deep .el-table tr:last-child {
    td {
      border-bottom: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
    }
    ::v-deep .hover-row > td {
      background-color: #ffffff;
    }
    ::v-deep .el-popover__reference {
      width: 20px !important;
      border-radius: 3px !important;
    }
    ::v-deep .el-table tr:last-child {
      td {
        border-bottom: 0 !important;
      }
    }
    ::v-deep .el-table__header tr th {
      background: #fafaf9;
    }
    ::v-deep .el-table th.is-leaf {
      color: #666666;
    }
    ::v-deep .tableHeadField span,
    ::v-deep .cannotEdit,
    ::v-deep td a span,
    ::v-deep td a,
    ::v-deep th .cell,
    ::v-deep td .cell span {
      font-size: 12px !important;
    }
  }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell,
  ::v-deep td .cell span {
    font-size: 12px !important;
  }
}
.knowOwnpop {
  ::v-deep .el-popover__reference {
    margin-bottom: 0;
  }
}
.text-centerKnow {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }

  ::v-deep .el-popover__reference {
    margin-bottom: 0;
    border-radius: 3px !important;
  }
  ::v-deep .el-table tr:last-child {
    td {
      border-bottom: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
    }
  }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell {
    font-size: 12px;
  }
}

.filedname {
  margin-left: 9px;
  margin-bottom: 10px;
}
.filednametitle {
  display: inline-block;
  width: 140px;
  font-size: 12px;
  color: #3e3e3c;
}

::v-deep .dropbox {
  width: 38px;
}

/* 关系网样式，超出盒子大小，可进行拖动 */
.orgchart_box {
  position: relative;
  .top_box {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
    .top_box_left_title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      .example_style {
        margin-left: 10px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
    .top_box_right {
      width: 15px;
      height: 15px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .org_chart {
    overflow: auto;
    margin: 10px 20px;
    box-sizing: border-box;
    .org_chart_manage_btn {
      width: 15px;
      height: 15px;
      cursor: pointer;
      padding-left: 5px;
    }
  }

  .example_area {
    position: absolute;
    top: 75px;
    left: 0;
    margin: 10px 20px;
    z-index: 999;
    width: 97%;
    height: 280px;
    background-color: #fff;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 90%;
      height: 90%;
    }
  }
}

.see_more {
  border-top: 1px solid #ccc;
  height: 44px;
  line-height: 44px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    cursor: pointer;
  }
}

.pricebook_box {
  border: 0;
  border-collapse: collapse;
  tr {
    border-top: 1px solid #dddbda;
    th,
    td {
      padding: 11px 14px;
      font-size: 12px;
      text-align: left;
    }
    th {
      border-right: 1px solid #dedcda;
      background: #fafaf9;
      color: #666666;
    }
    .jumpPriceClass {
      color: #006dcc;
      cursor: pointer;
    }
    .price_td {
      text-align: center;
      width: 80px;
      span {
        padding-right: 20px;
        display: inline-block;
      }
    }
  }
}

.attendance-staff {
  ::v-deep .el-table {
    font-size: 12px;
  }
  ::v-deep .el-table thead .el-table__cell {
    padding: 6px 0;
    background: #fafaf9;
    color: #666666;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #dedcda;
  }
  ::v-deep .el-table tbody .el-table__cell {
    padding: 2px 0;
  }

  .remove-btn {
    cursor: pointer;
    color: #006dcc;
  }
}
</style>
<style scoped>
.my-tooltip .dis_b ::v-deep a {
  color: #fff;
  cursor: default;
}
.xiala_s {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}
.list_xiala_s {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s svg {
  width: 15px;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.xiala_s svg {
  cursor: pointer;
  width: 12px;
  margin-top: -2px;
}

.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  line-height: 26px;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}

.upload-demo {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.upload-demo ::v-deep .el-upload {
  width: 100% !important;
  text-align: left !important;
}
.upload-demo ::v-deep .el-upload-dragger {
  width: 100% !important;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload-demo ::v-deep .el-upload-dragger .el-icon-upload {
  margin: 10px 0;
}
.dialog-type {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}
.file-dropbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.file-dropbox-svg {
  width: 18px;
  height: 18px;
}
</style>
