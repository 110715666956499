<template>
  <div class="shuttle-box" style="position: relative">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <h3 style="margin-left: 10px; margin-top: 10px">Knowledge</h3>
      <div v-show="caseCreateArticle">
        <el-popover
          placement="bottom-end"
          popper-class="pop_small"
          trigger="click"
          :visible-arrow="false"
          style="width: 20px"
        >
          <svg class="downXiala" aria-hidden="true" slot="reference">
            <use href="#icon-xiala"></use>
          </svg>
          <ul>
            <li @click="addarticle">
              <span>{{ $t("label.knowledgebase.new.article") }}</span>
            </li>
          </ul>
        </el-popover>
      </div>
    </div>
    <!-- 中间全局搜索框 -->
    <div class="global-search">
      <!-- 右侧搜索框 -->
      <div class="right_search">
        <div class="searchFather">
          <!-- 搜索图标 -->
          <div class="searchIcon">
            <svg class="icon search_icon" aria-hidden="true" v-show="!checked">
              <use href="#icon-search"></use>
            </svg>
          </div>
          <el-tag
            size="medium"
            closable
            style="margin-left: 30px"
            :key="tag"
            type="info"
            v-for="tag in dynamicTags"
            :disable-transitions="false"
            @close="handleClose(tag)"
            >{{ tag }}</el-tag
          >
          <input
            @keyup.13="getResult()"
            @focus="getFocus"
            @blur="loseFocus"
            @input="getlist"
            type="text"
            class="el-input"
            v-model="transferObj.userInput"
            :placeholder="$t('label.knowledgebase.search.knowledge.articles')"
          />
          <div
            style="cursor: pointer"
            class="el-icon-circle-close closeIcon"
            @click="clearInput"
            v-show="clearinput"
          ></div>
        </div>
      </div>
      <!-- 搜索结果页 -->
      <div class="searchResult" v-show="showResult">
        <!-- 搜索结果 -->
        <div class="resultList">
          <el-popover
            placement="right"
            width="400"
            trigger="click"
            v-model="visible"
            :visible-arrow="false"
            :open-delay="500"
          >
            <div>
              <div style="display: flex; justify-content: space-between">
                <h3 style="font-weight: 600">
                  {{ $t("label.knowledgebase.advanced.filtering") }}
                </h3>
                <h3
                  style="font-weight: 600; color: #006dcc"
                  @click="Resetfilter"
                >
                  {{ $t("label.knowledgebase.reset.filter") }}
                </h3>
              </div>
              <div style="margin-top: 20px">
                <div>{{ $t("label.language") }}</div>
                <el-select
                  v-model="langevalue"
                  :placeholder="$t('label.emailtocloudcc.select1')"
                  style="width: 370px"
                >
                  <el-option
                    v-for="item in langeoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top: 20px">
                <div>{{ $t("label.knowledgebase.release.status") }}</div>
                <el-select
                  v-model="statevalue"
                  :placeholder="$t('label.emailtocloudcc.select1')"
                  style="width: 370px"
                >
                  <el-option
                    v-for="item in stateoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:20px；margin-bottom:20px">
                <div>{{ $t("label.knowledgebase.verification.status") }}</div>
                <el-select
                  v-model="textarea"
                  multiple
                  :placeholder="$t('label.emailtocloudcc.select1')"
                  style="width: 370px"
                >
                  <el-option
                    v-for="item in verificationoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div style="text-align: right; margin-top: 20px">
                <el-button size="mini" type="text" @click="visible = false">{{
                  $t("label.emailsync.button.cancel")
                }}</el-button>
                <el-button type="primary" size="mini" @click="applyClick">{{
                  $t("label.apply")
                }}</el-button>
              </div>
            </div>
            <a
              slot="reference"
              style="margin-left: 20px; color: #006dcc; cursor: pointer"
              >{{ $t("label.knowledgebase.advanced.filtering") }}</a
            >
          </el-popover>
          <div
            style="cursor: pointer"
            @click="choseRecord(index, item)"
            class="searchResultItem"
            v-for="(item, index) in searchRecord"
            :key="index"
            v-html="highLight(item.name, transferObj.userInput)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="resultlistdata && resultlistdata.length > 0"
      style="min-height: 200px; font-size: 14px"
    >
      <div style="margin: 15px 0 5px">
        <span>{{ $t("report.label.searchresult") }}</span>
        <span>
          {{ $t("label.knowledgebase.results", { a: resultlistall }) }}
        </span>
      </div>
      <ul>
        <li
          v-for="(item, index) in resultlistdata"
          :key="index"
          style="margin-bottom: 5px"
        >
          <el-popover
            placement="right"
            :open-delay="1000"
            width="500"
            style="height: 200px; overflow: auto"
            trigger="hover"
            :v-model="articlevisible"
            @show="show(item)"
            @hide="hidePopver"
            :ref="refNamePopover + item.id"
          >
            <div>
              <div>
                <div style="display: flex; justify-content: space-between">
                  <h3>{{ item.name }}</h3>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    flex-wrap: wrap;
                  "
                >
                  <div
                    style="width: 25%"
                    v-for="item in labelarr"
                    :key="item.id"
                  >
                    <p>{{ item.fieldLabel }}</p>
                    <p>{{ item.fieldValue }}</p>
                  </div>
                </div>
                <div
                  :style="{
                    height: dialogBodyHeight,
                    overflow: 'auto',
                  }"
                >
                  <info-form
                    ref="infoForm"
                    :objId="objids"
                    v-loading="loading"
                    :element-loading-text="$t('label.report.loading')"
                    :operation="operation"
                    :form-attr="formAttr"
                    :option-list="optionList"
                    :label-position="labelPosition"
                    :label-width="labelWidth"
                    :status-icon="statusIcon"
                    :objectApi="objectApi"
                  ></info-form>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                "
              >
                <div style="margin-left: 10px"></div>
                <div>
                  <!-- 附加文章 -->
                  <div v-if="!item.addition">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="Additional(item.id, item)"
                      ><span>{{
                        $t("label.knowledgebase.additional.articles")
                      }}</span></el-button
                    >
                  </div>
                  <div v-if="item.addition">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="Additionalclose(item.id, item)"
                      ><span>{{
                        $t("label.knowledgebase.cancel.attached.articles")
                      }}</span></el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <a
              slot="reference"
              @click="enterdetial(item.id, item.name)"
              v-html="highLight1(item.name, keyword)"
              style="color: #006dcc; cursor: pointer"
              >{{ item.name }}</a
            >
          </el-popover>
          <div style="display: flex; justify-content: space-between">
            <div>
              <div style="color: #3e3e3c">
                <span>{{ item.article_number }}</span
                ><span> . </span
                ><span
                  v-show="statevalue == $t('label.knowledgebase.published')"
                  >{{ $t("label.knowledgebase.last.release") }}</span
                ><span v-show="statevalue == $t('label.knowledgebase.draft')">{{
                  $t("label.knowledgebase.last.modified")
                }}</span
                ><span v-show="statevalue == $t('label.archived')">{{
                  $t("label.knowledgebase.filing.time")
                }}</span>
                <span>&nbsp;</span>
                <span>{{ item.last_publish_date }}</span>
              </div>
              <div>{{ item.name }}</div>
            </div>
            <div>
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                trigger="click"
                :visible-arrow="false"
                style="width: 20px"
              >
                <svg class="downXiala" aria-hidden="true" slot="reference">
                  <use href="#icon-xiala"></use>
                </svg>
                <ul
                  v-if="
                    item.publish_status == $t('label.knowledgebase.published')
                  "
                >
                  <li
                    @click="dialogAssembly(item.id, 'draftedit')"
                    v-show="item.conditional !== 'true'"
                  >
                    <span>{{ $t("label.knowledgebase.edit.as.draft") }}</span>
                  </li>
                  <li
                    @click="dialogAssembly(item.id, 'file')"
                    v-show="item.is_master_language == 'true'"
                  >
                    <span>{{ $t("label.group.archived") }}</span>
                  </li>
                  <li @click="submittranslation(item.id)">
                    <span>{{
                      $t("label.knowledgebase.submit.for.translation")
                    }}</span>
                  </li>
                  <li
                    @click="Additionalclose(item.id, item)"
                    v-if="item.addition == 'true'"
                  >
                    <span>{{
                      $t("label.knowledgebase.cancel.attached.articles")
                    }}</span>
                  </li>
                  <li @click="Additional(item.id, item)" v-else>
                    <span>{{
                      $t("label.knowledgebase.additional.articles")
                    }}</span>
                  </li>
                </ul>
                <ul v-if="item.publish_status == $t('label.archived')">
                  <li
                    @click="dialogAssembly(item.id, 'articledel')"
                    v-show="item.conditional !== 'true'"
                  >
                    <span>{{ $t("label.knowledgebase.delete.article") }}</span>
                  </li>
                  <li @click="dialogAssembly(item.id, 'recovery')">
                    <span>{{ $t("label.marketsea.button.restore") }}</span>
                  </li>
                  <li
                    @click="Additionalclose(item.id, item)"
                    v-if="item.addition == 'true'"
                  >
                    <span>{{
                      $t("label.knowledgebase.cancel.attached.articles")
                    }}</span>
                  </li>
                  <li @click="Additional(item.id, item)" v-else>
                    <span>{{
                      $t("label.knowledgebase.additional.articles")
                    }}</span>
                  </li>
                </ul>
                <ul
                  v-if="
                    item.publish_status == $t('label.archived') &&
                    item.is_master_language !== 'true'
                  "
                >
                  <li>
                    <span>{{
                      $t("label.knowledgebase.no.action.available")
                    }}</span>
                  </li>
                  <li
                    @click="Additionalclose(item.id, item)"
                    v-if="item.addition == 'true'"
                  >
                    <span>{{
                      $t("label.knowledgebase.cancel.attached.articles")
                    }}</span>
                  </li>
                  <li @click="Additional(item.id, item)" v-else>
                    <span>{{
                      $t("label.knowledgebase.additional.articles")
                    }}</span>
                  </li>
                </ul>
                <ul
                  v-if="item.publish_status == $t('label.knowledgebase.draft')"
                >
                  <li @click="assign(item.id)">
                    <span>{{ $t("label.knowledgebase.assign") }}</span>
                  </li>
                  <li @click="release(item.id)">
                    <span>{{ $t("pagecreator.button.publish") }}</span>
                  </li>
                  <li @click="dialogAssembly(item.id, 'draftdel')">
                    <span>{{ $t("label.knowledgebase.delete.draft.t") }}</span>
                  </li>
                  <li
                    @click="submittranslation(item.id)"
                    v-show="item.is_master_language == 'true'"
                  >
                    <span>{{
                      $t("label.knowledgebase.submit.for.translation")
                    }}</span>
                  </li>
                  <li @click="edit(item)">
                    <span>{{ $t("label.emailtocloudcc.edit") }}</span>
                  </li>
                  <li
                    @click="Additionalclose(item.id, item)"
                    v-if="item.addition == 'true'"
                  >
                    <span>{{
                      $t("label.knowledgebase.cancel.attached.articles")
                    }}</span>
                  </li>
                  <li @click="Additional(item.id, item)" v-else>
                    <span>{{
                      $t("label.knowledgebase.additional.articles")
                    }}</span>
                  </li>
                </ul>
              </el-popover>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="knowResultPosi">
      <svg aria-hidden="true">
        <use href="#icon-noDateAvailable"></use>
      </svg>
      <div>
        {{
          $t("label.knowledgebase.no.results.knowledge.search", {
            a: knowsearchResult,
          })
        }}
      </div>
    </div>
    <!-- 新增/修改记录 -->
    <create-edit-obj
      ref="createEditObj"
      :objectApi="objectApi"
      :prefix="prefix"
      :id="id"
      :object-name="tabName"
      @save="save"
    >
    </create-edit-obj>
    <!-- 搜索对象设置L -->
    <el-dialog
      :title="$t('label.global.search.obj')"
      :visible.sync="isSearch"
      width="550px"
    >
      <shuttle-box
        @changeSearch="changeSearch"
        @deliverName="deliverName"
      ></shuttle-box>
    </el-dialog>
    <!-- 删除文章、删除草稿、归档、还原、作为草稿编辑 -->
    <Dialog
      :dialogSign="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      @dialogEdit='dialogEdit'
      @dialoghandleClose="dialoghandleClose"
    >
    <template v-slot:Dialog>
      <div>
        <div v-if="btnSign=='draftdel'">
           <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
          <div class="dialog-type">{{$t("label.knowledgebase.unable.restore.draft")}}</div>
          <div>{{$t("label.knowledgebase.delete.translation.associated.with.draft")}}</div>
        </div>
        <div v-else-if="btnSign=='articledel'" class="dialog-type">
          <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
          <div>{{$t("label.knowledgebase.permanently.remove.articles")}}</div>
        </div>
        <div v-else-if="btnSign=='file'" class="dialog-type">
          <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
          <div>{{$t("label.knowledgebase.published.translations")}}</div>
        </div>
         <div v-else-if="btnSign=='recovery'" class="dialog-type">
           <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
          <div>{{$t("label.knowledgebase.restore.creates.draft")}}</div>
        </div>
        <div v-else-if="btnSign=='draftedit'" class="dialog-type">
          <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
          <div>{{$t("label.knowledgebase.published.version.online")}}</div>
        </div>
      </div>
    </template>
    </Dialog>
    <!-- 知识库发布 -->
    <Release
      :id="id"
      :objId="objId"
      :transfer-owner-dialog="releasedialogVisible"
      :markNewVersion="markNewVersion"
      :affectTranslation="affectTranslation"
      @releasehandleClose="releasehandleClose"
      @getSingleGlobalResult="getSingleGlobalResult"
    ></Release>
    <!-- 指派 -->
    <Distribution
      :id="id"
      :objId="objId"
      :transfer-owner-dialog="assigndialogVisible"
      @assignhandleClose="assignhandleClose"
    >
    </Distribution>
    <!-- 提交进行翻译 -->
    <Submittranslation
      :id="id"
      :objId="objId"
      :transfer-owner-dialog="submittranslationdialogVisible"
      @submittranslationhandleClose="submittranslationhandleClose"
      :tableData="tableData"
    >
    </Submittranslation>
  </div>
</template>

<script>
import {
  ButtonWrapper,
  ViewButtons,
  ChooseView,
  SearchWrapper,
  knowledgeindextablePanel,
  Statistics,
  FilterPanel,
  LabelView,
  Chart,
  BaseForm,
  Distribution,
  Submittranslation,
  Release,
  InfoForm,
  Dialog,
} from "@/components/index";
import { getObjectSetting, saveObjectSetting } from "./api.js";
import { GetBrief } from "./api.js";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import * as CommonObjApi from "./api.js";
import INPUTTYPE from "@/config/enumCode/inputType";
import * as knowledgearticlesApi from "./api.js";
import DialogMixin from "@/mixin/Dialog.js";

export default {
  mixins: [DialogMixin],
  components: {
    shuttleBox: () => import("@/views/GlobalSearch/object-transfer.vue"),
    InfoForm,
    createEditObj,
    ButtonWrapper,
    ViewButtons,
    ChooseView,
    SearchWrapper,
    knowledgeindextablePanel,
    Statistics,
    FilterPanel,
    LabelView,
    Chart,
    BaseForm,
    Distribution,
    Submittranslation,
    Release,
    Dialog,
  },
  props: {
    caseArticleId: {
      type: String,
      default: "",
    },
  },
  computed: {
    selectobj() {
      this.getGloObj();
    },
  },
  data() {
    return {
      // 搜索下拉禁用
      dropdownBoo: true,
      objids: "cloudcc_project",
      loading: false,
      objId: "",
      dialogBodyHeight: "400px",
      //保存所有的全局对象
      globalobj: [],
      //选中的全局对象
      selectobj: [],
      //搜索图标 关闭按钮的显示
      checked: false,
      clearinput: false,
      //结果页的显示
      showResult: false,
      searchRecord: [],
      isSearch: false,
      seaButton: false,
      //保存记录id
      recordId: "",
      //向子组件传递对象
      transferObj: {
        searchObjVal: "all",
        //保存已选对象对应的id
        searchObjId: "all",
        //保存用户输入的搜索关键词
        userInput: "",
      },
      loadDate: false,
      stateoptions: [
        {
          value: this.$i18n.t("label.knowledgebase.draft"),
          label: this.$i18n.t("label.knowledgebase.draft"),
        },
        {
          value: this.$i18n.t("label.archived"),
          label: this.$i18n.t("label.archived"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.published"),
          label: this.$i18n.t("label.knowledgebase.published"),
        },
      ],
      langeoptions: [],
      statevalue: this.$i18n.t("label.knowledgebase.published"),
      langevalue: "",
      textarea: null,
      visible: false,
      articlevisible: false,
      resultlistdata: [],
      resultlistall: "",
      objectApi: "CloudccKArticle",
      prefix: "k02",
      formAttr: null,
      operation: "DETAIL",
      labelPosition: "top",
      labelWidth: 180,
      statusIcon: true,
      attrIndex: -1, // 表单字段所在位置
      personsName: "",
      optionList: {},
      id: "",
      tabName: this.$i18n.t("label.knowledgebase.knowledge.article"),
      filedialogVisible: false,
      thumbsUpvalue: 0,
      thumbsDownvalue: 0,
      refNamePopover: "popover-", // popover ref名称前缀
      releasedialogVisible: false,
      assigndialogVisible: false,
      submittranslationdialogVisible: false,
      type: "changerecordtype",
      labelarr: [],
      keyword: "",
      // is_master_version: "", //是否为主版本
      conditional: "false", //主版本下是否有草稿
      tableData: [],
      dynamicTags: [],
      verificationoptions: [
        {
          value: "Not Validated",
          label: "Not Validated",
        },
        {
          value: "Validated",
          label: "Validated",
        },
      ],
      affectTranslation: null,
      markNewVersion: null,
      knowsearchResult: "",
      caseCreateArticle: false,
    };
  },
  mounted() {
    this.queryLanguage();
    this.$bus.$on("isClear", this.isClearCallback);
    this.getGloObj();
  },
  beforeDestroy(){
    this.$bus.$off("isClear", this.isClearCallback);
  },
  computed: {
    // 搜索框的输入值
    inputData: function () {
      const { userInput } = this.transferObj;
      return { userInput };
    },
    searchRecord() {
      if (!this.transferObj.userInput) return this.searchRecord;
      let newArr = this.searchRecord.filter((res) => {
        let result = new RegExp(this.transferObj.userInput).test(res.name);
        return result;
      });
      if (newArr.length == 0) {
        this.showResult = false;
      }
      return newArr;
    },
  },
  watch: {
    // clearinput(val, newval) {
    //   if (newval && !this.$route.path.includes("globalsearch")) {
    //     this.transferObj.userInput = "";
    //   }
    // },
    // 搜索框的输入值
    inputData: function (newVal, oldVal) {
      if (newVal.userInput !== "") {
        this.dropdownBoo = false;
      }
    },
  },
  methods: {
    isClearCallback(val){
      if (val) {
        this.transferObj.userInput = "";
      }
    },
    judgeAddArticleByCase() {
      knowledgearticlesApi.judgeAddArticleByCase().then((res) => {
        this.caseCreateArticle = res.data.caseCreateArticle;
      });
    },
    // 标签隐藏
    handleClose(tag) {
      this.queryLanguage();
      this.textarea = null;
      this.statevalue = this.$i18n.t("label.knowledgebase.published");
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 查询语言接口
    async queryLanguage() {
      this.langeoptions = [];
      let params = {
        id: "",
      };
      knowledgearticlesApi.queryLanguage(params).then((res) => {
        for (let i in res.data.languageList) {
          this.langeoptions.push({
            value: res.data.languageList[i].language,
            label: res.data.languageList[i].language,
          });
          if (res.data.languageList[i].is_default_language == "true") {
            this.langevalue = res.data.languageList[i].language;
          }
        }
      });
    },
    articleclose() {
      this.articlevisible = false;
    },
    // 搜索结果进详情页
    enterdetial(id, name) {
      if (this.$store.state.navigationStyle) {
        this.$CCDK.CCPage.openDetailPage(
          {
            oprateType: "DETAIL",
            objectName: name,
            objId: this.$store.state.consoleData.objId,
            objectApi: this.$store.state.consoleData.objectApi,
            prefix: this.$store.state.consoleData.prefix,
          },
          id,
          { openPlace: "menu2", openMode: "_blank", pageId: id }
        );
      } else {
        this.$router.push(`/commonObjects/detail/${id}/DETAIL`);
      }
    },
    //获取标签
    getBrief(id) {
      GetBrief({ id }).then((res) => {
        this.labelarr = res.data.heigthLight;
      });
    },
    //查询结果悬浮
    show(item) {
      this.articlevisible = true;
      this.loading = true;
      this.getBrief(item.id);
      this.getFormAttr(item.id);
    },
    hidePopver() {},
    thumbsUp(type) {
      if (type == "up") {
        this.thumbsUpvalue = this.thumbsUpvalue + 1;
      } else {
        this.thumbsDownvalue = this.thumbsDownvalue + 1;
      }
    },
    copy() {
      // this.id = this.editdate;
      this.dialogSign = false;
      this.$nextTick(() => {
        this.$refs.createEditObj.objId = "cloudcc_k_article";
        this.$refs.createEditObj.copy(this.id);
      });
    },
    edit(data, type) {
      this.id = data.id;
      this.$nextTick(() => {
        this.$refs.createEditObj.edit(data, type);
      });
    },
    //更改记录类型
    changerecordtype(data) {
      this.$nextTick(() => {
        this.$refs.createEditObj.edit(data, this.type);
      });
    },
    getFormAttr(id) {
      this.formAttr = [];
      CommonObjApi.getDetail({
        id,
        objectApi: this.objectApi,
        operation: "DETAIL",
        recordType: "",
      }).then((res) => {
        // this.is_master_version = res.data.is_master_version;
        this.conditional = res.data.conditional;
        res.data.dataList.forEach((group) => {
          this.loading = false;
          // 每部分下标都从0开始
          if (group.showWhenEdit) {
            this.attrIndex = -1;
          }
          // 获取详情页用户对象跳转的用户名
          let colNum = group.colNum;
          // 部分名称展示与否通过showWhenEdit控制
          if (group.showWhenEdit) {
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }
          // 非内嵌页面
          if (group.selectionType === "Field") {
            group.data &&
              group.data.length > 0 &&
              group.data.forEach((line) => {
                if (
                  !(Array.isArray(line.left) && line.left.length === 0) &&
                  line.left.fieldType !== "blank"
                ) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.left.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group.selectionId,
                      colNum,
                      line.left,
                      undefined,
                      "left"
                    );
                  }
                } else {
                  this.attrIndex += 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                    selectionId: group.selectionId,
                  });
                }
                if (Number(colNum) !== 1) {
                  if (
                    !(Array.isArray(line.right) && line.right.length === 0) &&
                    line.right.fieldType !== "blank"
                  ) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.right.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group.selectionId,
                        colNum,
                        line.right,
                        undefined,
                        "right"
                      );
                    }
                  } else {
                    this.attrIndex += 1;
                    this.formAttr.push({
                      width: "50%",
                      colNum: 2,
                      attrIndex: this.attrIndex,
                      type: "noData",
                      hidden: false,
                      groupShow: true,
                      selectionId: group.selectionId,
                    });
                  }
                }
              });
          } else {
            // 内嵌页面
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "customPage",
              pageUrl: group.pageUrl,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }
        });
        // 强制刷新表单
        //  this.$refs.infoForm && this.$refs.infoForm.filterFormData();
      });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr, idx) => {
          if (attr.apiname === item.expression.split(",")[0]) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            if (attr.value !== undefined) {
              // 新建时必须修改
              // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
              this.getLookupValue(item, attr.value);
            }
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      fieldJson.push({
        fieldrelateid: lookupRule.id,
        id: id,
        expression: lookupRule.expression,
        iscover: lookupRule.iscover,
      });
      let param = {
        objId: this.prefix,
        fieldJson: JSON.stringify(fieldJson),
      };
      CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
        carryValue = res.data && res.data.length > 0 ? res.data[0].value : null;
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === lookupRule.id) {
            if (formData !== undefined) {
              // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
              if (
                attr.value === "" ||
                attr.value === null ||
                (isArray(attr.value) && attr.value.length === 0) ||
                lookupRule.iscover === "true"
              ) {
                // 查找、查找多选字段特殊处理
                if (
                  (attr.fieldType === "Y" || attr.fieldType === "MR") &&
                  res.data[0].value &&
                  res.data[0].lkid &&
                  res.data[0].value !== "" &&
                  res.data[0].lkid !== ""
                ) {
                  this.optionList[attr.prop] = [
                    {
                      value: res.data[0].lkid,
                      label: res.data[0].value,
                    },
                  ];
                  if (res.data[0].lkid !== "") {
                    attr.value =
                      attr.fieldType === "Y"
                        ? res.data[0].lkid
                        : [res.data[0].lkid];
                  }
                } else {
                  attr.value = carryValue;
                }
              }
            } else {
              // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
              if (
                formData[attr.prop] === "" ||
                formData[attr.prop] === null ||
                (isArray(formData[attr.prop]) &&
                  formData[attr.prop].length === 0) ||
                lookupRule.iscover === "true"
              ) {
                // 查找、查找多选字段特殊处理
                if (
                  (attr.fieldType === "Y" || attr.fieldType === "MR") &&
                  res.data[0].value &&
                  res.data[0].lkid &&
                  res.data[0].value !== "" &&
                  res.data[0].lkid !== ""
                ) {
                  this.optionList[attr.prop] = [
                    {
                      value: res.data[0].lkid,
                      label: res.data[0].value,
                    },
                  ];
                  if (res.data[0].lkid !== "") {
                    formData[attr.prop] =
                      attr.fieldType === "Y"
                        ? res.data[0].lkid
                        : [res.data[0].lkid];
                  }
                } else {
                  formData[attr.prop] = carryValue;
                }
              }
            }
          }
        });
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }

      this.formAttr.forEach((attr, idx) => {
        if (attr.fieldId === fieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (attr.apiname === lookupValue.expression.split(",")[0]) {
              this.getLookupValue(lookupValue, optionValue, formData);
            }
          });
        }
      });
    },
    // 表单添加属性
    addFormAttr(selectionId, colNum, attr, isChildren, position) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      this.$set(attr, "selectionId", selectionId);
      if (attr.required && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
              attr.fieldLabel || attr.label
            }`,
            trigger: "blur",
          },
        ];
      }

      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.operation === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value =
            this.operation === "EDIT" ? Number(attr.value) : Number(attr.value);
        }
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });

          this.optionList[attr.prop] = options;
          // 记录类型选项特殊处理
          if (attr.prop === "recordtype") {
            this.recordType = attr.id;
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          // else {
          //   this.recordType = attr.value;
          //   // this.optionList[attr.prop] = this.recordOptions;
          // }

          if (attr.type === "multi-select") {
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.value === ""
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            } else {
              attr.value = "";
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.colNum = colNum;
        attr.selectionId = selectionId;
        attr.groupShow = true;
        if (
          this.operation === "DETAIL" ||
          (this.operation === "EDIT" && attr.apiname !== "cloudcctag")
        ) {
          this.formAttr.push(attr);
        }
      } else if (this.operation === "DETAIL") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.selectionId = selectionId;
        attr.groupShow = true;
        this.formAttr.push(attr);
      } else {
        // 地址和地理定位特殊处理
        attr.children.forEach((item) => {
          this.addFormAttr(selectionId, colNum, item, true);
          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (item["name"].slice(item["name"].length - 2) === "00") {
            this.getCountrySelectValue(item["name"]);
          }
        });

        this.attrIndex -= attr.children.length - 1;
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },
    save() {},
    //排序
    sort(command) {
      // this.getFormAttr();
    },
    //新建文章
    addarticle() {
      this.$refs.createEditObj.add();
    },
    //选中搜索记录
    choseRecord(index, item) {
      // 是控制台导航样式需要打开二级菜单
      if (this.$store.state.navigationStyle) {
        this.$CCDK.CCPage.openDetailPage(
          {
            oprateType: "DETAIL",
            objectName: item.name,
            objId: this.$store.state.consoleData.objId,
            objectApi: this.$store.state.consoleData.objectApi,
            prefix: this.$store.state.consoleData.prefix,
          },
          item.id,
          { openPlace: "menu2", openMode: "_blank", pageId: item.id}
        );
      } else {
        this.$router.push(`/commonObjects/detail/${item.id}/DETAIL`);
      }
      this.showResult = false;
      this.clearinput = false;
      this.seaButton = false;
    },
    //为用户输入的内容设置高亮
    highLight(val, keyword) {
      let Reg = new RegExp(keyword, "g");
      if (val) {
        const res = val.replace(
          Reg,
          `<span style="color: #3aa1ff;">${keyword}</span>`
        );
        return res;
      }
    },
    //收起弹出框
    changeSearch() {
      this.isSearch = false;
    },
    deliverName(name) {
      this.selectobj = name;
      this.transferObj.searchObjVal = "all";
      localStorage.setItem("searchObjVal", this.transferObj.searchObjVal);
      this.transferObj.searchObjId = "all";
      localStorage.setItem("searchObjId", this.transferObj.searchObjId);
      // 将用户的搜索对象设置参数传递接口存入数据库
      this.saveObjSetting(name);
    },
    //创建存储用户搜索对象的接口调用方法
    async saveObjSetting(name) {
      //用户设置搜索对象的对应id
      //1.将对象id转换为字符串
      let array = [];
      for (let key in name) {
        //判断当前遍历到的元素 有没有objid属性
        //如果没有  将id属性存入array数组
        if (!name[key].objid) {
          array.push(name[key].id);
        } else {
          array.push(name[key].objid);
        }
      }
      array = array.toString();
      let userObjId = {
        objIds: array,
      };
      let result = await saveObjectSetting(userObjId);
      let newResult = await getObjectSetting();
      this.selectobj = newResult.data.selectedObjList;
    },
    getlist() {
      this.searchRecord = [];
      let obj = {
        conditions: "",
        objId: "cloudcc_k_article",
        page: "1",
        pageSize: "10",
        searchKeyWord: this.transferObj.userInput,
        dir: "",
        sort: "",
        language: this.langevalue,
        issueStatus: this.statevalue,
        verifyStatus:
          this.textarea && this.textarea.length > 0
            ? this.textarea.toString()
            : "",
        caseArticleId: this.caseArticleId,
      };
      this.keyword = obj.searchKeyWord;
      knowledgearticlesApi.getGlobalSearchSingleResult(obj).then((res) => {
        this.searchRecord = res.data.dataList.splice(0, 5);
      });
    },
    getFocus() {
      this.getlist();
      if (this.searchRecord.length == 0) {
        this.showResult = false;
      } else {
        this.showResult = true;
      }
      this.showResult = true;
      this.checked = true;
      this.seaButton = true;
      this.clearinput = true;
    },
    loseFocus() {
      this.checked = false;
      setTimeout(() => {
        this.showResult = false;
      }, 400);
    },
    clearInput() {
      this.transferObj.userInput = "";
      this.clearinput = false;
      this.resultlistdata = [];
    },
    //获取全局对象
    async getGloObj() {
      let result = await getObjectSetting();
      //如果首次登录
      for (var key in result.data.selectedObjList) {
        if (result.data.selectedObjList[key].id == "") {
          result.data.selectedObjList.splice(key, 1);
        }
      }
      if (result.data.selectedObjList.length == 0) {
        this.selectobj = result.data.noSelectedObjList.filter((item, index) => {
          let rules =
            item.id == "account" ||
            item.id == "contact" ||
            item.id == "opportunity" ||
            item.id == "lead";
          return rules;
        });
      } else {
        this.selectobj = result.data.selectedObjList;
      }
    },
    getKnowResult() {
      if (this.transferObj.userInput !== "") {
        this.getResult();
      }
    },
    getResult() {
      //输入框内有无值判断
      if (this.transferObj.userInput == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.globalsearch.tip"),
        });
        return false;
      }
      this.checked = false;
      this.seaButton = false;
      this.clearinput = false;
      this.showResult = false;
      this.getSingleGlobalResult();
    },
    //获取单个对象搜索结果
    async getSingleGlobalResult(val = "") {
      let obj = {
        conditions: "",
        objId: "cloudcc_k_article",
        page: "1",
        pageSize: "10",
        searchKeyWord: this.transferObj.userInput || val,
        dir: "",
        sort: "",
        language: this.langevalue,
        issueStatus: this.statevalue,
        verifyStatus:
          this.textarea && this.textarea.length > 0
            ? this.textarea.toString()
            : "",
        caseArticleId: this.caseArticleId,
      };
      this.keyword = obj.searchKeyWord;
      knowledgearticlesApi.getGlobalSearchSingleResult(obj).then((res) => {
        this.resultlistdata = res.data.dataList;
        if (res.data.dataList) {
          this.resultlistall = res.data.dataList.length;
          if (res.data.dataList.length <= 0) {
            this.knowsearchResult = this.transferObj.userInput;
          } else {
            this.knowsearchResult = "";
          }
        }
      });
    },
    highLight1(val, keyword) {
      let Reg = new RegExp(keyword, "g");
      if (val) {
        const res = val.replace(
          Reg,
          `<span style="background:yellow;">${keyword}</span>`
        );
        return res;
      }
    },
    openBox() {
      this.isSearch = true;
    },
    Resetfilter() {
      this.queryLanguage();
      this.textarea = null;
      this.statevalue = this.$i18n.t("label.knowledgebase.published");
    },
    // 知识库发布弹窗
    release(id) {
      this.id = id;
      let params = {
        id: this.id,
      };
      knowledgearticlesApi.ismasterVersion(params).then((res) => {
        this.releasedialogVisible = true;
        this.affectTranslation = res.data.affectTranslation;
        this.markNewVersion = res.data.markNewVersion;
      });
      // this.releasedialogVisible = true;
    },
    //知识库发布关闭弹窗
    releasehandleClose() {
      this.releasedialogVisible = false;
      this.$emit("refreshall");
    },
    assign(id) {
      this.id = id;
      this.assigndialogVisible = true;
    },
    assignhandleClose() {
      this.assigndialogVisible = false;
    },
    submittranslation(id) {
      this.id = id;
      let params = {
        id: this.id,
      };
      knowledgearticlesApi.queryLanguage(params).then((res) => {
        for (let i in res.data.languageList) {
          res.data.languageList[i].data = [
            {
              id: res.data.languageList[i].default_assignee,
              name: res.data.languageList[i].assignee_name,
            },
          ];
          res.data.languageList[i].id = Number(i);
        }
        res.data.languageList.map((o) => {
          return Object.assign(o, { value: o.default_assignee });
        });
        res.data.languageList.map((item) => {
          return Object.assign(item, { ownerType: item.assignee_ownertype });
        });
        this.tableData = res.data.languageList;
      });
      this.submittranslationdialogVisible = true;
    },
    submittranslationhandleClose() {
      this.submittranslationdialogVisible = false;
    },
    // 附加文章
    Additional(id, item) {
      let params = {
        articleId: id,
        caseId: this.caseArticleId,
      };
      knowledgearticlesApi.addCaseArticle(params).then((res) => {
        const name = item.name;
        if (this.caseArticleId.substring(0, 3) === "s21") {
          // `文章${name}已附加到工作订单！`
          this.$message.success(
            `${this.$i18n.t("c2429",{ name })}`
          );
        } else {
          this.$message.success(
            `${this.$i18n.t("label.knowledgebase.article.attaching.case", {
              name,
            })}`
          );
        } 

        if (this.transferObj.userInput == "") {
          this.getSingleGlobalResult(item.name);
        } else {
          this.getSingleGlobalResult();
        }
        this.$emit("blockRefresh");
      });
    },
    // 取消附加文章
    Additionalclose(id, item) {
      let params = {
        articleId: id,
        caseId: this.caseArticleId,
      };
      knowledgearticlesApi.cancelAttachCase(params).then((res) => {
        const name = item.name;
        if (this.caseArticleId.substring(0, 3) === "s21") {
          // `文章${name}已取消附加到工作订单！`
          this.$message.success(
            `${this.$i18n.t("c2430",{ name })}`
          );
        } else {
          this.$message.success(
            `${this.$i18n.t(
              "label.knowledgebase.article.cancelled.attaching.case",
              { name }
            )}`
          );
        }
        if (this.transferObj.userInput == "") {
          this.getSingleGlobalResult(item.name);
        } else {
          this.getSingleGlobalResult();
        }
        this.$emit("blockRefresh");
      });
    },
    // 高级筛选点击应用
    applyClick() {
      if (this.textarea && this.textarea.length > 0) {
        this.dynamicTags = [
          this.$i18n.t("label.knowledgebase.filters", { a: "3" }),
        ];
      } else {
        this.dynamicTags = [
          this.$i18n.t("label.knowledgebase.filters", { a: "2" }),
        ];
      }
      // this.queryLanguage();
      // this.textarea = null;
      // this.statevalue = "已发布";
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.downXiala {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  float: right;
}
.knowResultPosi {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  min-height: 215px;
}
.divStyle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
.resultTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
::v-deep .objSetting {
  position: absolute;
  left: 0px;
  top: 32px;
  // width: 220px;
  z-index: 10;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
}
.searchResultItem {
  padding: 5px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.iconPosition {
  position: absolute !important;
  right: 8px;
  top: -25px;
  width: 0;
  height: 0;
  border-width: 5px 5px;
  border-style: solid;
  border-color: #333 transparent transparent; /*灰 透明 透明 */
  margin: 40px auto;
  margin-bottom: 0px;
  position: relative;
}
.selectedColor {
  color: #7ac2fd;
}
.leftButton {
  font-size: 12px;
  min-width: 130px;
  max-width: 200px;
  height: 32.5px;
  border: 1px solid #d9d9d9;
  outline: none;
  border-radius: 5px 0px 0px 5px;
  background-color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
  padding-left: 10px;
  text-align: center;
  line-height: 32.5px;
}
.leftButton:hover {
  cursor: pointer;
}
.globalObjTitle {
  display: flex;
  justify-content: space-between;
}
.searchFather {
  position: relative;
  width: 90%;
  border: 1px solid #d9d9d9;
}
::v-deep .singleObj {
  padding: 7px 6px 7px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.global-search {
  // display: flex;
  // height: 32.5px;
  width: 100%;
  // background:pink;
  // overflow: hidden;
}
.searchIcon {
  position: absolute;
  left: 7px;
  top: 5px;
}
.right_search {
  display: flex;
}
.el-input {
  width: 80%;
  // border: 1px solid #d9d9d9;
  border: none;
  height: 32.5px;
  padding-left: 30px;
  // padding-right: 80px;
  font-size: 12px;
  background: transparent;
  border-radius: 0px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search_icon {
  width: 15px;
  height: 15px;
}
.closeIcon {
  position: absolute;
  right: 30px;
  top: 10px;
  // z-index: 999;
}
.searchButton {
  width: 60px;
  height: 32.5px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #1989fa;
  border: none;
  text-align: center;
  line-height: 32.5px;
  outline: none;
  color: white;
  border-radius: 0px 3px 3px 0px;
}
.searchResult {
  width: 90%;
  min-height: 40px;
  position: absolute;
  //   right: 0px;
  top: 90px;
  background-color: white;
  box-shadow: 0px 2px 10px 2px #efefef;
  margin-top: 0px;
  margin-left: 0px;
  z-index: 101;
  // padding: 20px 20px 0px 20px;
  overflow: hidden;
  font-size: 14px;
}
.resultList {
  // padding: 15px;
  padding: 15px 0px;
  overflow: hidden;
}
.resultList li {
  margin: 0px 15px;
  text-decoration: none;
  padding: 5px 0px;
  white-space: nowrap;
  font-size: 14px;
}
::v-deep .el-dialog {
  // min-height: 300px;
  // max-height: 471px;
  overflow: auto;
}

::v-deep .el-popover {
  height: 400px;
  overflow: auto;
}
.dialog-type{
  font-size: 16px;
  font-weight: bold;
  word-break: break-word
}
</style>
