<template>
  <!--  倒计时组件-->
  <div class="count-down-item">
    <div class="count-down-item-left">
      <div class="count-down-item-left-title">
        {{ item.name }}
      </div>
      <!--    1 天 2 小时 2 分逾期-->
      <div v-if="isTimeout" class="count-down-item-left-out">
        <span v-show="day > 0">
          {{ day }} {{ $t("label.login.access.day") }}</span
        >
        <span v-show="hour > 0"> {{ hour }} {{ $t("label.ems.hour") }}</span>
        <span v-show="minute > 0"> {{ minute }} {{ $t("label.minutes") }}</span>
        <!-- {{$t('label.service.seconds')}} -->
        <span> {{ second }} {{$t('label.service.seconds') + ' '}}</span>
        <span>{{ $t("label.weixin.postunsolve") }}</span>
      </div>
      <!--    剩余时间 4 分 36 秒-->
      <div v-else class="count-down-item-left-rest">
        <!-- 剩余时间: {{$t('label.service.rest.time')}} -->
        <span>{{$t('label.service.rest.time')}}</span>
        <span v-show="day > 0">
          {{ day }} {{ $t("label.login.access.day") }}</span
        >
        <span v-show="hour > 0"> {{ hour }} {{ $t("label.ems.hour") }}</span>
        <span v-show="minute > 0"> {{ minute }} {{ $t("label.minutes") }}</span>
        <!-- {{$t('label.service.seconds')}} -->
        <span> {{ second }} {{$t('label.service.seconds')  + ' '}}</span>
      </div>
      <div class="count-down-item-left-status" @click="markCompleted(item)">
         {{$t('label.service.marking.completed')}}
<!--        标记已完成-->
      </div>
    </div>
    <div class="count-down-item-right">
      <svg class="count-down-item-right-svg"
           v-if="isTimeout"
           aria-hidden="true">
        <use href="#icon-forbid1"></use>
      </svg>
      <div v-else class="rotate-svg">
        <el-progress
          :width="30"
          :stroke-width="4"
          type="circle"
          color="#52C67F"
          :show-text="false"
          :percentage="percent"
        >
        </el-progress>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    endDate: {
      type: String,
      default: "2021-03-23 15:00:00",
    },
    item: {
      type: Object,
    },
  },
  name: "CountDown",
  data() {
    return {
      day: "",
      hour: "",
      minute: "",
      second: "",
      isTimeout: false,
      percent: 100,
    };
  },
  created() {
    this.countTime();
  },
  methods: {
    countTime() {
      //获取当前时间
      let date = new Date();
      let now = date.getTime();
      //设置截止时间
      let endDate = new Date(this.endDate);
      let end = endDate.getTime();
      //时间差
      let leftTime = end - now;
      //定义变量 day,hour,minute,second保存倒计时的时间
      if (leftTime >= 0) {
        this.isTimeout = false;
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        this.hour = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.minute = Math.floor((leftTime / 1000 / 60) % 60);
        this.second = Math.floor((leftTime / 1000) % 60);
        // 剩余1分钟时计算，圆环开始变化
        let tt = 60 * 1000;
        if (leftTime <= tt) {
          this.percent = (leftTime / tt) * 100;
          this.percent = this.percent.toFixed(2) * 1;
        }
      } else {
        this.isTimeout = true;
        let leftTime2 = leftTime * -1;
        this.day = Math.floor(leftTime2 / 1000 / 60 / 60 / 24);
        this.hour = Math.floor((leftTime2 / 1000 / 60 / 60) % 24);
        this.minute = Math.floor((leftTime2 / 1000 / 60) % 60);
        this.second = Math.floor((leftTime2 / 1000) % 60);
      }
      //递归每秒调用countTime方法，显示动态时间效果
      setTimeout(this.countTime, 1000);
    },
    markCompleted(item) {
      this.$emit("freshMajorEvent", item);
    }
  },
};
</script>

<style lang="scss" scoped>
.count-down-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &-left {
    &-title {
      font-size: 16px;
      font-weight: bold;
      color: #080707;
      line-height: 21px;
      margin-bottom: 8px;
      //cursor: pointer;
      //&:hover{
      //  text-decoration: underline;
      //}
    }
    &-out {
      font-size: 14px;
      color: #c23934;
      line-height: 19px;
      margin-bottom: 8px;
    }
    &-rest {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      margin-bottom: 8px;
    }
    &-status {
      font-size: 14px;
      color: #006dcc;
      line-height: 19px;
      cursor: pointer;
    }
  }
  &-right {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    &-svg {
      width: 30px;
      height: 30px;
    }
    .rotate-svg {
      width: 30px;
      height: 30px;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }
}
</style>
