<template>
  <div class="important-event" v-loading="loadingStatus">
    <!--          头部-->
    <div class="important-event-h">
      <div class="important-event-h-l">
        <svg
          class="important-event-h-l-svg important-event-h-l-img"
          aria-hidden="true"
        >
          <use href="#icon-iEvent_obj"></use>
        </svg>
        <div class="important-event-h-l-text">
          <!--          重大事件-->
          {{ $t("label.service.major.event") }}
        </div>
      </div>
    </div>
    <!--            内容-->
    <div class="important-event-c">
      <div class="important-event-c-normal">
        <div v-show="errorCodeInfo" class="important-event-c-normal-tips">
          {{ errorCodeInfoStr }}
        </div>
        <div
          v-show="isHasEvent && !errorCodeInfo && overEventList.length === 0"
          class="important-event-c-normal-tips"
        >
          <!--          没有要显示的重大事件-->
          {{ $t("label.service.without.major.event") }}
        </div>
        <div
          v-show="isHasEvent && overEventList.length > 0"
          class="important-event-c-normal-tips"
        >
          <!--          您已完成所有重大事件-->
          {{ $t("label.service.all.done.major.event") }}
        </div>
        <div
          class="important-event-c-normal-item"
          v-for="(item, index) in eventList"
          :key="index"
        >
          <count-down
            @freshMajorEvent="freshEvent"
            :item="item"
            :endDate="item.endTime"
          ></count-down>
        </div>
      </div>
      <!--      <transition name="el-zoom-in-top">-->
      <div class="important-event-c-more" v-show="showMore">
        <!--        已完成-->
        <div class="important-event-c-more-desc">
          {{ $t("label.finished") }}
        </div>
        <div
          class="important-event-c-more-item"
          v-for="(item, index) in overEventList"
          :key="index"
        >
          <div class="important-event-c-more-item-left">
            <div class="important-event-c-more-item-left-title">
              {{ item.majorEventTypeName }}
            </div>
            <div class="important-event-c-more-item-left-status">
              <!-- 已违反 $t('label.service.violated') -->
              {{
                item.iscompleted === "1"
                  ? item.isviolated === "1"
                    ? $t("label.service.violated")
                    : $t("label.finished")
                  : ""
              }}
            </div>
          </div>
          <div class="important-event-c-more-item-right">
            <svg
              class="important-event-c-more-item-right-svg"
              v-if="item.isviolated === '1'"
              aria-hidden="true"
            >
              <use href="#icon-forbited"></use>
            </svg>
            <svg
              class="important-event-c-more-item-right-svg"
              v-else
              aria-hidden="true"
            >
              <use href="#icon-completed"></use>
            </svg>
          </div>
        </div>
      </div>
      <!--      </transition>-->
    </div>

    <!--          底部-->
    <div
      v-show="overEventList.length > 0"
      class="important-event-f"
      @click="openMoreItem"
    >
      {{
        showMore
          ? $t("label.service.hide.more.info")
          : $t("label.service.show.more.info")
      }}
    </div>
  </div>
</template>
<script>
import countDown from "./CountDown";
import * as serviceApi from "../api.js";

export default {
  name: "ImportantEvent",
  components: {
    countDown,
  },
  props: {
    workOrderId: {
      type: String,
    },
  },
  computed: {
    // 是否拥有重大事件
    isHasEvent() {
      if (this.eventList.length === 0) {
        // 没有重大事件
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  data() {
    return {
      isOpenService: sessionStorage.getItem("isOpenService"),
      // 去关联权利过程报错信息
      errorCodeInfo: false,
      errorCodeInfoStr: "",
      // isHasEvent: false, // 是否拥有重大事件
      isOver: false, // 是否全部完成
      showMore: false, // 展示更多
      loadingStatus: true,
      eventList: [],
      overEventList: [],
      objId: "",
      entitlementId: "",
      entitlementName: "", // 权力名称
    };
  },
  methods: {
    renderPages() {
      // 判断是否为工作订单或者个案
      if (
        this.workOrderId.substring(0, 3) !== "s21" &&
        this.workOrderId.substring(0, 3) !== "008"
      ) {
        return false;
      }
      let op = {
        id: this.workOrderId,
        operation: "DETAIL",
      };
      serviceApi
        .getDetail(op)
        .then((res) => {
          this.objId = res.data.objId;
          this.entitlementName = "";
          res.data.dataList.forEach((itemA) => {
            itemA.data.forEach((itemB) => {
              if (itemB.left.apiname === "entitlement") {
                if (itemB.left.data[0].value) {
                  this.entitlementId = itemB.left.value;
                  this.entitlementName = itemB.left.data[0].value;
                }
              }
              if (itemB.right.apiname === "entitlement") {
                if (itemB.right.data[0].value) {
                  this.entitlementId = itemB.right.value;
                  this.entitlementName = itemB.right.data[0].value;
                }
              }
            });
          });
          if (this.entitlementName) {
            this.errorCodeInfo = false;
            this.getEventList();
          } else {
            this.loadingStatus = false;
            this.eventList = [];
            this.overEventList = [];
            this.errorCodeInfo = true;
            // 没有关联权利 this.$i18n.t('label.service.without.right')
            this.errorCodeInfoStr = this.$i18n.t("label.service.without.right");
          }
        })
        .catch(() => {});
    },
    getEventList() {
      if (this.entitlementId) {
        //       objId	是	String	对象id，例：工作订单（WorkOrderstdobj02021）
        //       entitlementId	否	String	权利id 没有不用传
        //       workOrderId	是	String	工作订单id
        //       cloudccCaseId	是	String	个案id 工作订单id跟个案id必填一个
        let option = {
          objId: this.objId,
          entitlementId: this.entitlementId,
        };
        this.loadingStatus = true;
        if (this.workOrderId.substring(0, 3) === "s21") {
          option.workOrderId = this.workOrderId;
        } else if (this.workOrderId.substring(0, 3) === "008") {
          option.cloudccCaseId = this.workOrderId;
        }
        serviceApi
          .getMajorEvent(option)
          .then((res) => {
            if (res.data === null) {
              this.errorCodeInfo = true;
            } else {
              this.errorCodeInfo = false;
            }
            this.errorCodeInfoStr = res.returnInfo;
            this.eventList = res.data || [];
            this.eventList.map((item) => {
              let timeUnit = item.timetrigger * 60 * 1000;
              item.endTime = this.getDateStr(item.startTime, timeUnit);
              item.startTime = this.getDateStr(item.startTime, 0);
            });
            this.loadingStatus = false;
          })
          .catch((err) => {
            this.loadingStatus = false;
            this.eventList = [];
            this.overEventList = [];
            this.errorCodeInfo = true;
            this.errorCodeInfoStr = err.returnInfo;
          });
        this.getAllEvents(option);
      } else {
        this.loadingStatus = false;
        this.eventList = [];
        this.overEventList = [];
        this.errorCodeInfo = true;
        // 没有关联权利 this.$i18n.t('label.service.without.right')
        this.errorCodeInfoStr = this.$i18n.t("label.service.without.right");
      }
    },
    getAllEvents(option) {
      serviceApi
        .getAllMajorEvent(option)
        .then((res) => {
          let tempArr = [];
          res.data.map((item) => {
            if (item.iscompleted || item.isviolated) {
              tempArr.push(item);
              // item.end = this.getDateStr(item.lastmodifydate, 0 )
            }
          });
          tempArr.sort(function (a, b) {
            return a.lastmodifydate < b.lastmodifydate ? 1 : -1;
          });
          if (tempArr.length === 0) {
            this.showMore;
          }
          this.overEventList = tempArr;
        })
        .catch(() => {});
    },
    // 点击完成后刷新重大事件
    freshEvent(obj) {
      // objId	是	String	对象id，例：工作订单（WorkOrderstdobj02021）
      // entitlementId	是	String	权利id
      // workOrderId	是	String	工作订单id
      // cloudccCaseId	是	String	个案id 工作订单id跟个案id必填一个
      // type	是	String	类型，获取重大事件接口返回
      // majorEventTypeId	是	String	重大事件类型id，获取重大事件接口返回
      // majorEventSetId	是	String	重大事件设置id，获取重大事件接口返回
      // entityMilestoneId	是	String	对象重大事件id，获取重大事件接口返回
      // entitlementProcessId	是	String	权力过程id，获取重大事件接口返回
      // timetrigger	是	String	事件触发器，多长时间，获取重大事件接口返
      let option = {
        objId: this.objId,
        entitlementId: this.entitlementId,
        // workOrderId: this.workOrderId，
        // cloudccCaseId	是	String	个案id 工作订单id跟个案id必填一个
        type: obj.type,
        majorEventTypeId: obj.majorEventTypeId,
        majorEventSetId: obj.majorEventSetId,
        entityMilestoneId: obj.entityMilestoneId,
        entitlementProcessId: obj.entitlementProcessId,
        timetrigger: obj.timetrigger + "",
      };
      if (this.workOrderId.substring(0, 3) === "s21") {
        option.workOrderId = this.workOrderId;
      } else if (this.workOrderId.substring(0, 3) === "008") {
        option.cloudccCaseId = this.workOrderId;
      }
      serviceApi
        .handleCompleteMajor(option)
        .then(() => {
          // 标记已完成 this.$i18n.t('label.service.marking.completed')
          this.$message.success(
            this.$i18n.t("label.service.marking.completed")
          );
          this.getEventList();
        })
        .catch(() => {});
    },
    getDateStr(date, timeUnit) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let forDate = new Date(date).getTime() + timeUnit;
      let d = new Date(forDate);
      return (
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate()) +
        " " +
        addDateZero(d.getHours()) +
        ":" +
        addDateZero(d.getMinutes()) +
        ":" +
        addDateZero(d.getSeconds())
      );
    },

    openMoreItem() {
      this.showMore = !this.showMore;
    },
  },
  created() {
    if (this.isOpenService === "true") {
      this.renderPages();
    }
  },
};
</script>

<style lang="scss" scoped>
.important-event {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  background: #ffffff;
  //border: 1px solid #DDDBDA;
  border-radius: 3px;
  &-h {
    height: 60px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    &-l {
      display: flex;
      align-items: center;
      &-img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
      &-text {
        width: 64px;
        font-size: 16px;
        font-weight: bold;
        color: #080707;
        line-height: 36px;
      }
    }

    &-r {
      width: 54px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #006dcc;
    }
  }
  &-c {
    border-bottom: 1px solid #dedcda;
    &-normal {
      padding: 0 10px 10px 10px;
      //border-bottom: 1px solid #DEDCDA;
      &-tips {
        font-size: 16px;
        color: #080707;
        line-height: 21px;
        margin-bottom: 10px;
      }
      &-item {
        padding: 10px 0 16px 0;
        border-bottom: 1px solid #dedcda;
      }
      &-item:last-child {
        border-bottom: 0;
      }
    }
    &-more {
      padding: 10px;
      border-top: 1px solid #dedcda;
      &-desc {
        display: flex;
        align-items: center;
        padding: 10px 0;
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        border-bottom: 1px solid #dedcda;
      }
      &-item {
        padding: 12px 0 10px 0;
        border-bottom: 1px solid #dedcda;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left {
          &-title {
            font-size: 16px;
            font-weight: bold;
            color: #080707;
            line-height: 21px;
            margin-bottom: 4px;
            //cursor: pointer;
            //&:hover{
            //  text-decoration: underline;
            //}
          }
          &-status {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
          }
        }
        &-right {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          &-svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      &-item:last-child {
        border-bottom: 0;
      }
    }
  }
  &-f {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    color: #006dcc;
    line-height: 19px;
    cursor: pointer;
  }
}
</style>
