import axios from '@/config/httpConfig'



//个人搜索对象设置页
export function getObjectSetting(data) {
    return axios.post('/globalSearch/getObjectSetting', data)
}
//保存全局搜索对象设置
export function saveObjectSetting(data) {
    return axios.post('/globalSearch/saveObjectSetting', data)
}
// 获取详情页标签
export function GetBrief(data) {
    return axios.post('/objectdetail/getBrief', data)
}
// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}
// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}
// 全局搜索高级筛选
export function getGlobalSearchSingleResult(data) {
    return axios.post("/KnowledgeArticle/getGlobalSearchSingleResult", data);
}
// 判断是否是作为主版本发布的条件
export function ismasterVersion(data) {
    return axios.post("/knowledge/ismasterVersion", data);
}
// 查询语言接口
export function queryLanguage(data) {
    return axios.post("/knowledgeTranslate/queryLanguage", data);
}
// 知识文章附加到个案
export function addCaseArticle(data) {
    return axios.post('/KnowledgeArticle/addCaseArticle', data)
}
// 取消附加到个案
export function cancelAttachCase(data) {
    return axios.post('/knowledgeArticleOperate/cancelAttachCase', data)
}

// 是否允许用户从个案中创建文章
export function judgeAddArticleByCase(data) {
    return axios.post('/knowledgeDesignate/judgeAddArticleByCase', data)
}