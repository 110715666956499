<template>
  <div class="serviceBox">
    <!-- 满意度评价 -->
    <div class="headChildBox">
      <div
        class="headChildContent"
        v-for="(item, index) in eavluationNumber"
        :key="index"
      >
        <!-- 左侧百分数 -->
        <div class="childLeft">
          <!-- 满意评价 -->
          <span>{{ $t("font-evaluate-module-v1-satisfaction") }}</span>
          <svg class="icon" aria-hidden="true">
            <use href="#icon-full_unsel_facce2_good"></use>
          </svg>
          <span>{{ goodEvNum }}</span>
        </div>
        <!-- 右侧评价 个数 -->
        <div class="childRight">
          <!-- 好评 -->
          <div class="pjOne">
            <!-- 好评 -->
            <span>{{ $t("label.evaluation.praise") }}</span>
            <span class="pjNumber">{{ item.positive }}</span>
          </div>
          <!-- 一般 -->
          <div class="pjTwo">
            <!-- 一般 -->
            <span>{{ $t("label.evaluation.general") }}</span>
            <span class="pjNumber">{{ item.moderate }}</span>
          </div>
          <!-- 差评 -->
          <div class="pjThree">
            <!-- 差评 -->
            <span>{{ $t("lable.evaluation.badreview") }}</span>
            <span class="pjNumber">{{ item.negative }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 评价列表 -->
    <div class="mainContent" v-if="serveListArray.length > 0">
      <!-- 评价的流程表 -->
      <div class="mainPart" v-show="isShow">
        <!-- 评价 流程线 -->
        <div
          class="serve-list"
          v-for="(item, index) in serveListArray"
          :key="index"
        >
          <!-- 评价时间  留下评价的联系人  -->
          <div class="serve-list-timeMan">
            <!-- 左侧时间 -->
            <div class="list-time">
              <!-- 时间 -->
              <div class="left-data">
                <div>{{ item.createdate }}</div>
              </div>
              <!-- 客户下才有的联系人名称  限制两个字 -->
              <div class="middle-icon" v-show="isKeHu">
                {{
                  item.contactname.substring(
                    item.contactname.length - 2,
                    item.contactname.length
                  )
                }}
              </div>
            </div>
            <!-- 客户下 右侧 联系人评价  -->
            <!-- 已留下了一个评价 -->
            <div class="list-man" v-show="isKeHu">
              {{ item.contactname }}
              {{ $t("font-evaluate-module-v1-have-comment") }}
            </div>
            <!-- 联系人样式 -->
            <div class="kehuStyle" v-show="!isKeHu">
              <!-- 评价样式  笑脸 小手-->
              <div class="iconStyle">
                <svg class="icon" aria-hidden="true">
                  <use :href="'#' + item.emoji"></use>
                </svg>
              </div>
              <!-- 请求单编号 -->
              <div class="serveSerial" @click="caseDetail(item.caseid)">
                #{{ item.name }}{{ item.zhuti }}
              </div>
            </div>
          </div>
          <!-- 客户下  关联个案的服务请求单编号 -->
          <div class="serve-list-serial" v-show="isKeHu">
            <!-- 评价样式  笑脸 小手-->
            <div class="icon-style">
              <svg class="icon" aria-hidden="true">
                <use :href="'#' + item.emoji"></use>
              </svg>
            </div>
            <!-- 请求单编号 -->
            <div class="serve-serial" @click="caseDetail(item.caseid)">
              #{{ item.name }}{{ item.zhuti }}
            </div>
          </div>
          <!-- 流程线 -->
          <!-- 客户 下的 -->
          <div class="line-position" v-show="isKeHu"></div>
          <!-- 联系人 下的-->
          <div
            class="linePosition"
            v-show="!isKeHu && index != serveListArray.length - 1"
          ></div>
        </div>
      </div>
      <!-- 展开 收起 -->
      <div class="controlBtn" @click="controlMethod">{{ controlText }}</div>
    </div>
    <!-- 评价列表无数据时 -->
    <!-- 您尚未从联系人收到满意度评价 -->
    <div class="evNoData" v-else>
      {{ $t("font-evaluate-module-v1-not-received") }}
    </div>
  </div>
</template>
<script>
import * as evaluationApi from "../api.js";
export default {
  name: "serviceEvaluation",
  props: {
    evaluationID: {
      type: String,
      default: "",
    },
    evaluationType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isKeHu: false, // 联系人下的评价为false  客户为true
      isShow: true, //评价列表的展开和收起
      controlText: this.$i18n.t("vue_label_approve_put_away"), //展开和收起 文本切换 默认收起      收起
      serveListArray: [], //评价 流程线 数据
      eavluationNumber: [], //好 中 差评 数量
      goodEvNum: "", //好评度
      goodNumber: 0, //好评的数量
      tottleNumber: 0, //好中差评的总的数量
      isEvEmail: true,
      isEvWeb: false,
      isEvXcx: false,
    };
  },
  mounted() {
    this.getEvaluation();
  },
  methods: {
    // 联系人和客户下的服务评价相关列表展示
    async getEvaluation() {
      // 联系人
      if (this.evaluationType === "contact") {
        this.isKeHu = false;
        let params = {
          contactid: this.evaluationID,
        };
        let res = await evaluationApi.getContactSatisfactionEvaluation(params);
        this.$emit("accountNumber", res.data.evaluationlist.length);
        if (res.data.evaluationlist.length === 0) {
          this.eavluationNumber = res.data.sum;
          this.eavluationNumber.forEach((item) => {
            this.$set(item, "positive", 0);
            this.$set(item, "moderate", 0);
            this.$set(item, "negative", 0);
          });
          this.goodEvNum = 0 + "%";
        } else {
          // 好中差评数量
          this.eavluationNumber = res.data.sum;
          // 好评数量
          this.goodNumber = this.eavluationNumber[0].positive;
          // 好中差总的数量
          this.tottleNumber =
            this.eavluationNumber[0].positive +
            this.eavluationNumber[0].moderate +
            this.eavluationNumber[0].negative;
          // 好评度
          this.goodEvNum =
            ((this.goodNumber / this.tottleNumber) * 100).toFixed(0) + "%";
          // 评价列表
          this.serveListArray = res.data.evaluationlist;
          this.serveListArray.forEach((item) => {
            this.$set(item, "contactname", "wzj");
          });
        }
      } else if (this.evaluationType === "account") {
        // 客户
        this.isKeHu = true;
        let params = {
          accountid: this.evaluationID,
        };
        let res = await evaluationApi.getAccountSatisfactionEvaluation(params);
        this.$emit("accountNumber", res.data.evaluationlist.length);
        if (res.data.evaluationlist.length === 0) {
          this.eavluationNumber = res.data.sum;
          this.eavluationNumber.forEach((item ) => {
            this.$set(item, "positive", 0);
            this.$set(item, "moderate", 0);
            this.$set(item, "negative", 0);
          });
          this.goodEvNum = 0 + "%";
        } else {
          // 好中差评数量
          this.eavluationNumber = res.data.sum;
          // 好评数量
          this.goodNumber = this.eavluationNumber[0].positive;
          // 好中差总的数量
          this.tottleNumber =
            this.eavluationNumber[0].positive +
            this.eavluationNumber[0].moderate +
            this.eavluationNumber[0].negative;
          // 好评度
          this.goodEvNum =
            ((this.goodNumber / this.tottleNumber) * 100).toFixed(0) + "%";
          // 评价列表
          this.serveListArray = res.data.evaluationlist;
        }
      }
    },
    // 个案详情页跳转
    caseDetail(caseid) {
      this.$router.push({
        path: `/commonObjects/detail/${caseid}/DETAIL`,
      });
    },
    // 收起和展开
    controlMethod() {
      this.controlText =
        this.controlText === this.$i18n.t("vue_label_approve_put_away")
          ? this.$i18n.t("label.unfold")
          : this.$i18n.t("vue_label_approve_put_away");
      this.isShow =
        this.controlText === this.$i18n.t("vue_label_approve_put_away")
          ? true
          : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.serviceBox {
  // 服务评价标题
  .headBox {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    background: #f5f5f5;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #dedcda;
    .headContent {
      padding-left: 10px;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: -4px;
      }
    }
  }
  // 满意度评价
  .headChildBox {
    width: 100%;
    .headChildContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 43px 17px 30px;
      font-size: 14px;
      color: #333333;
      .childLeft {
        text-align: center;
        span {
          display: inline-block;
          margin: 0 6px;
        }
        .icon {
          display: inline-block;
          width: 16px;
          height: 16px;
        }
      }
      .childRight {
        display: flex;
        align-items: center;
        // 评价数量的样式
        .pjNumber {
          display: inline-block;
          width: 36px;
          height: 16px;
          line-height: 16px;
          font-size: 12px;
          text-align: center;
          color: #333333;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #dedcda;
          margin-left: 6px;
        }
        // 好评
        .pjOne {
          color: #74e00e;
        }
        // 一般
        .pjTwo {
          margin-left: 43px;
          color: #e9dd26;
        }
        // 差评
        .pjThree {
          margin-left: 43px;
          color: #ff753c;
        }
      }
    }
  }
  // 评价列表
  .mainContent {
    width: 100%;
    // 评价主要列表
    .mainPart {
      width: 100%;
      padding: 17px 0;
      min-height: 110px;
      max-height: 360px;
      border-top: 1px solid #dedcda;
      overflow-y: auto;
      // 评价 流程线
      .serve-list {
        position: relative;
        // 评价时间  留下评价的联系人
        .serve-list-timeMan {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          // 左侧时间
          .list-time {
            display: flex;
            align-items: center;
            .left-data {
              width: 111px;
              text-align: right;
              font-size: 12px;
              font-weight: 400;
              color: #666;
              margin: 6px 6px 0 2px;
              div:nth-of-type(1) {
                width: 101px;
                color: #666666;
                font-size: 12px;
              }
            }
            // 节点图标
            .middle-icon {
              width: 36px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              font-size: 12px;
              border: solid 1px #dedcda;
              border-radius: 50%;
              background: #f5f5f5;
              z-index: 10;
              margin: 0 17px 0 10px;
            }
          }
          // 右侧 评价的联系人
          .list-man {
            font-size: 14px;
            color: #333333;
          }
          // 客户对象下的评价样式
          .kehuStyle {
            position: relative;
            margin: 0 0 0 9px;
            display: flex;
            align-items: center;
            .iconStyle {
              width: 30px;
              height: 30px;
              z-index: 10;
              .icon {
                width: 30px;
                height: 30px;
              }
            }
            .iconPosition {
              width: 13px;
              height: 13px;
              position: absolute;
              top: -7px;
              left: 17px;
              .icon {
                width: 13px;
                height: 13px;
              }
            }
            .serveSerial {
              margin-left: 12px;
              font-size: 14px;
              color: #006dcc;
              cursor: pointer;
            }
          }
        }
        // 关联个案的服务请求单编号
        .serve-list-serial {
          position: relative;
          margin: 17px 0 17px 135px;
          display: flex;
          align-items: center;
          .icon-style {
            width: 26px;
            height: 26px;
            z-index: 10;
            .icon {
              width: 26px;
              height: 26px;
            }
          }
          // 编号信息
          .serve-serial {
            margin-left: 23px;
            font-size: 12px;
            color: #006dcc;
            cursor: pointer;
          }
        }
        // 流程线
        .line-position {
          position: absolute;
          height: 87px;
          left: 146px;
          top: 34px;
          border-left: 1px solid #dedcda;
        }
        .linePosition {
          position: absolute;
          height: 50px;
          left: 141px;
          top: 30px;
          border-left: 1px solid #dedcda;
        }
      }
    }
    // 展开 收起
    .controlBtn {
      width: 100%;
      height: 33px;
      line-height: 33px;
      font-size: 14px;
      font-weight: 500;
      color: #006dcc;
      background: #ffffff;
      border-radius: 0px 0px 3px 3px;
      border-top: 1px solid #dedcda;
      text-align: center;
      cursor: pointer;
    }
  }
  // 评价列表无数据时
  .evNoData {
    width: 100%;
    height: 56px;
    line-height: 56px;
    border-top: solid 1px #dedcda;
    font-size: 12px;
    color: #666666;
    text-align: center;
  }
}
</style>