<template>
  <!-- 营销渠道相关列表 -->
  <!-- 开发人员：贺文娟 -->
  <el-card class="box-main_main" shadow="never">
    <div class="relation-list-obj">
      <!-- 通用相关列表 -->
      <div class="tit">
        <div class="tit_s">
          <svg class="icon imgClass" aria-hidden="true">
            <use href="#icon-hometab"></use>
          </svg>
          <p class="hover_p">
            {{ $t("label.channel") }}（{{
              tableDataList.length >= 10 ? "10+" : tableDataList.length
            }}）
          </p>
        </div>
        <el-button-group style="position: relative" class="right_btn">
          <el-button
            class="button"
            size="small"
            v-for="item in objectInfo.showButton"
            :key="item.id"
            @click="buttonFun(item)"
            style="position: relative"
          >
            <span>{{ item.label }}</span>
          </el-button>
        </el-button-group>
      </div>

      <!--    相关列表-->
      <div>
        <tablePanel
          :tableAttr="tableAttr"
          :operationButtonList="operationButtonList"
          :tableDataList="tableDataList"
          @edit="edit"
          @delete="del"
          @invitationCode="invitationCode"
          @qrCode="qrCode"
          @activeMember="activeMember"
          @viewSourceCode="viewSourceCode"
        ></tablePanel>
      </div>
      <!-- 在右侧只能是卡片 -->

      <!-- 查看更多 -->
      <div class="see_more">
        <span @click="seeAllBtn">{{
          $t("component.todaytask.label.all")
        }}</span>
      </div>
    </div>
    <!--  弹窗-->
    <stepdialog
      :isShowDialog.sync="isShowDialog"
      @closeDialog="closeDialog()"
      :dialogTitle="stepdialogTitle"
    ></stepdialog>

    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.emailtocloudcc.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确认删除该条数据吗 -->
      <span style="font-size: 14px; color: #080707">{{
        $t("label.weixin.confirm.delete")
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button @click="confirmDel" class="el-button--primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 查看源码弹窗 -->
    <el-dialog
      :visible.sync="sourceCodeFlg"
      :title="$t('label.source.code')"
      top="15%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
    >
      <souceCode
        :sourceCode.sync="sourceCode"
        :editable="true"
        :information="information"
        :canWrite="true"
      ></souceCode>
    </el-dialog>

    <!-- 二维码弹框 -->
    <el-dialog
      :visible.sync="qrcodeFlg"
      :title="$t('label.qrcode')"
      top="15%"
      width="55%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div style="min-width: 750px; float: left; width: 100%">
        <div class="wrapper">
          <h2
            style="
              font-size: 18px;
              color: #555;
              margin: 0px;
              padding-bottom: 15px;
            "
          >
            {{ $t("label.campaign.channel.code") }}
          </h2>
          <div class="content">
            {{ $t("label.campagin.roi.name") }}：<br />
            <p>{{ campaignname }}</p>
            {{ $t("label.channel") }}：<br />
            <p>{{ channel }}</p>
            {{ $t("label.url") }}:<br />
            <p>
              <a :href="url" target="_blank">{{ url }}</a
              ><br />
            </p>
            <br />
            <div style="margin-top: -10px">
              {{ $t("label.campaign.channel.download") }} :
            </div>
            <p class="download">
              <a :href="download1000">{{
                $t("label.campaign.channel.download.size1")
              }}</a>
            </p>
            <p class="download">
              <a :href="download500">{{
                $t("label.campaign.channel.download.size2")
              }}</a>
            </p>
            <p class="download">
              <a :href="download300">{{
                $t("label.campaign.channel.download.size3")
              }}</a>
            </p>
          </div>
        </div>
        <div style="float: left; text-align: center; width: 340px">
          <a :href="url" target="_blank">
            <img
              :alt="$t('label.campaign.channel.code')"
              width="200px;"
              :src="qrcodeImg"
            />
          </a>
        </div>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import souceCode from '@/views/marketList/marketingChannel/component/sourceCode/index.vue'
import tablePanel from '@/views/marketList/marketingChannel/component/table-panel/index'
import stepdialog from '@/views/marketList/marketingChannel/component/stepDialog/index'
import * as CommonObjApi from '../api'

export default {
  name: 'marketingChannelList',
  components: {
    souceCode,
    tablePanel,
    stepdialog,
  },
  data() {
    return {
      Slinkshow: true,
      QRcodeshow: false,
      shareName: '',
      sharetype: false,
      // 操作按钮的下拉框按钮数据
      operationButtonList: [
        { action: 'edit', label: this.$i18n.t('label.emailtocloudcc.edit') },
        {
          action: 'delete',
          label: this.$i18n.t('pagecreator.page.button.delete'),
        },
        { action: 'invitationCode', label: this.$i18n.t('label.yard') },
        { action: 'qrCode', label: this.$i18n.t('label.qrcode') },
        {
          action: 'activeMember',
          label: this.$i18n.t('label.members.activity'),
        },
      ],
      information: {},
      sourceCodeFlg: false,
      sourceCode: '',
      dialogVisible: false,
      download500: '',
      download300: '',
      channel: '',
      download1000: '',
      campaignname: '',
      url: '',
      qrcodeImg: '',
      qrcodeFlg: false,
      // 相关列表右侧按钮
      objId: '',
      objectInfo: {
        showButton: [
          { label: this.$i18n.t('label.import.page1.newcreate'), id: 'new' },
          {
            label: this.$i18n.t(
              'vue_label_commonobjects_detail_related_listview'
            ),
            id: 'show',
          },
        ],
      },
      isShowDialog: false,
      //表头
      tableAttr: [],
      tableDataList: [],
      // 弹窗标题
      stepdialogTitle:""
    }
  },
  created() {
    this.init()
  },
  methods: {
    viewSourceCode(row) {
      CommonObjApi.channelPreview({ id: row.id }).then((res) => {
        if (res.result) {
          this.sourceCode = decodeURIComponent(res.data.pagecontent)
          this.information = res.data
          this.sourceCodeFlg = true
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.returnInfo,
          })
        }
      })
    },
    activeMember(row) {
      this.$router.push({
        path: `/activeMember/${this.$route.params.id}`,
        query: {
          id: this.$route.params.id,
          channel: row.channel,
          tagId: row.id,
        },
      })
    },
    // 编辑
    edit(id) {
      this.$store.commit('changeItemDataId', id) // 点击编辑将每条数据的id存在state中，
      this.$store.commit('changeActionType', 'edit') //
        this.stepdialogTitle=this.$i18n.t("label.campaign.channel.edit")
      this.isShowDialog = true // 弹窗打开
    },
    invitationCode(id) {
      this.$router.push({
        name: `invitation`,
        params: {
          id: id,
          objId: 'user',
          objectApi: 'campain',
          prefix: '005',
          type: 'table',
        },
      })
      // window.open(`${this.$cookies.get(
      //   'domainName'
      // )}/user.action?m=query&campaignid=${this.$route.params.id}&registerid=${id}`)
    },
    //二维码
    qrCode(id) {
      CommonObjApi.getQrCode({ id: id }).then((res) => {
        if (res.result) {
          this.download500 = res.data.download500
          this.download300 = res.data.download300
          this.channel = res.data.channel
          this.download1000 = res.data.download1000
          this.campaignname = res.data.campaignname
          this.url = res.data.url
          this.qrcodeImg = res.data.codeimgurl
          this.qrcodeFlg = true
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.returnInfo,
          })
        }
      })
    },
    //初始化
    init() {
      let id = this.$route.params.id
      this.$store.commit('getCampaignId', this.$route.params.id)
      if (id) {
        CommonObjApi.getMarketList({ id: id }).then((res) => {
          if (res.result) {
            this.tableAttr = res.data.header
            this.tableDataList = res.data.dataList
            this.$store.commit(
              'getMarketingListLength',
              res.data.dataList.length
            )
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: res.returnInfo,
            })
          }
        })
      }
    },
    closeDialog() {
      // 刷新列表
      this.init()
      this.qrcodeFlg = false
      this.isShowDialog = false
    },
    // 查看更多跳转到营销渠道列表展示页面
    seeAllBtn() {
      this.$router.push({
        path: `/marketingChannelListShow`,
        query: {
          id: this.$route.params.id,
          objId: this.$route.params.objId,
        },
      })
    },
    del(id) {
      this.dialogVisible = true
      this.id = id
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = false
      CommonObjApi.delMarketList({ id: this.id }).then((res) => {
        if (res.result) {
          this.$message({
            showClose: true,
            type: 'success',
            message: this.$i18n.t('label_tabpage_delsuccessz'),
          })
          this.init()
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.returnInfo,
          })
        }
      })
    },
    // 关闭弹窗
    handleClose() {
      this.sourceCodeFlg = false
      this.qrcodeFlg = false
      this.dialogVisible = false
    },
    // 列表展示
    buttonFun(item) {
      if (item.id == 'show') {
        this.$router.push({
          path: `/marketingChannelListShow`,
          query: {
            id: this.$route.params.id,
          },
        })
      } else if (item.id == 'new') {
        this.$store.commit('changeActionType', 'new')
        this.stepdialogTitle=this.$i18n.t("label.campaign.channel.new")
        this.isShowDialog = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.icon {
  display: inline-block;
}
.content {
  font-size: 14px;
  padding-left: 20px;
}
.download {
  margin-left: 40px !important;
}
.wrapper {
  padding-left: 10px;
  padding-top: 10px;
  word-break: break-all;
  font-family: "Microsoft Yahei";
  float: left;
  width: 400px;
}
.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.totip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  .upload-demo {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .el-upload .el-upload--text {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .tit_s {
    display: flex;
    img {
      margin-right: 7px;
      //   float: left;
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0;
      //   float: right;
      height: 20px;
      font-size: 12px;
      color: #080707;
      line-height: 20px;
    }
    .hover_p:hover {
      border-bottom: 1px solid #006dcc;
      cursor: pointer;
      color: #006dcc;
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}

.cont_table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  .cont {
    display: flex;
    flex-wrap: wrap;
    width: 46.7%;
    justify-content: space-between;
    padding: 6px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #dddbda;
    .title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // min-height: 30px;
      word-break: break-all;
      p {
        min-height: 20px;
        margin: 0;
        font-size: 12px;
        color: #006dcc;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .cont_div {
      width: 50%;
      display: flex;
      width: 100%;
      .list_left {
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        width: 100%;
        li {
          font-size: 12px;
          min-height: 16px;
          display: flex;
          .dis_a {
            ::v-deep a {
              color: #080707 !important;
              cursor: default;
            }
          }
          .font_li {
            padding-left: 20px;
            font-size: 14px;
            color: #080707;
          }
          .over_p .font_li {
            padding-left: 0;
          }
          span:first-child {
            margin-right: 30px;
          }
          .fieldName {
            margin-right: 10px;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            font-size: 12px;
            color: #3e3e3c;
            line-height: 18px;
          }
          //   .over_p {
          //     max-width: 75%;
          //     overflow: hidden;
          //     text-overflow: ellipsis;
          //     white-space: nowrap;
          //     font-size: 12px;
          //     color: #080707;
          //     // padding-left: 20px;
          //   }
        }
      }
    }
  }
}
//列表样式
.text-center {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  // ::v-deep.el-table__header-wrapper {
  //   // border-top: 1px solid #dedcda !important;
  // }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }
  // ::v-deep .editIconImg {
  //   display: none !important;
  // }
  ::v-deep .el-popover__reference {
    width: 20px !important;
    border-radius: 3px !important;
  }
  ::v-deep .el-table tr:last-child {
    td {
      border-bottom: 0 !important;
    }
  }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell {
    font-size: 12px;
  }
}
.pricebook_box {
  border: 0;
  tr {
    border-top: 1px solid #dddbda;
    th,
    td {
      padding: 11px 14px;
      font-size: 12px;
    }
    th {
      border-right: 1px solid #dedcda;
      background: #fafaf9;
      color: #666666;
    }
  }
}

.filedname {
  margin-left: 9px;
  margin-bottom: 10px;
}
.filednametitle {
  display: inline-block;
  width: 140px;
  font-size: 12px;
  color: #3e3e3c;
}

.see_more {
  border-top: 1px solid #ccc;
  height: 44px;
  line-height: 44px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    cursor: pointer;
  }
}
::v-deep .dropbox {
  width: 38px;
}
</style>
<style scoped>
.box-main_main {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid #dddbda;
  margin-top: 10px;
}
.my-tooltip .dis_b ::v-deep a {
  color: #fff;
  cursor: default;
}
.xiala_s {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}
.list_xiala_s {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.xiala_s img {
  cursor: pointer;
  width: 12px;
  margin-top: -2px;
}

.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}

.upload-demo {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.upload-demo ::v-deep .el-upload {
  width: 100% !important;
  text-align: left !important;
}
.upload-demo ::v-deep .el-upload-dragger {
  width: 100% !important;
}
.imgClass {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
</style>
