<template>
  <!-- 开发人员：王雪茹 -->
  <div class="suspension">
    <div class="tit-box">
      <div class="tit_s">
        <svg class="icon" aria-hidden="true">
          <use
            :href="
              itemArr.tabStyle ? '#icon-' + itemArr.tabStyle : '#icon-hometab'
            "
          ></use>
        </svg>
        <dl>
          <dd
            @click="
              itemArr.objName !== 'tp_sys_approval_instance_his' &&
              itemArr.objLabel !== $t('lable.opppdt.selPbk.title')
                ? tableView(itemArr)
                : ''
            "
            style="cursor: pointer; color: #006dcc"
          >
            <span v-html="itemArr.objLabel"> </span>
          </dd>
        </dl>
      </div>
    </div>
    <!-- 批准历史 -->
    <div
      v-if="
        historyData &&
        historyData.length !== 0 &&
        itemArr.objName === 'tp_sys_approval_instance_his'
      "
      :style="{ maxHeight: titOffsetTop + 'px', overflowY: 'scroll' }"
    >
      <table class="history_tablle" style="width: 100%" bordercolor="#DDDBDA">
        <tr class="tit">
          <!-- 日期 -->
          <th>{{ $t("date") }}</th>
          <!-- 状态 -->
          <th>{{ $t("label.state") }}</th>
          <!-- 被分配人 -->
          <th>{{ $t("label.assigned") }}</th>
          <!-- 实际批准人 -->
          <th>{{ $t("label.layout.realityapprover") }}</th>
          <!-- 注释 -->
          <th>{{ $t("label.note") }}</th>
          <!-- 整体状态 -->
          <th>{{ $t("label.wholestate") }}</th>
        </tr>
        <tbody v-for="(item, index1) in historyData" :key="index1">
          <tr>
            <!-- 步骤 -->
            <td colspan="5">{{ $t("step") }}:{{ item.stepName }}</td>
            <td>
              <!-- 已提交 -->
              <b v-if="item.status === 'Started'">{{
                $t("label.submitted")
              }}</b>
              <!-- 已批准 -->
              <b v-else-if="item.status === 'Approved'">{{
                $t("label.approved")
              }}</b>
              <!-- 待处理 -->
              <b
                v-else-if="
                  item.status === 'Pending' || item.status === 'pending'
                "
                >{{ $t("label.pending") }}</b
              >
              <!-- 已调回 -->
              <b v-else-if="item.status === 'Recalled'">{{
                $t("lebel.alreadyback")
              }}</b>
              <!-- 已拒绝 -->
              <b v-else-if="item.status === 'Rejected'">{{
                $t("label.refused")
              }}</b>
            </td>
          </tr>
          <tr v-for="(val, i) in item.instancehis" :key="i">
            <td>{{ val.lastModifyDate }}</td>
            <td>
              <!-- 已提交 -->
              <span v-if="val.stepStatus === 'Started'">{{
                $t("label.submitted")
              }}</span>
              <!-- 已批准 -->
              <span v-else-if="val.stepStatus === 'Approved'">{{
                $t("label.approved")
              }}</span>
              <!-- 待处理 -->
              <span v-else-if="val.stepStatus === 'Pending'">{{
                $t("label.pending")
              }}</span>
              <!-- 已调回 -->
              <span v-else-if="val.stepStatus === 'Recalled'">{{
                $t("label.pending")
              }}</span>
              <!-- 已拒绝 -->
              <span v-else-if="val.stepStatus === 'Rejected'">{{
                $t("label.refused")
              }}</span>
            </td>
            <td>{{ val.assigntoName }}</td>
            <td>{{ val.actualName }}</td>
            <td>{{ val.comments }}</td>
            <td>
              <span></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 价格手册 -->
    <div
      v-if="
        pricebookArr &&
        pricebookArr.length !== 0 &&
        itemArr.objLabel === $t('lable.opppdt.selPbk.title')
      "
      :style="{ maxHeight: titOffsetTop + 'px', overflowY: 'scroll' }"
    >
      <table style="width: 100%" bordercolor="#DDDBDA">
        <tr class="tit">
          <th></th>
          <!-- 价目表价格 -->
          <th>{{ $t("lable.product.plist") }}</th>
          <!-- 使用标准价格 -->
          <th>{{ $t("lable.product.pricebooks.ustand") }}</th>
          <!-- 已启用 -->
          <th>{{ $t("label.inusing") }}</th>
        </tr>
        <tr v-for="(item, index1) in pricebookArr" :key="index1">
          <td>{{ index1 + 1 }}</td>
          <td>{{ item.currency }}{{ item.listPrice }}</td>
          <td>
            <el-checkbox
              value="item.useStdPrice === 'true'"
              disabled
            ></el-checkbox>
          </td>
          <td>
            <el-checkbox
              value="item.isenable === 'true'"
              disabled
            ></el-checkbox>
          </td>
        </tr>
      </table>
    </div>
    <div
      v-if="
        itemArr.fieldList &&
        itemArr.data &&
        itemArr.data.length !== 0 &&
        itemArr.objName !== 'tp_sys_approval_instance_his' &&
        !itemArr.data[0].norecord
      "
      :style="{ maxHeight: titOffsetTop + 'px', overflowY: 'auto' }"
    >
      <table-panel
        :islineEdit="false"
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-index="true"
        :showLock="false"
        :checked="false"
        :customHeader="false"
        :prefix="itemArr.prefix"
        :obj-id="itemArr.objid"
        :objectApi="itemArr.objName"
        :pageNum="2"
        :table-attr="itemArr.fieldList"
        :page-obj="pageObj"
        :itemObj="itemArr"
        :mainId="id"
      >
      </table-panel>
      <div
        v-if="itemArr.objName !== 'tp_sys_approval_instance_his'"
        class="more-btn-all"
        @click="tableView(itemArr)"
      >
        <!-- 查看全部 -->
        {{ $t("label.viewall") }}
      </div>
    </div>
    <div
      v-if="
        (itemArr.data &&
          itemArr.data.length == 0 &&
          itemArr.objName !== 'tp_sys_approval_instance_his') ||
        (itemArr.data && itemArr.data[0].norecord)
      "
      class="noth_box"
    >
      <!-- 没有记录 -->
      {{ $t("label.norecord") }}
    </div>
    <div
      v-if="
        itemArr.objName === 'tp_sys_approval_instance_his' &&
        historyData.length === 0
      "
      class="noth_box"
    >
      <!-- 没有记录 -->
      {{ $t("label.norecord") }}
    </div>
    <div
      v-if="
        itemArr.objLabel === $t('lable.opppdt.selPbk.pricebook') &&
        pricebookArr.length === 0
      "
      class="noth_box"
    >
      <!-- 没有记录 -->
      {{ $t("label.norecord") }}
    </div>
  </div>
</template>
<script>
import { getPermissionById } from "./api";
import { TablePanel } from "@/components/index";
export default {
  components: {
    TablePanel,
  },
  props: {
    itemArr: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Array,
    },
    titOffsetTop: {
      type: Number,
    },
    pricebookArr: {
      type: Array,
    },
    list: {
      type: Array,
    },
    relatedListIndex: {
      type: String,
      default: "",
    },
    bigTitle: {
      type: String,
      default: "",
    },
    objId: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    pageObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // //业务机会小组
      // popTableVisible: false, //管理小组
      // manualVisible: false, //选择价格手册
      // addmanualVisible: false, //添加产品
      // productsVisible: false, //编辑全部
      // contactsVisible: false, //编辑业务机会联系人
      // opportunityId: "",
      // loadingDetail: true,
      // seeMoveFlag: false, //查看更多
      // pricebooks: [], //价格手册数据
      // addmanualWrapArr: {}, //添加产品大对象
      // defaultManual: "",
      // opppdtsWrapArr: {}, //编辑现有产品大对象
      // addmanualData: [], //添加产品表格数据
      // condition: [], //筛选的数组
      // isAdd: "编辑",
      // //以下是报价单的添加产品及价格手册
      // singleManualVisible: false,
      // singleAddmanualVisible: false,
      // singleProductsVisible: false,
      // singlePricebooks: [],
      // singleAddmanualWrapArr: {},
      // singleAddmanualData: [],
      // singleDefaultManual: "",
      // singleCondition: [],
      // popNoDataTip: false, // 是否提示无更多数据
      // singleNoDataTip: false,
      // singlePageSize: 3,
      // singlePage: 1,
      // pageSize: 3,
      // page: 1,
      // transferOwnerDialog: false,
      // transferClientOwnerDialog: false,
      // optionList: {},
      // //文件
      // dialogAddVisible: false,
      // //添加市场活动成员
      // showAddCampaignMembers: false,
      // // 新建编辑
      // dialogNewAttr: {
      //   title: "",
      //   type: "NEW",
      //   isShow: false,
      //   dialogWidth: "85%",
      //   id: "",
      // },
      // relatedArr: {},
      // recordType: "",
      // //审批历史
      // headerBtn: [], // 传回父组件的审批按钮
      // //价格手册
      // pricebookdialogVisible: false,
      // product: "",
      // checked3: false,
      // pricebooklist: [],
      // input8: "",
      // checked2: false,
      // checked4: false,
      // checked5: false,
      // value8: "",
      // currencyList: [], // 标准价格手册币种列表
      // pricecurrencyList: [], //价格手册需要币种列表
      // editPricebooklist: [],
      // customPrice: [],
      // state2: "",
      // pricebooklistid: "",
      // data: {},
      // count: "",
      // listObjectApi: "",
      // listPrefix: "",
      // dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      // datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      // timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      // countryCode: this.$cookies.get("countryCode"),
      // token: this.$CCDK.CCToken.getToken(),
    };
  },
  created() {},
  methods: {
    // 相关列表菜单跳转列表视图
    tableView(item) {
      this.list.map((val) => {
        if (val.relatedlistId === item.id) {
          let routerPath="";
          // 服务控制台下打开二级菜单
          if(this.$store.state.navigationStyle){
            routerPath=`/commonObjects/console-multi-screen/console-commonObjects/suspension-list/${this.id}/${val.attachementType}`;
            let menuObj = {
            name:val.relationName ,
            id: item.id,
            routerPath: routerPath,
            query: {
              relatedlistId: val.relatedlistId,
              layoutId: val.layoutId,
              objId: this.objId,
              relatedlistType: val.relatedlistType,
              },
            }
            window.$CCDK.CCMenu.addMenu2(menuObj);
          }else{
            routerPath=`/commonObjects/suspension-list/${this.id}/${val.attachementType}`;
          }
          this.$router.push({
            path: routerPath,
            query: {
              relatedlistId: val.relatedlistId,
              layoutId: val.layoutId,
              objId: this.objId,
              relatedlistType: val.relatedlistType,
            },
          });
        }
      });
    },
    //详情跳转
    detail(row, n, val) {
      if (val.type === "Y" && val.lookupObj === "user") {
        getPermissionById({
          id: n[val.name],
        }).then((res) => {
          if (res.data.isQuery) {
            this.$router.push({
              path: `/commonObjects/detail/${n[val.name]}/DETAIL`,
            });
          } else {
            this.$message.warning(
              this.$i18n.t("vue_label_notice_nopermission_view")
              //"抱歉，您无权查看该条数据，请联系系统管理员。"
            );
          }
        });
      } else if (row.objid === "attachement") {
        getPermissionById({
          id: this.id,
        }).then((res) => {
          if (res.data.isLocked && res.data.isEdit === false) {
            this.$message.warning(
              this.$i18n.t("front-lighting-module-v1-detail-file-downlaodInfo")
            );
          } else {
            let baseURL = this.$baseConfig.baseURL;
            let token = this.$CCDK.CCToken.getToken();
            var link = document.createElement("a");
            link.setAttribute("download", "");
            link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${n.id}`;
            link.click();
          }
        });
      }
      if (val.name === "name") {
        this.$router.push({
          path: `/commonObjects/detail/${n.id}/DETAIL`,
        });
      } else {
        this.$router.push({
          path: `/commonObjects/detail/${n[val.name]}/DETAIL`,
          query: {
            viewId: "",
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.suspension {
  ::v-deep .more-btn-all {
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    color: #006dcc;
    cursor: pointer;
  }
  .tit-box {
    display: flex;
    justify-content: space-between;
    .tit_s {
      display: flex;
      margin-bottom: 10px;
      svg {
        width: 36px;
        height: 36px;
      }
      dl {
        margin: 0 0 0 8px;
        line-height: 36px;
        dd {
          margin: 0;
          font-size: 14px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  ::v-deep .text-center {
    tr {
      th {
        .cell {
          font-size: 12px;
        }
      }
      td {
        div {
          width: 100% !important;
          .user_box {
            line-height: 23px;
            height: 23px;
            display: flex;
            width: 100%;
            .headPortrait {
              width: 23px !important;
              .logoname {
                font-size: 12px !important;
                line-height: 23px;
              }
            }
          }
        }
        a,
        span {
          font-size: 12px;
        }
      }
    }
  }
  .history_tablle {
    tr {
      td:first-child {
        width: 230px !important;
      }
    }
  }
}
</style>
