import { render, staticRenderFns } from "./Knowledgesearch.vue?vue&type=template&id=67f4a463&scoped=true&"
import script from "./Knowledgesearch.vue?vue&type=script&lang=js&"
export * from "./Knowledgesearch.vue?vue&type=script&lang=js&"
import style0 from "./Knowledgesearch.vue?vue&type=style&index=0&id=67f4a463&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f4a463",
  null
  
)

export default component.exports