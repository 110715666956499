var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"suspension"},[_c('div',{staticClass:"tit-box"},[_c('div',{staticClass:"tit_s"},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":_vm.itemArr.tabStyle ? '#icon-' + _vm.itemArr.tabStyle : '#icon-hometab'}})]),_c('dl',[_c('dd',{staticStyle:{"cursor":"pointer","color":"#006dcc"},on:{"click":function($event){_vm.itemArr.objName !== 'tp_sys_approval_instance_his' &&
            _vm.itemArr.objLabel !== _vm.$t('lable.opppdt.selPbk.title')
              ? _vm.tableView(_vm.itemArr)
              : ''}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.itemArr.objLabel)}})])])])]),(
      _vm.historyData &&
      _vm.historyData.length !== 0 &&
      _vm.itemArr.objName === 'tp_sys_approval_instance_his'
    )?_c('div',{style:({ maxHeight: _vm.titOffsetTop + 'px', overflowY: 'scroll' })},[_c('table',{staticClass:"history_tablle",staticStyle:{"width":"100%"},attrs:{"bordercolor":"#DDDBDA"}},[_c('tr',{staticClass:"tit"},[_c('th',[_vm._v(_vm._s(_vm.$t("date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.state")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.assigned")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.layout.realityapprover")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.note")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.wholestate")))])]),_vm._l((_vm.historyData),function(item,index1){return _c('tbody',{key:index1},[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v(_vm._s(_vm.$t("step"))+":"+_vm._s(item.stepName))]),_c('td',[(item.status === 'Started')?_c('b',[_vm._v(_vm._s(_vm.$t("label.submitted")))]):(item.status === 'Approved')?_c('b',[_vm._v(_vm._s(_vm.$t("label.approved")))]):(
                item.status === 'Pending' || item.status === 'pending'
              )?_c('b',[_vm._v(_vm._s(_vm.$t("label.pending")))]):(item.status === 'Recalled')?_c('b',[_vm._v(_vm._s(_vm.$t("lebel.alreadyback")))]):(item.status === 'Rejected')?_c('b',[_vm._v(_vm._s(_vm.$t("label.refused")))]):_vm._e()])]),_vm._l((item.instancehis),function(val,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(val.lastModifyDate))]),_c('td',[(val.stepStatus === 'Started')?_c('span',[_vm._v(_vm._s(_vm.$t("label.submitted")))]):(val.stepStatus === 'Approved')?_c('span',[_vm._v(_vm._s(_vm.$t("label.approved")))]):(val.stepStatus === 'Pending')?_c('span',[_vm._v(_vm._s(_vm.$t("label.pending")))]):(val.stepStatus === 'Recalled')?_c('span',[_vm._v(_vm._s(_vm.$t("label.pending")))]):(val.stepStatus === 'Rejected')?_c('span',[_vm._v(_vm._s(_vm.$t("label.refused")))]):_vm._e()]),_c('td',[_vm._v(_vm._s(val.assigntoName))]),_c('td',[_vm._v(_vm._s(val.actualName))]),_c('td',[_vm._v(_vm._s(val.comments))]),_vm._m(0,true)])})],2)})],2)]):_vm._e(),(
      _vm.pricebookArr &&
      _vm.pricebookArr.length !== 0 &&
      _vm.itemArr.objLabel === _vm.$t('lable.opppdt.selPbk.title')
    )?_c('div',{style:({ maxHeight: _vm.titOffsetTop + 'px', overflowY: 'scroll' })},[_c('table',{staticStyle:{"width":"100%"},attrs:{"bordercolor":"#DDDBDA"}},[_c('tr',{staticClass:"tit"},[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t("lable.product.plist")))]),_c('th',[_vm._v(_vm._s(_vm.$t("lable.product.pricebooks.ustand")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.inusing")))])]),_vm._l((_vm.pricebookArr),function(item,index1){return _c('tr',{key:index1},[_c('td',[_vm._v(_vm._s(index1 + 1))]),_c('td',[_vm._v(_vm._s(item.currency)+_vm._s(item.listPrice))]),_c('td',[_c('el-checkbox',{attrs:{"value":"item.useStdPrice === 'true'","disabled":""}})],1),_c('td',[_c('el-checkbox',{attrs:{"value":"item.isenable === 'true'","disabled":""}})],1)])})],2)]):_vm._e(),(
      _vm.itemArr.fieldList &&
      _vm.itemArr.data &&
      _vm.itemArr.data.length !== 0 &&
      _vm.itemArr.objName !== 'tp_sys_approval_instance_his' &&
      !_vm.itemArr.data[0].norecord
    )?_c('div',{style:({ maxHeight: _vm.titOffsetTop + 'px', overflowY: 'auto' })},[_c('table-panel',{ref:"tablePanel",staticClass:"text-center",attrs:{"islineEdit":false,"border":true,"show-index":true,"showLock":false,"checked":false,"customHeader":false,"prefix":_vm.itemArr.prefix,"obj-id":_vm.itemArr.objid,"objectApi":_vm.itemArr.objName,"pageNum":2,"table-attr":_vm.itemArr.fieldList,"page-obj":_vm.pageObj,"itemObj":_vm.itemArr,"mainId":_vm.id}}),(_vm.itemArr.objName !== 'tp_sys_approval_instance_his')?_c('div',{staticClass:"more-btn-all",on:{"click":function($event){return _vm.tableView(_vm.itemArr)}}},[_vm._v(" "+_vm._s(_vm.$t("label.viewall"))+" ")]):_vm._e()],1):_vm._e(),(
      (_vm.itemArr.data &&
        _vm.itemArr.data.length == 0 &&
        _vm.itemArr.objName !== 'tp_sys_approval_instance_his') ||
      (_vm.itemArr.data && _vm.itemArr.data[0].norecord)
    )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e(),(
      _vm.itemArr.objName === 'tp_sys_approval_instance_his' &&
      _vm.historyData.length === 0
    )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e(),(
      _vm.itemArr.objLabel === _vm.$t('lable.opppdt.selPbk.pricebook') &&
      _vm.pricebookArr.length === 0
    )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span')])}]

export { render, staticRenderFns }