
<template>
  <el-card class="box-main_main" shadow="never">
    <div class="relation-list-obj">
      <!-- 通用相关列表 -->
      <div class="tit">
        <div class="tit_s">
          <svg class="icon imgClass" aria-hidden="true">
            <use href="#icon-markting_social"></use>
          </svg>
          <!-- 社交帖子 -->
          <p class="hover_p">
            {{$t('market.name.social.post')}}（
            {{ tableDataList.length >= 10 ? "10+" : tableDataList.length }}）
          </p>
        </div>
        <el-button-group style="position: relative" class="right_btn">
          <el-button
            class="button"
            size="small"
            v-for="item in objectInfo.showButton"
            :key="item.id"
            @click="buttonFun(item)"
            style="position: relative"
          >
            <span>{{ item.label }}</span>
          </el-button>
        </el-button-group>
      </div>
      <!--    相关列表-->
      <div>
        <tablePanel
          :tableAttr="tableAttr"
          :tableDataList="tableDataList"
          @edit="edit"
          @delete="del"
          @share="share"
          @view="view"
          :operationButtonList="operationButtonList"
        ></tablePanel>
      </div>
      <!-- 在右侧只能是卡片 -->
      <!-- 查看更多 -->
      <div class="see_more">
        <span @click="seeAllBtn">{{
          $t("component.todaytask.label.all")
        }}</span>
      </div>
    </div>
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="'删除帖子？'"
      top="15%"
      width="40%"
      height="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确认删除该条数据吗 -->
      <span style="font-size: 14px; color: #080707; font-weight: bold"
        >删除帖子后，市场活动成员和已经产生的业务机会依旧保留，已赢得的收益会记录在市场活动总收益下，但是无法统计此帖子的roi信息。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button @click="confirmDel" class="el-button--primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- soical详情 -->
    <detailSoical ref="detailSoical"></detailSoical>

    <div>
      <el-dialog
        class="abow_dialog"
        width="70%"
        :title="dialogTitle"
        :visible.sync="isShowDialog"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <div
          :style="{
            height: dialogBodyHeight,
            overflow: 'auto',
            padding: '0 20px',
          }"
        >
          <div style="height: 30px" class="mb-10">
            <el-radio v-model="posterFromData.radio" label="1"
              >渠道类型</el-radio
            >
            <span v-show="posterFromData.radio === '1'">成本为 </span>
            <el-input
              v-show="posterFromData.radio === '1'"
              v-model="posterFromData.channel"
              size="mini"
              style="width: 270px"
            ></el-input>
          </div>

          <div style="height: 30px">
            <el-radio v-model="posterFromData.radio" label="2"
              >员工邀约</el-radio
            >
          </div>
          <el-row v-show="posterFromData.radio === '2'">
            <el-form
              ref="objForms"
              :model="stepTwoFormData"
              :label-width="formLabelWidth"
              label-position="left"
              size="small"
            >
              <el-col :span="24">
                <div class="screen_s">
                  <el-form-item>
                    <span style="width: 21px"></span>
                    <div class="select_one fieldTextStyle">
                      {{ $t("setup.layout.label.selectedfield") }}
                    </div>
                    <p class="equalP"></p>
                    <div class="select_two fieldTextStyle">
                      <div>{{ $t("label.tabpage.value") }}</div>
                    </div>
                    <div style="width: 100px"></div>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24">
                <div
                  class="screen_s"
                  v-for="(ev, index) in stepTwoFormData.leadDefaultValues"
                  :key="index"
                >
                  <el-form-item>
                    <span style="width: 21px">{{ index + 1 }}</span>
                    <!-- placeholder="选择字段" -->
                    <el-select
                      v-model="ev.fieldid"
                      :placeholder="$t('label.selectfield')"
                      class="select_one"
                      filterable
                      clearable
                      @change="selectChange($event, index)"
                    >
                      <el-option
                        v-for="item in selectFieldList"
                        :key="item.datafieldRef"
                        :label="item.labelName"
                        :value="item.datafieldRef"
                      ></el-option>
                    </el-select>
                    <p class="equalP">{{ $t("label.tabpage.equalz") }}</p>
                    <div class="select_two">
                      <el-input
                        :placeholder="$t('label.please.fill.out')"
                        v-model="ev.dvalue"
                      >
                      </el-input>
                    </div>
                    <div style="width: 100px">
                      <span
                        class="btn_s"
                        @click="dels(index)"
                        v-if="stepTwoFormData.leadDefaultValues.length !== 1"
                        >-</span
                      >
                      <span
                        class="btn_s"
                        @click="adds"
                        v-if="
                          stepTwoFormData.leadDefaultValues.length - 1 === index
                        "
                        >+</span
                      >
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-form>
          </el-row>
        </div>

        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button size="mini" type="primary" @click="complete">
            {{ $t("UG.Forcast.10") }} </el-button
          >P
        </div>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>
import * as marketChannel from "../api";
import detailSoical from "@/views/social/components/detailSoical.vue";
import tablePanel from "@/views/marketList/marketingChannel/component/table-panel/index.vue";

export default {
  name: "marketingChannelList",
  components: {
    detailSoical,
    tablePanel,
  },
  data() {
    return {
      //
      loading: true,
      formLabelWidth: "118px",
      dataId: 1,
      //初始数据
      initData: {
        fieldid: "", // select 绑定的值
        dvalue: "", // input绑定的值
      },
      stepTwoFormData: {
        leadDefaultValues: [
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
          },
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
          },
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
          },
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
          },
        ],
      },
      selectFieldList: [], // 下拉框的数据 从后端获取
      projectTasksObjectApi: "",
      dialogBodyHeight: `${document.body.offsetHeight - 295}px`,
      dialogTitle: " 市场活动：编辑社交帖子成本",
      rules: {
        subject: [
          {
            required: true,
            message: this.$i18n.t("label.file.enter.title"),
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      posterFromData: {
        subject: "",
        actual: "",
        from: "",
        channel: "",
        radio: "1",
      },
      operationButtonList: [
        {
          action: "view",
          label: this.$i18n.t("label.emailtocloudcc.button.view"),
        },
      ],
      information: {},
      dialogVisible: false,
      campaignname: "",
      url: "",
      // 相关列表右侧按钮
      objId: "",
      objectInfo: {
        showButton: [
          // { label: '成本', id: 'cost' },
          {
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ),
            id: "show",
          },
        ],
      },
      isShowDialog: false,
      //表头
      tableAttr: [
        {
          apiname: "socialmedianame",
          labelname: this.$i18n.t("c736"),//"帖子发送人"
          colwidth: 200,
        },
        {
          apiname: "socialtype",
          labelname: this.$i18n.t("c737"),//"社交平台"
          colwidth: 100,
        },
        {
          apiname: "poststype",
          labelname: this.$i18n.t("label.chatter.state"),//状态
          colwidth: 200,
        },
        {
          apiname: "senddate",
          labelname: this.$i18n.t("c738"),//"发送日期"
          colwidth: "",
        },
      ],
      tableDataList: [],
      flag: false, // 父组件给子组件传动态参数的时候，拿不到的问题，加flag判断
    };
  },
  created() {
    this.$store.commit("setSocialLength", 0);
    this.init();
  },
  methods: {
    //新增
    adds() {
      if (this.dataId < 10) {
        this.dataId += 1;
        this.stepTwoFormData.leadDefaultValues.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //删除
    dels(id) {
      this.dataId = id;
      this.stepTwoFormData.leadDefaultValues.splice(id, 1);
    },
    //根据筛选字段判断值
    selectChange() {},
    //分享
    share() {},
    //查看
    view(id) {
      this.$refs.detailSoical.detailSoical({
        id: id,
        posttype: "",
        linkedinuserid: "",
      });
    },
    // 编辑
    edit() {
      this.isShowDialog = true; // 弹窗打开
    },
    //初始化
    init() {
      marketChannel
        .getPostInfo({
          orgid: this.$store.state.userInfoObj.orgId,
          campaignid: this.$route.params.id,
        })
        .then((res) => {
          if (res.result) {
            if (res.data === null || res.data === []) {
              this.tableDataList = [];
            } else {
              this.tableDataList = res.data.sociallist.slice(0, 10);
            }
            this.$store.commit("setSocialLength", res.data.sociallist.length);
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    closeDialog() {
      // 刷新列表
      this.init();
      this.qrcodeFlg = false;
      this.isShowDialog = false;
    },
    // 查看更多跳转到营销渠道列表展示页面
    seeAllBtn() {
      this.$router.push({
        path: `/marketingSocialListShow`,
        query: {
          id: this.$route.params.id,
          objId: this.$route.params.id,
        },
      });
    },
    del(id) {
      this.dialogVisible = true;
      this.id = id;
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = false;
      marketChannel.delMarketList({ id: this.id }).then((res) => {
        if (res.result) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label_tabpage_delsuccessz"),
          });
          this.init();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 列表展示
    buttonFun(item) {
      if (item.id == "show") {
        this.$router.push({
          path: `/marketingSocialListShow`,
          query: {
            id: this.$route.params.id,
            objId: this.$route.params.id,
          },
        });
      } else if (item.id == "cost") {
        this.isShowDialog = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.screen_s > div {
  margin-bottom: 0 !important;
}

::v-deep .screen_s .editableCell {
  .el-select__tags {
    flex-wrap: unset;
    overflow-x: overlay;
    top: 53%;
    height: 38px;
    overflow-y: hidden;
  }

  .el-input .el-input__inner {
    height: 32px !important;
  }

  .el-tag {
    margin: 0 5px !important;
  }
}

.screen_s ::v-deep .el-form-item__content {
  display: flex;
  margin-left: 133px !important;
  align-items: center;
}

.el-form-item {
  margin-bottom: 0;
}

.select_one {
  /*width: 280px;*/
  width: 35%;
  margin: 6px;
  height: 32px;
}

.select_two {
  width: 35%;
  margin: 6px 12px 6px 6px;
  height: 32px;
}

.btn_s {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}
.fieldTextStyle {
  text-align: center;
  font-size: 14px;
  color: #080707;
}
.screen_s > div {
  margin-bottom: 0 !important;
}
.dialog-text {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 20px;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
::v-deep .el-dialog__body {
  overflow: auto;
  padding: 16px 36px;
  font-size: 12px !important;
}
.content {
  font-size: 14px;
  padding-left: 20px;
}
.download {
  margin-left: 40px !important;
}
.wrapper {
  padding-left: 10px;
  padding-top: 10px;
  word-break: break-all;
  font-family: "Microsoft Yahei";
  float: left;
  width: 400px;
}
.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.totip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  .upload-demo {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .el-upload .el-upload--text {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .tit_s {
    display: flex;
    img {
      margin-right: 7px;
      //   float: left;
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0;
      //   float: right;
      height: 20px;
      font-size: 12px;
      color: #080707;
      line-height: 20px;
    }
    .hover_p:hover {
      border-bottom: 1px solid #006dcc;
      cursor: pointer;
      color: #006dcc;
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}

//列表样式
.text-center {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  ::v-deep .el-table__header-wrapper {
    // border-top: 1px solid #dedcda !important;
  }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }
  // ::v-deep .editIconImg {
  //   display: none !important;
  // }
  ::v-deep .el-popover__reference {
    width: 20px !important;
    border-radius: 3px !important;
  }
  ::v-deep .el-table tr:last-child {
    td {
      border-bottom: 0 !important;
    }
  }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell {
    font-size: 12px;
  }
}

.filedname {
  margin-left: 9px;
  margin-bottom: 10px;
}

.see_more {
  border-top: 1px solid #ccc;
  height: 44px;
  line-height: 44px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    cursor: pointer;
  }
}
::v-deep .dropbox {
  width: 38px;
}
</style>
<style scoped>
.box-main_main {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid #dddbda;
  margin-top: 10px;
}
.my-tooltip .dis_b ::v-deep a {
  color: #fff;
  cursor: default;
}
.xiala_s {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}
.list_xiala_s {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.xiala_s img {
  cursor: pointer;
  width: 12px;
  margin-top: -2px;
}

.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}

.upload-demo {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.upload-demo ::v-deep .el-upload {
  width: 100% !important;
  text-align: left !important;
}
.upload-demo ::v-deep .el-upload-dragger {
  width: 100% !important;
}
.imgClass {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
</style>
