<!--
 * @Author: zhangyutong
 * @Date: 2021-08-16 19:25:47
 * @LastEditTime: 2022-04-13 18:04:46
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingPosters\marketingPostersList.vue
-->
<template>
  <el-card class="box-main_main" shadow="never">
    <div class="relation-list-obj">
      <!-- 通用相关列表 -->
      <div class="tit">
        <div class="tit_s">
          <svg class="icon imgClass" aria-hidden="true">
            <use href="#icon-markting_poster"></use>
          </svg>
          <!-- 海报 -->
          <p class="hover_p">
            {{$t("c732")}}（{{
              tableDataList.length > 10 ? "10+" : tableDataList.length
            }}）
          </p>
        </div>
        <el-button-group style="position: relative" class="right_btn">
          <el-button
            class="button"
            size="small"
            v-for="item in objectInfo.showButton"
            :key="item.id"
            @click="buttonFun(item)"
            style="position: relative"
          >
            <span>{{ item.label }}</span>
          </el-button>
        </el-button-group>
      </div>
      <!--    相关列表-->
      <div>
        <tablePanel
          :tableAttr="tableAttr"
          :tableDataList="tableDataList"
          @edit="edit"
          @delete="del"
          @share="share"
          @view="view"
          :operationButtonList="operationButtonList"
        ></tablePanel>
      </div>
      <!-- 在右侧只能是卡片 -->
      <!-- 查看更多 -->
      <div class="see_more">
        <span @click="seeAllBtn">{{
          $t("component.todaytask.label.all")
        }}</span>
      </div>
    </div>
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.emailtocloudcc.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确认删除该条数据吗 -->
      <span style="font-size: 14px; color: #080707">{{
        $t("label.weixin.confirm.delete")
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button @click="confirmDel" class="el-button--primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 新建 -->
    <el-dialog
      width="70%"
      top="3%"
      style="height: 80%; overflow: visible"
      :title="dialogTitle"
      :visible.sync="isShowDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <dialogFrom ref="dialogFrom" @close="dialogClose"></dialogFrom>
    </el-dialog>

    <shareFrom
      ref="shareFrom"
      :title="$t('front-marketing-module-v1-share-poster')"
    ></shareFrom>
  </el-card>
</template>

<script>
import * as CommonObjApi from "../api";
import tablePanel from "@/views/marketList/marketingChannel/component/table-panel/index.vue";
import dialogFrom from "@/views/marketList/marketingPosters/component/dialogFrom.vue";
import * as SetViewApi from "@/views/marketList/api.js";
import shareFrom from "@/views/marketList/marketingPosters/component/shareFrom.vue";

export default {
  name: "marketingChannelList",
  components: {
    tablePanel,
    dialogFrom,
    shareFrom,
  },
  data() {
    return {
      // 弹窗邀约渠道
      sharetype: false,
      callOptions: [
        { value: "1", label: "市场活动所有人" },
        { value: "2", label: "此渠道邀约员工" },
      ],
      Slinkshow: true,
      QRcodeshow: false,
      shareName: "Share link",
      // 新建弹窗
      isShowDialog: false,
      dialogTitle: " 市场活动：编辑海报",
      operationButtonList: [
        {
          action: "delete",
          label: this.$i18n.t("pagecreator.page.button.delete"),
        },
        { action: "edit", label: this.$i18n.t("label.emailtocloudcc.edit") },
        { action: "share", label: this.$i18n.t("label.h5.share") },
      ],
      information: {},
      dialogVisible: false,
      campaignname: "",
      url: "",
      // 相关列表右侧按钮
      objId: "",
      objectInfo: {
        showButton: [
          { label: this.$i18n.t("label.import.page1.newcreate"), id: "new" },
          {
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ),
            id: "show",
          },
        ],
      },
      //表头
      tableAttr: [],
      tableDataList: [],
      flag: false, // 父组件给子组件传动态参数的时候，拿不到的问题，加flag判断
      fields: "",
      relatedlistId: "",
      recordId: "",
      layoutId: "",
      list: [],
    };
  },
  created() {
    this.getRelationList();
  },
  methods: {
    getRelationList() {
      SetViewApi.GetRelationList({ recordId: this.$route.params.id }).then(
        (res) => {
          if (res.result) {
            this.list = res.data;
            this.getHeadArr();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        }
      );
    },
    // 获取相关列表数据
    getHeadArr() {
      this.list.forEach((element) => {
        if (element.relatedlistType === "poster") {
          this.relatedlistId = element.relatedlistId;
          this.layoutId = element.layoutId;
        }
      });
      if (!this.layoutId) {
        this.layoutId =
          this.$route.params.layoutId || this.this.$route.query.layoutId;
      }
      let data = {
        recordId: this.$route.params.id,
        layoutId: this.layoutId,
        relatedlistId: this.relatedlistId,
        perPage: "all",
        attachementType: "",
      };
      SetViewApi.GetDetailRelatedItems(data).then((res) => {
        if (res.result && res.data.fieldList) {
          res.data.fieldList.forEach((list) => {
            let obj = {
              apiname: list.name,
              colwidth: list.length,
              labelname: list.label,
              type: list.type,
            };
            this.tableAttr.push(obj);
          });
          this.tableAttr = this.getName(this.tableAttr);
          this.tableDataList = res.data.data;
          this.$store.commit("setPosterLength", this.tableDataList.length);
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    getName(item) {
      item.forEach((val) => {
        if (
          val.type == "S" &&
          (val.apiname == "formid" ||
            val.apiname == "createbyid" ||
            val.apiname == "lastmodifybyid" ||
            val.apiname == "ownerid" ||
            val.apiname == "inviteduser" ||
            val.apiname == "campaignid" ||
            val.apiname == "campaignownerid")
        ) {
          val.apiname += "ccname";
        }
      });
      return item;
    },
    dialogClose() {
      this.isShowDialog = false;
      this.getRelationList();
    },
    //分享
    share(id) {
      this.$refs.shareFrom.init(id);
    },
    //查看
    view() {
      this.$router.push({
        path: `/marketingPostersListShow`,
        query: {
          id: this.$route.params.id,
          objId: this.$route.params.objId,
        },
      });
    },
    // 编辑
    edit(id) {
      this.isShowDialog = true;
      this.$nextTick(() => {
        this.$refs.dialogFrom.init(id);
      });
    },
    closeDialog() {
      // 刷新列表
      this.getRelationList();
      this.isShowDialog = false;
    },
    // 查看更多跳转到营销渠道列表展示页面
    seeAllBtn() {
      this.$router.push({
        path: `/marketingPostersListShow`,
        query: {
          id: this.$route.params.id,
          objId: this.$route.params.objId,
        },
      });
    },
    del(id) {
      this.dialogVisible = true;
      this.id = id;
    },
    // 确认删除数据
    confirmDel() {
      CommonObjApi.deletePoster({ id: this.id }).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
          this.getRelationList();
          this.dialogVisible = false;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 列表展示
    buttonFun(item) {
      if (item.id == "show") {
        this.$router.push({
          path: `/marketingPostersListShow`,
          query: {
            id: this.$route.params.id,
            objId: this.$route.params.id,
          },
        });
      } else if (item.id == "new") {
        this.$store.commit("changeActionType", "new");
        this.flag = true;
        this.dialogTitle = this.$i18n.t("c733");//"市场活动：新建海报"
        this.isShowDialog = true;
        this.$nextTick(() => {
          this.$refs.dialogFrom.init();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.imgClass {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
.dialog-text {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 20px;
}

.content {
  font-size: 14px;
  padding-left: 20px;
}
.download {
  margin-left: 40px !important;
}
.wrapper {
  padding-left: 10px;
  padding-top: 10px;
  word-break: break-all;
  font-family: "Microsoft Yahei";
  float: left;
  width: 400px;
}
.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.totip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  .upload-demo {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .el-upload .el-upload--text {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .tit_s {
    display: flex;
    img {
      margin-right: 7px;
      //   float: left;
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0;
      //   float: right;
      height: 20px;
      font-size: 12px;
      color: #080707;
      line-height: 20px;
    }
    .hover_p:hover {
      border-bottom: 1px solid #006dcc;
      cursor: pointer;
      color: #006dcc;
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}

//列表样式
.text-center {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  // ::v-deep.el-table__header-wrapper {
  // border-top: 1px solid #dedcda !important;
  // }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }
  // ::v-deep .editIconImg {
  //   display: none !important;
  // }
  ::v-deep .el-popover__reference {
    width: 20px !important;
    border-radius: 3px !important;
  }
  ::v-deep .el-table tr:last-child {
    td {
      border-bottom: 0 !important;
    }
  }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell {
    font-size: 12px;
  }
}

.filedname {
  margin-left: 9px;
  margin-bottom: 10px;
}

.see_more {
  border-top: 1px solid #ccc;
  height: 44px;
  line-height: 44px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    cursor: pointer;
  }
}
::v-deep .dropbox {
  width: 38px;
}
</style>
<style scoped>
.box-main_main {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid #dddbda;
  margin-top: 10px;
}
.my-tooltip .dis_b ::v-deep a {
  color: #fff;
  cursor: default;
}
.xiala_s {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}
.list_xiala_s {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.xiala_s img {
  cursor: pointer;
  width: 12px;
  margin-top: -2px;
}

.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}

.upload-demo {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.upload-demo ::v-deep .el-upload {
  width: 100% !important;
  text-align: left !important;
}
.upload-demo ::v-deep .el-upload-dragger {
  width: 100% !important;
}
.imgClass {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
</style>
